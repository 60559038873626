import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
// import queryString from 'query-string';
// import { parseString } from '../../../data/data';

import Page from './Page';
import PageNavigator from '../common/PageNavigator';

import './Teachers.scss';

import data from './data';

// FIXME: replace resources into public folder
const iconObjectives = '/resources/teachers/lessons/icons/icons-01.png';
const iconLessonPlan = '/resources/teachers/lessons/icons/icons-02.png';
const iconDiscussion = '/resources/teachers/lessons/icons/icons-03.png';
const iconIntroduction = '/resources/teachers/lessons/icons/icons-04.png';
const iconActivity = '/resources/teachers/lessons/icons/icons-06.png';
const iconGlossary = '/resources/teachers/lessons/icons/icons-07.png';
const iconQuestions = '/resources/teachers/lessons/icons/icons-09.png';
const iconSummary = '/resources/teachers/lessons/icons/icons-10.png';

class Teachers extends React.Component {
  // TODO: move all data modification functions to a data utilities module
  static resolveIcon(pageType, lessonSubject, lessonId, iconName) {
    // FIXME: return response.data.lesson icon......
    if (pageType === 'page' && iconName) {
      return `/resources/teachers/lessons/icons/${lessonId}/${iconName}`;
    }

    if (pageType === 'objectives') return iconObjectives;
    if (pageType === 'lessonPlan') return iconLessonPlan;
    if (pageType === 'discussion') return iconDiscussion;
    if (pageType === 'introduction') return iconIntroduction;
    if (pageType === 'activity') return iconActivity;
    if (pageType === 'glossary') return iconGlossary;
    if (pageType === 'questions') return iconQuestions;
    if (pageType === 'summary') return iconSummary;
    // "lessonPlan", "discussion", "introduction", "activity", "questions", "glossary", "summary"
    // TODO: implement icons.....
    return iconObjectives;
  }

  state = {
    currentPage: -1,
    chapterTitle: '',
    lessonTitle: '',
    pages: [],
    content: [],
  };

  componentDidMount() {
    // const query = queryString.parse(this.props.location.search);
    const query = { id: this.props.chapterID, c: this.props.chapterTitle };
    // TODO: implement lesson page in case we have exercises and videos and .....
    // DataService.getLesson(query.id).then(response => {

    if (!data[this.props.chapterID]) {
      this.setState({ notFound: true });
      return;
    }

    // DataService.getTeachers(query.id).then((response) => {
    const response = {
      data: data[this.props.chapterID],
    };

    const content = [];
    const pages = [];
    for (const page of response.data.teachers.pages) {
      pages.push(page.shortTitle || page.pageTitle);
      content.push({
        contentTitle: page.pageTitle,
        contentIcon: Teachers.resolveIcon(
          page.pageType,
          response.data.lesson.subject,
          response.data.lesson._id,
          response.data.lesson.icon
        ),
        content: this.parseString(
          page.pageContent,
          response.data.teachers.resourcesFolder,
          response.data.teachers._id,
          response.data.lesson.subject,
          'teachers',
          false
        ),
      });
    }
    const popups = [];
    // FIXME: trimming the popups data should be handled on dbm
    for (const popup of response.data.teachers.popups) {
      popups.push({
        keyword: popup.keyword.trim(),
        display: this.parseString(
          popup.display.trim(),
          response.data.teachers.resourcesFolder,
          response.data.teachers._id,
          response.data.lesson.subject,
          'teachers',
          true
        ),
      });
    }
    this.setState({
      chapterTitle: query.c,
      lessonTitle: response.data.lesson.title,
      pages,
      content,
      popups,
      currentPage: 0,
    });
    // });
  }

  gotoPage = (page) => {
    this.setState({ currentPage: page }, () => {
      document
        .getElementById('teachersContent')
        .scroll({ top: 0, behavior: 'smooth' });
    });
  };

  gotoPrevPage = () => {
    if (!(this.state.currentPage > 0)) return;
    this.gotoPage(parseInt(this.state.currentPage, 10) - 1);
  };

  gotoNextPage = () => {
    if (!(this.state.currentPage < this.state.pages.length - 1)) return;
    this.gotoPage(parseInt(this.state.currentPage, 10) + 1);
  };

  parseString(input, resourcesFolder, resourcesId, lessonSubject, type, local) {
    // NOTE: transform all the required stirng items....
    const here = local ? '..' : '.';
    return input.replace(
      new RegExp(`${here}/${resourcesFolder}/`, 'g'),
      `/resources/teachers/lessons/images/${resourcesId}/`
    );
  }

  render() {
    if (this.state.notFound) return <Redirect to="/" push />;

    return (
      <div className="teachers">
        <div className="teachers-navigator">
          <PageNavigator
            navHandler={this.gotoPage}
            pages={this.state.pages}
            currentPage={this.state.currentPage}
          />
        </div>
        <div className="teachers-content" id="teachersContent">
          <Page
            chapterTitle={this.state.chapterTitle}
            lessonTitle={this.state.lessonTitle}
            popups={this.state.popups}
            currentPage={this.state.currentPage}
            {...this.state.content[this.state.currentPage]}
          />
        </div>

        <div className="teachers-nav-buttons">
          <div
            className="teachers-nav-buttons-right"
            style={this.state.currentPage > 0 ? {} : { visibility: 'hidden' }}
            onClick={this.gotoPrevPage}
          >
            السابق
          </div>

          <div
            className="teachers-nav-buttons-left"
            style={
              this.state.currentPage < this.state.pages.length - 1
                ? {}
                : { visibility: 'hidden' }
            }
            onClick={this.gotoNextPage}
          >
            التالي
          </div>
        </div>
      </div>
    );
  }
}

Teachers.propTypes = {
  // location: PropTypes.objectOf(PropTypes.any).isRequired,
  chapterID: PropTypes.string.isRequired,
  chapterTitle: PropTypes.string.isRequired,
};

export default Teachers;
