import React from 'react';
import PropTypes from 'prop-types';

import './PageNavigator.scss';

// TODO: generalize distances into a props scale value

class PageNavigator extends React.Component {
  state = { hovered: -1 };

  navElements = [];

  scale = 20;

  render() {
    this.navElements = [];
    for (let i = 0; i < this.props.pages.length; i += 1) {
      this.navElements.push(
        <div className="nav-bar" key={`nav-${i}`}>
          <div
            style={{
              top: i * this.scale * 2,
              right: this.scale + 10,
              fontSize: this.scale / 1.2,
            }}
            className={`nav-title${this.state.hovered === i ? '' : ' hidden'}`}
            onClick={() => this.props.navHandler(i)}
            onMouseEnter={() => {
              this.setState({ hovered: i });
            }}
            onMouseLeave={() => {
              this.setState({ hovered: -1 });
            }}
          >
            {this.props.pages[i]}
          </div>
          <div
            className={`nav-circle${
              parseInt(i, 10) <= this.props.currentPage ? ' active' : ''
            }`}
            onClick={() => this.props.navHandler(i)}
            onMouseEnter={() => {
              this.setState({ hovered: i });
            }}
            onMouseLeave={() => {
              this.setState({ hovered: -1 });
            }}
            style={{
              top: i * this.scale * 2,
              width: this.scale,
              height: this.scale,
              borderWidth: this.scale / 4,
            }}
          />
        </div>
      );

      if (parseInt(i, 10) !== this.props.pages.length - 1) {
        this.navElements.push(
          <div
            className="nav-line"
            key={`nav-line-${i}`}
            style={{
              height: this.scale + 2,
              width: this.scale / 4,
              top: this.scale * (2 * i + 1) - 1,
              right: this.scale / 2 - this.scale / 8,
            }}
          />
        );
      }
    }
    return (
      <div className="nav">
        {this.navElements}
        <div
          className="nav-overlay"
          style={{
            width: this.scale,
            height: this.scale * this.props.pages.length * 2,
          }}
        />
      </div>
    );
  }
}

PageNavigator.propTypes = {
  navHandler: PropTypes.func.isRequired,
  pages: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentPage: PropTypes.number.isRequired,
};

export default PageNavigator;
