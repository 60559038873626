import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import Frame from '../../players/models/ModelsViewer';
import ThreeViewer from '../../players/three/ThreeViewer';

class Models extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeModel: 0,
      models: props.models,
      landscape: false,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.update);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.update);
  }

  update = () => {
    if (window.innerWidth > 600) {
      this.setState({ landscape: false });
    } else {
      this.setState({ landscape: true });
    }
  };

  selectModel(newActive) {
    this.setState({ activeModel: newActive });
  }

  render() {
    return (
      <div>
        <section className="first">
          <Container>
            <Row>
              <Col>
                <Row>
                  <Col className="model-display" xs={12} md={12}>
                    {/* {this.state.activeModel === 1 ? ( */}
                    {/* // <ThreeViewer options={this.props.models[0]} stats /> */}
                    <Frame
                      token="3369BAD522FB2C126797037829A0E9BAE93E4DC6239989DB25D938694C3E9B8B"
                      url={this.props.models.frameModels[2].path}
                    />
                    {/* ) : (
                      <div
                        onClick={() => this.selectModel(1)}
                        className="filler"
                        style={{
                          backgroundImage: `url("${this.props.models.frameModels[2].thumbnail}")`
                        }}
                      >
                        <i className="fas fa-play" />
                      </div>
                    )} */}
                  </Col>
                  <Col className="paragraph">
                    {/* <div className="title">نماذج مبهرة لتعليمٍ ثريّ</div> */}
                    <div className="text">
                      نماذجنا مصمّمة خصيصاً لجذب انتباه الطلّاب عبر تفاعلهم مع
                      المحتوى المتحرّك من أجل فهمٍ مُتقن ودقيق يُطبَع في ذاكرة
                      المتعلّم.
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="second">
          <Container>
            <Row>
              <Col>
                <Row>
                  <Col
                    className="paragraph"
                    xs={{ size: 12, order: 0 }}
                    md={{ size: 6, order: 1 }}
                  >
                    <div className="title">تمثيلٌ واضح للمفاهيم العلميّة</div>
                    <div className="text">
                      بالرغم من أن بعض المفاهيم صعبة الإستيعاب، فإنّ هذه النماذج
                      الثلاثية الأبعاد تمكّن الأستاذ من إعطاء شرحٍ تفصيليٍّ
                      وتمكّن الطالب من فهم تفاصيل أي درس كان، وذلك عبر رؤيته
                      للأجسام بتفصيلٍ دقيق وألوانٍ جذّابة، والتفاعل معها بسهولة
                      تامّة. فمثلاً عندما يرى الطالب نموذج الخليّة ويقوم
                      بتحريكها، فإنّه يحفظ مكوناتها ويتفاعل معها فتُطبع في
                      ذاكرته.
                    </div>
                  </Col>
                  <Col className="model-display">
                    {this.state.activeModel === 2 ? (
                      <Frame
                        token="3369BAD522FB2C126797037829A0E9BAE93E4DC6239989DB25D938694C3E9B8B"
                        url={this.props.models.frameModels[1].path}
                      />
                    ) : (
                      <div
                        onClick={() => this.selectModel(2)}
                        className="filler"
                        style={{
                          backgroundImage: `url("${this.props.models.frameModels[1].thumbnail}")`,
                        }}
                      >
                        <i className="fas fa-play" />
                      </div>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="third">
          <Container>
            <Row>
              <Col>
                <Row>
                  <Col className="paragraph" xs={12} md={6}>
                    <div className="title">تعليمٌ معاصر في متناول اليد</div>
                    <div className="text">
                      نماذجنا ثلاثيّة الأبعاد متاحة بشكل فوري في متناول
                      المستخدمين للتحكم بها عبر حاسوبه أو هاتفه بشكل سهل وسريع،
                      دون الحاجة لتحميل أيّة برامج إضافيّة، مما يسهّل العمليّة
                      التعليميّة.
                    </div>
                  </Col>
                  <Col className="model-display">
                    {this.state.activeModel === 3 ? (
                      <Frame
                        token="3369BAD522FB2C126797037829A0E9BAE93E4DC6239989DB25D938694C3E9B8B"
                        url={this.props.models.frameModels[0].path}
                      />
                    ) : (
                      <div
                        onClick={() => this.selectModel(3)}
                        className="filler"
                        style={{
                          backgroundImage: `url("${this.props.models.frameModels[0].thumbnail}")`,
                        }}
                      >
                        <i className="fas fa-play" />
                      </div>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
        <section>
          <Container>
            <Row>
              <Col>
                {/* {this.state.landscape ? (
                  <div>Please rotate the screen</div>
                ) : ( */}
                <div className="frame">
                  {/* {this.state.activeModel === 4 ? ( */}
                  <Frame
                    token="3369BAD522FB2C126797037829A0E9BAE93E4DC6239989DB25D938694C3E9B8B"
                    url={this.props.models.frameModels[3].path}
                  />
                  {/* ) : (
                      <div
                        onClick={() => this.selectModel(4)}
                        className="filler"
                        style={{
                          backgroundImage: `url("${this.props.models.frameModels[3].thumbnail}")`
                        }}
                      >
                        <i className="fas fa-play" />
                      </div>
                    )} */}
                </div>
                {/* )} */}
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    );
  }
}

export default Models;

Models.propTypes = {
  // models: PropTypes.arrayOf(PropTypes.object).isRequired
  models: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.object)).isRequired,
};
