import React, { Component } from 'react';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imgs: [
        '/resources/earlylearning/demo/mainRes/level1.png',
        '/resources/earlylearning/demo/mainRes/level2.png',
        '/resources/earlylearning/demo/mainRes/level3.png',
        '/resources/earlylearning/demo/mainRes/level4.png',
        '/resources/earlylearning/demo/mainRes/level5.png',
        '/resources/earlylearning/demo/mainRes/level6.png',
      ],
      char_imgs: [
        '/resources/earlylearning/demo/mainRes/level1_char.png',
        '/resources/earlylearning/demo/mainRes/level2_char.png',
        '/resources/earlylearning/demo/mainRes/level3_char.png',
        '/resources/earlylearning/demo/mainRes/level4.png',
        '/resources/earlylearning/demo/mainRes/level5_char.png',
        '/resources/earlylearning/demo/mainRes/level6_char.png',
      ],
    };
  }

  handlePointer = (idx) => {
    this.state.imgs[idx] = `/resources/earlylearning/demo/mainRes/level${
      idx + 1
    }_g.png`;
    this.state.char_imgs[idx] = `/resources/earlylearning/demo/mainRes/level${
      idx + 1
    }_char_g.png`;
    this.forceUpdate();
  };

  handlePointerOut = (idx) => {
    this.state.imgs[idx] = `/resources/earlylearning/demo/mainRes/level${
      idx + 1
    }.png`;
    this.state.char_imgs[idx] = `/resources/earlylearning/demo/mainRes/level${
      idx + 1
    }_char.png`;
    this.forceUpdate();
  };

  render() {
    return (
      <div className="home">
        <img
          src="/resources/earlylearning/demo/mainRes/kexuebj1280.jpg"
          alt=""
          className="background"
        />
        <div>
          <div className="part">
            <img
              className="level1-char onPiont"
              src={this.state.char_imgs[0]}
              alt=""
            />
            <img
              onClick={() => this.props.select(1)}
              className="level1"
              src={this.state.imgs[0]}
              onPointerOver={() => this.handlePointer(0)}
              onPointerOut={() => this.handlePointerOut(0)}
              alt=""
            />
          </div>
          <div className="part">
            <img
              onClick={() => this.props.select(2)}
              className="level2"
              src={this.state.imgs[1]}
              onPointerOver={() => this.handlePointer(1)}
              onPointerOut={() => this.handlePointerOut(1)}
              alt=""
            />
            <img
              className="level2-char onPiont"
              src={this.state.char_imgs[1]}
              alt=""
            />
          </div>
          <div className="part">
            <img
              onClick={() => this.props.select(3)}
              className="level3"
              src={this.state.imgs[2]}
              onPointerOver={() => this.handlePointer(2)}
              onPointerOut={() => this.handlePointerOut(2)}
              alt=""
            />
            <img
              className="level3-char onPiont"
              src={this.state.char_imgs[2]}
              alt=""
            />
          </div>
          <div className="part">
            <img
              onClick={() => this.props.select(4)}
              className="level4"
              src={this.state.imgs[3]}
              onPointerOver={() => this.handlePointer(3)}
              onPointerOut={() => this.handlePointerOut(3)}
              alt=""
            />
          </div>
          <div className="part">
            <img
              onClick={() => this.props.select(5)}
              className="level5"
              onPointerOver={() => this.handlePointer(4)}
              onPointerOut={() => this.handlePointerOut(4)}
              src={this.state.imgs[4]}
              alt=""
            />
            <img
              className="level5-char onPiont"
              src={this.state.char_imgs[4]}
              alt=""
            />
          </div>
          <div className="part">
            <img
              onClick={() => this.props.select(6)}
              className="level6"
              src={this.state.imgs[5]}
              onPointerOver={() => this.handlePointer(5)}
              onPointerOut={() => this.handlePointerOut(5)}
              alt=""
            />
            <img
              className="level6-char onPiont"
              src={this.state.char_imgs[5]}
              alt=""
            />
          </div>
          <img
            className="leaf"
            src="/resources/earlylearning/demo/mainRes/kexue_yezi_1.png"
            alt=""
          />
          <img
            src="/resources/earlylearning/demo/mainRes/title.png"
            alt=""
            className="app-title"
          />
        </div>
      </div>
    );
  }
}

export default Home;
