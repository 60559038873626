import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Dialog from '@material-ui/core/Dialog';
import CancelIcon from '@material-ui/icons/Cancel';
import DialogActions from '@material-ui/core/DialogActions';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    backgroundColor: theme.palette.background.paper,
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
    textAlign: 'center',
  },
  imgVertical: {
    height: 180,
    objectFit: 'contain',
  },
  imgHorizantel: {
    width: 180,
    objectFit: 'contain',
  },
  imgTile: {
    textAlign: 'center',
  },
  gridList: {},
  descText: {
    direction: 'rtl',
    fontSize: '1.2rem',
  },

  tileTitle: {
    paddingRight: 15,
    fontSize: '0.7rem',
  },
}));
const ImgList = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [currentImg, setcurrentImg] = useState('');

  const handleOpen = (img) => {
    if (img != null) {
      setOpen(true);
      //setcurrentImg("/images/" + props.imgFolder + "/" + img.fileName);
      setcurrentImg(img);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  function getColsCount() {
    if (props.vertical) {
      return 1;
    } else {
      switch (props.img.length) {
        case 1:
          return 1;
        case 2:
          return 2;
        case 3:
          return 3;
        case 4:
          return 2;
        default:
          return 3;
      }
    }
  }

  function getGridTitle(imgval) {
    if (typeof imgval == 'string') return '';
    else {
      return (
        <GridListTileBar
          className={classes.tileTitle}
          title={<span style={{ fontSize: '0.7rem' }}>{imgval.text}</span>}
          actionIcon={
            <IconButton
              className={classes.icon}
              onClick={() => handleOpen(imgval)}
            >
              <ZoomInIcon />
            </IconButton>
          }
        />
      );
    }
  }

  function getImgSrc(imgval, thumbnails) {
    if (typeof imgval == 'string') {
      //console.log("/images/" + props.imgFolder + "/" + thumbnails + imgval);
      return (
        '/resources/portal/images/' +
        props.imgFolder +
        '/' +
        thumbnails +
        imgval
      );
    } else {
      return (
        '/resources/portal/images/' +
        props.imgFolder +
        '/' +
        thumbnails +
        imgval.fileName
      );
    }
  }

  function getBigImageDesc() {
    if (typeof currentImg == 'string') return '';
    else {
      return (
        <Typography
          color="textSecondary"
          className={classes.descText}
          dangerouslySetInnerHTML={{ __html: currentImg.text }}
        ></Typography>
      );
    }
  }

  return (
    <div style={{ textAlign: 'center' }}>
      <GridList
        {...(typeof props.imgSize === 'undefined'
          ? {}
          : { cellHeight: 'auto' })}
        className={classes.gridList}
        cols={getColsCount()}
      >
        {props.img.map((imgval, index) => (
          <GridListTile className={classes.imgTile} key={'img' + index}>
            <img
              onClick={() => handleOpen(imgval)}
              style={{
                height: typeof props.imgSize === 'undefined' ? '100%' : 'auto',
                width:
                  typeof props.imgSize === 'undefined' ? '100%' : props.imgSize,
                objectFit: 'contain',
              }}
              src={getImgSrc(imgval, 'thumbnails/')}
              alt=""
            />
            {getGridTitle(imgval)}
          </GridListTile>
        ))}
      </GridList>
      <Dialog open={open} onClose={handleClose}>
        <img
          alt=""
          src={getImgSrc(currentImg, '')}
          style={{ width: '100%', height: '100%', objectFit: 'contain' }}
        />
        <DialogActions>
          {getBigImageDesc()}
          <IconButton onClick={handleClose}>
            <CancelIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default ImgList;
