import React from 'react';
import Datamaps from 'react-datamaps';
import { Container, Row, Col } from 'reactstrap';

const countries = [
  {
    name: 'Lebanon',
    label: 'لبنان',
    latitude: 33.8547,
    longitude: 35.8623,
    fillKey: 'branchesFill',
  },
  {
    name: 'Saudi Arabia',
    label: 'المملكة العربية السعودية',
    latitude: 23.885942,
    longitude: 45.079162,
    fillKey: 'branchesFill',
  },
  {
    name: 'United Arab Emirates',
    label: 'الإمارات العربية المتحدة',
    latitude: 23.424076,
    longitude: 53.847818,
    fillKey: 'branchesFill',
  },
  {
    name: 'Bahrain',
    label: 'البحرين',
    latitude: 25.930414,
    longitude: 50.637772,
    fillKey: 'branchesFill',
  },
  {
    name: 'Qatar',
    label: 'قطر',
    latitude: 25.354826,
    longitude: 51.183884,
    fillKey: 'branchesFill',
  },
  {
    name: 'Kuwait',
    label: 'الكويت',
    latitude: 29.31166,
    longitude: 47.481766,
    fillKey: 'branchesFill',
  },
  {
    name: 'Tunisia',
    label: 'تونس',
    latitude: 33.886917,
    longitude: 9.537499,
    fillKey: 'branchesFill',
  },
  {
    name: 'Egypt',
    label: 'مصر',
    latitude: 26.820553,
    longitude: 30.802498,
    fillKey: 'branchesFill',
  },
  {
    name: 'Canada',
    label: 'كندا',
    latitude: 56.130366,
    longitude: -106.346771,
    fillKey: 'branchesFill',
  },
  {
    name: 'China',
    label: 'الصين',
    latitude: 35.86166,
    longitude: 104.195397,
    fillKey: 'branchesFill',
  },
  {
    name: 'Belgium',
    label: 'بلجيكا',
    latitude: 50.503887,
    longitude: 4.469936,
    fillKey: 'branchesFill',
  },
  {
    name: 'Germany',
    label: 'ألمانيا',
    latitude: 51.165691,
    longitude: 10.451526,
    fillKey: 'branchesFill',
  },
  {
    name: 'Malaysia',
    label: 'ماليزيا',
    latitude: 4.210484,
    longitude: 101.975766,
    fillKey: 'branchesFill',
  },
  {
    name: 'Turkey',
    label: 'تركيا',
    latitude: 38.963745,
    longitude: 35.243322,
    fillKey: 'branchesFill',
  },
  {
    name: 'USA',
    label: 'الولايات المتحدة الأمريكية',
    latitude: 37.09024,
    longitude: -95.712891,
    fillKey: 'distributorsFill',
  },
  {
    name: 'UK',
    label: 'المملكة المتحدة',
    latitude: 55.378051,
    longitude: -3.435973,
    fillKey: 'distributorsFill',
  },
  {
    name: 'Indonesia',
    label: 'إندونيسيا',
    latitude: -0.789275,
    longitude: 113.921327,
    fillKey: 'distributorsFill',
  },
  {
    name: 'France',
    label: 'فرنسا',
    latitude: 46.227638,
    longitude: 2.213749,
    fillKey: 'distributorsFill',
  },
  {
    name: 'Algeria',
    label: 'الجزائر',
    latitude: 28.033886,
    longitude: 1.659626,
    fillKey: 'distributorsFill',
  },
  {
    name: 'Morocco',
    label: 'المغرب',
    latitude: 31.791702,
    longitude: -7.09262,
    fillKey: 'distributorsFill',
  },
  {
    name: 'Jordan',
    label: 'الأردن',
    latitude: 30.585164,
    longitude: 36.238414,
    fillKey: 'distributorsFill',
  },
  {
    name: 'Libya',
    label: 'ليبيا',
    latitude: 26.3351,
    longitude: 17.228331,
    fillKey: 'distributorsFill',
  },
  {
    name: 'Sudan',
    label: 'السودان',
    latitude: 12.862807,
    longitude: 30.217636,
    fillKey: 'distributorsFill',
  },
  {
    name: 'Iraq',
    label: 'العراق',
    latitude: 33.223191,
    longitude: 43.679291,
    fillKey: 'distributorsFill',
  },
];

function Map() {
  return (
    <div>
      <Container className="place-list">
        <Row>
          <Col xs="12" lg="6" className="section paragraph">
            <Row className="title">فروعنا</Row>
            <Row>
              {countries
                .filter((c) => c.fillKey === 'branchesFill')
                .map((el, key) => (
                  <Col key={key} xs="6" className="item branch">
                    {/* <i className="fas fa-circle" /> */}
                    <div className="circ" />
                    {el.label}
                  </Col>
                ))}
            </Row>
          </Col>
          <Col className="section paragraph">
            <Row className="title">موزعونا</Row>
            <Row>
              {countries
                .filter((c) => c.fillKey === 'distributorsFill')
                .map((el, key) => (
                  <Col key={key} xs="6" className="item distributor">
                    {/* <i className="fas fa-circle" /> */}
                    <div className="circ" />
                    {el.label}
                  </Col>
                ))}
            </Row>
          </Col>
        </Row>
        <Row>
          <Datamaps
            responsive
            scope="world"
            geographyConfig={{
              hideAntarctica: true,
              hideHawaiiAndAlaska: false,
              contiguousUnitedStates: true,
              borderColor: 'white',
              borderWidth: 1,
              popupOnHover: false,
              highlightOnHover: false,
            }}
            fills={{
              defaultFill: '#98c1d9',
              branchesFill: '#3d5a80',
              distributorsFill: '#e0fbfc',
            }}
            bubbleOptions={{
              borderWidth: 1,
              radius: 5,
              //   borderOpacity: 1,
              borderColor: 'black',
              popupOnHover: true, // True to show the popup while hovering
              popupTemplate(geography, data) {
                // This function should just return a string
                return `<div class="hoverinfo"><strong>${data.label}</strong></div>`;
                // return `<i class="fas fa-map-pin"></i>`;
              },
              fillOpacity: 1,
              animate: true,
              highlightOnHover: true,
              highlightFillColor: '#fe5f55',
              highlightBorderColor: 'black',
              highlightBorderWidth: 1,
              highlightBorderOpacity: 1,
              highlightFillOpacity: 1,
              exitDelay: 100, // Milliseconds
              key: JSON.stringify,
            }}
            bubbles={countries}
            // bubbleOptions={{
            //   borderWidth: 1,
            //   borderColor: '#ABCDEF',
            // }}
          />
        </Row>
      </Container>
    </div>
  );
}

export default Map;
