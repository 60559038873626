import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Player,
  BigPlayButton,
  ControlBar,
  VolumeMenuButton,
  CurrentTimeDisplay,
  TimeDivider,
  PlaybackRateMenuButton,
} from 'video-react';

import { Row, Col, Container, Button } from 'reactstrap';

import './VideoExercise.scss';

const QUESTIONS = {
  49: {
    question: 'متى يحصل كسوف الشمس؟',
    choices: [
      'عندما يحجب ضوء الشمس عن القمر',
      'عندما يحجب ضوء الشمس عن الأرض',
      'عندما يصل ضوء الشمس إلى الأرض والقمر',
      'عندما يحجب ضوء الشمس عن الأرض والقمر',
    ],
    solution: 'عندما يحجب ضوء الشمس عن الأرض',
    submitted: false,
  },
  70: {
    question: 'متى يحدث خسوف القمر؟ ',
    choices: [
      'عندما تقع الشمس بين القمر والأرض',
      'عندما يقع القمر بين الشمس والأرض',
      'عندما تقع الأرض بين الشمس والقمر',
      'عندما يقع كوكب عطارد بين الشمس والأرض',
    ],
    solution: 'عندما تقع الأرض بين الشمس والقمر',
    submitted: false,
  },
  115: {
    question: 'هل تؤثر الشمس على عمليتيّ المد والجزر؟ ',
    choices: [
      'نعم وتأثيرها كتأثير القمر',
      'نعم وتأثيرها أكبر من تأثير القمر بسبب كبر حجمها',
      'نعم ولكن تأثيرها أقل من تأثير القمر بسبب بعدها عن الأرض',
      'لا تأثير للشمس',
    ],
    solution: 'نعم ولكن تأثيرها أقل من تأثير القمر بسبب بعدها عن الأرض',
    submitted: false,
  },
};

class VideoExercise extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.video = null;

    this.myRef = React.createRef();
  }

  componentDidMount() {
    // console.log('video', this.player.video);
    // this.player.video.video.addEventListener('timeupdate', () => {
    //   // console.log(e)
    //   console.log(this.state.player.currentTime);
    // });

    this.video = this.player.video.video;

    this.video.addEventListener('timeupdate', this.handleTimeUpdate);
    this.video.addEventListener('loadedmetadata', () => {
      const progressHolder = this.myRef.current.querySelector(
        '.video-react-progress-holder'
      );
      Object.keys(this.props.questions).forEach((key) => {
        const point =
          (Number.parseInt(key, 10) / Number.parseFloat(this.video.duration)) *
          100;

        const sp = document.createElement('span');
        sp.textContent = '';
        sp.style.height = '100%';
        sp.style.width = '5px';
        sp.style.backgroundColor = 'yellow';
        sp.style.position = 'absolute';
        sp.style.left = `${point}%`;
        progressHolder.appendChild(sp);

        this.props.questions[key].indecator = sp;
      });
    });
  }

  componentWillUnmount() {
    this.video.removeEventListener('timeupdate', this.handleTimeUpdate);
  }

  handleTimeUpdate = () => {
    // console.log(this.video.currentTime);
    const currTime = Number.parseInt(this.video.currentTime, 10);
    const askQst = Object.keys(this.props.questions).includes(
      currTime.toString()
    );

    if (askQst) {
      this.setState({ currQst: currTime });
      setTimeout(() => {
        this.player.pause();

        if (this.props.questions[currTime].submitted) {
          this.handleChoiceClick();
        }
      }, 500);
    }
  };

  renderQuestion = () => {
    if (!this.state.currQst) return null;
    const qst = this.props.questions[this.state.currQst];
    return (
      <div className="question">
        <Row className="justify-content-center">
          <Col xs={12} md={8}>
            <p>{qst.question}</p>
          </Col>
        </Row>
        <Row className="justify-content-center">
          {qst.choices.map((el, idx) => (
            <Col key={idx} xs={12} md={8} className="mt-1 mt-md-2">
              <Button
                // eslint-disable-next-line no-nested-ternary
                color={`${
                  !qst.submitted
                    ? 'primary'
                    : el === qst.solution
                    ? 'success'
                    : 'danger'
                }`}
                outline={!qst.answer || el !== qst.answer}
                block
                onClick={this.handleChoiceClick}
              >
                {el}
              </Button>
            </Col>
          ))}
        </Row>
      </div>
    );
  };

  handleChoiceClick = (e) => {
    if (e) {
      this.props.questions[this.state.currQst].submitted = true;
      this.props.questions[this.state.currQst].answer = e.target.textContent;
      this.props.questions[this.state.currQst].indecator.style.backgroundColor =
        'green';
      this.forceUpdate();
    }

    setTimeout(() => {
      this.setState({ currQst: null }, () => {
        const seek = this.video.currentTime + 0.6;
        this.player.seek(seek);
        if (seek < this.video.duration) {
          this.player.play();
        } else {
          this.player.seek(0);
          this.player.pause();
        }
      });
    }, 2000);
  };

  render() {
    return (
      <div className="VideoExercise" ref={this.myRef}>
        <Container>
          <Row>
            <Col>
              <Player
                playsInline
                src={this.props.source}
                ref={(ref) => {
                  this.player = ref;
                }}
              >
                <BigPlayButton position="center" />
                <ControlBar>
                  <VolumeMenuButton order={8.1} />
                  <CurrentTimeDisplay order={4.1} />
                  <TimeDivider order={4.2} />
                  <PlaybackRateMenuButton rates={[5, 2, 1, 0.75]} order={7.1} />
                </ControlBar>
              </Player>
              {this.renderQuestion()}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

VideoExercise.propTypes = {
  source: PropTypes.string,
  questions: PropTypes.objectOf(PropTypes.any),
};

VideoExercise.defaultProps = {
  source: '/resources/videos/أسئلة كسوف خسوف.mp4',
  questions: QUESTIONS,
};

export default VideoExercise;
