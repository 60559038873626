import React from 'react';
import { Container, Row, Col } from 'reactstrap';

function Partners() {
  const partners = [
    {
      logo: '/resources/logos/partners/Author Services.jpg',
      nav: 'https://www.authorservicesinc.com/',
    },
    {
      logo: '/resources/logos/partners/bbcActiveLogo.png',
      nav: 'http://www.bbcactive.com/',
    },
    {
      logo: '/resources/logos/partners/bbclearning.jpg',
      nav: 'http://www.bbcworldwidelearning.com/',
    },
    {
      logo: '/resources/logos/partners/Berlitz.png',
      nav: 'https://www.berlitz.us/',
    },
    {
      logo: '/resources/logos/partners/Chouette.png',
      nav: 'https://www.chouette-publishing.com/EN/',
    },
    {
      logo: '/resources/logos/partners/dk-penguin.jpg',
      nav: 'https://www.penguinrandomhouse.com/',
    },
    {
      logo: '/resources/logos/partners/fltrp.png',
      nav: 'http://en.fltrp.com/',
    },
    {
      logo: '/resources/logos/partners/Hachette.png',
      nav: 'https://www.hachette-education.com/',
    },
    {
      logo: '/resources/logos/partners/highlights.jpg',
      nav: 'https://www.highlights.com/companies/education-group',
    },
    {
      logo: '/resources/logos/partners/intercontinental - PRESS.png',
      nav: 'https://www.facebook.com/China-Intercontinental-Press-624173571079918/',
    },
    {
      logo: '/resources/logos/partners/Renmin-University-of-China.jpg',
      nav: 'https://www.ruc.edu.cn/en/',
    },
    {
      logo: '/resources/logos/partners/scholastic-logo.png',
      nav: 'http://emea.scholastic.com/en',
    },
  ];
  return (
    <Container className="logos">
      <div className="title">شركاؤنا</div>
      <Row>
        {partners.map((partner, key) => (
          <Col key={key} xs="6" sm="6" md="4" lg="3" className="logo-columns">
            <a href={partner.nav} target="_blank" rel="noopener noreferrer">
              <img src={partner.logo} alt="" />
            </a>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default Partners;
