import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ExerciseStatuses from '../../utils/exercise-statuses';
// import { QuestionText } from '../QuestionTextComponent';
import wrapWithExerciseBase from '../ExerciseWrapperHOC/ExerciseWrapper';

class MCQ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.initalState(),
    };

    this.multipleAnswers = Array.isArray(this.props.exMeta.answer);
  }

  initalState = () => {
    const v = {
      exStatus: ExerciseStatuses.UNTOUCHED,
      canAnswer: true,
      answer: {},
    };

    return v;
  };

  checkAnswer = () => {
    const choosenAnswers = Object.keys(this.state.answer).filter(
      (key) => this.state.answer[key] === true
    );

    // convert to array only when props.solution is not.
    const solution = this.multipleAnswers
      ? this.props.exMeta.answer
      : [this.props.exMeta.answer];

    if (choosenAnswers.length !== solution.length) {
      return false;
    }

    for (let i = 0; i < choosenAnswers.length; i += 1) {
      if (solution.indexOf(choosenAnswers[i]) === -1) {
        return false;
      }
    }

    return true;
  };

  reset = () => {
    this.setState({
      ...this.initalState(),
    });
  };

  showAnswer = () => {
    const answer = {};
    const solution = this.multipleAnswers
      ? this.props.exMeta.answer
      : [this.props.exMeta.answer];

    solution.forEach((el) => {
      answer[el] = true;
    });

    this.setState({
      answer,
    });
  };

  initlizeChoices = () => {
    const choices = [];
    this.props.exMeta.choices.forEach((choice, index) => {
      choices.push(
        <div className="mcq-choice" key={index}>
          <input
            type="checkbox"
            name={choice}
            checked={this.state.answer[choice] || false}
            onChange={this.handleChoiceChange}
            disabled={
              !this.state.canAnswer ||
              this.state.exStatus >= ExerciseStatuses.CORRECT
            }
          />
          <label>
            {/* {choice} */}
            <p dangerouslySetInnerHTML={{ __html: choice }} />
          </label>
        </div>
      );
    });

    return choices;
  };

  handleChoiceChange = (e) => {
    const { checked, name } = e.target;
    let status;

    this.setState(
      (prev) => {
        // if props.solution is array, multiple allowed
        // if not, so ovrride the answer
        const answer = this.multipleAnswers ? prev.answer : {};

        if (checked) {
          answer[name] = true;
        } else {
          delete answer[name];
        }

        status =
          Object.keys(answer).length > 0
            ? ExerciseStatuses.TOUCHED
            : ExerciseStatuses.UNTOUCHED;
        return { answer, exStatus: status };
      },
      () => {
        if (this.props.onChange) {
          this.props.onChange();
        }
      }
    );
  };

  renderQNumber() {
    const { qNum } = this.props;

    if (!qNum) return null;

    return (
      <span className="question-number">
        <strong>{this.props.qNum}. </strong>
      </span>
    );
  }

  renderContext() {
    const { context } = this.props.exMeta;

    if (!context || context === '') return null;

    return (
      <div className="question-context">
        <span dangerouslySetInnerHTML={{ __html: context }} />
        <br />
      </div>
    );
  }

  renderQuestion() {
    const { question } = this.props.exMeta;

    if (!question || question === '') return null;

    return (
      <span
        className="question-text"
        dangerouslySetInnerHTML={{ __html: question }}
      />
    );
  }

  renderExplanation() {
    const { explanation } = this.props.exMeta;

    if (!explanation || explanation === '') return null;

    return (
      <span
        className="question-explanation"
        dangerouslySetInnerHTML={{ __html: explanation }}
      />
    );
  }

  render() {
    const choices = this.initlizeChoices();
    return (
      <div
        className={`exercise mcq ${
          this.state.exStatus === ExerciseStatuses.CORRECT ? 'correct' : ''
        } ${this.state.exStatus === ExerciseStatuses.WRONG ? 'wrong' : ''}`}
      >
        <div className="qst-header">
          {this.renderQNumber()}

          {this.renderContext()}

          {this.renderQuestion()}
        </div>

        <div
          className="mcq-choices-list"
          disabled={
            !this.state.canAnswer ||
            this.state.exStatus >= ExerciseStatuses.CORRECT
          }
        >
          {choices}
        </div>

        {this.renderExplanation()}
      </div>
    );
  }
}

MCQ.propTypes = {
  exMeta: PropTypes.objectOf(PropTypes.any).isRequired,
  qNum: PropTypes.number,
  onChange: PropTypes.func,
};

MCQ.defaultProps = {
  qNum: null,
  onChange: null,
};

export default wrapWithExerciseBase(MCQ);
export { MCQ as MCQPURE };
