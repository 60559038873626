import React, { Component } from 'react';
import {
  Player,
  BigPlayButton,
  ControlBar,
  VolumeMenuButton,
  CurrentTimeDisplay,
  TimeDivider,
  PlaybackRateMenuButton,
} from 'video-react';

import { Row, Col, Container, Button } from 'reactstrap';

class QuestionVideo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      source: '/resources/videos/أسئلة كسوف خسوف.mp4',
      showQuestion: null,
      questions: {
        0: {
          question: 'متى يحصل كسوف الشمس؟',
          choices: [
            'عندما يحجب ضوء الشمس عن القمر',
            'عندما يحجب ضوء الشمس عن الأرض',
            'عندما يصل ضوء الشمس إلى الأرض والقمر',
            'عندما يحجب ضوء الشمس عن الأرض والقمر',
          ],
          solution: 'عندما يحجب ضوء الشمس عن الأرض',
          submitted: false,
        },
        1: {
          question: 'متى يحدث خسوف القمر؟ ',
          choices: [
            'عندما تقع الشمس بين القمر والأرض',
            'عندما يقع القمر بين الشمس والأرض',
            'عندما تقع الأرض بين الشمس والقمر',
            'عندما يقع كوكب عطارد بين الشمس والأرض',
          ],
          solution: 'عندما تقع الأرض بين الشمس والقمر',
          submitted: false,
        },
        2: {
          question: 'هل تؤثر الشمس على عمليتيّ المد والجزر؟ ',
          choices: [
            'نعم وتأثيرها كتأثير القمر',
            'نعم وتأثيرها أكبر من تأثير القمر بسبب كبر حجمها',
            'نعم ولكن تأثيرها أقل من تأثير القمر',
            'لا تأثير للشمس',
          ],
          solution: 'نعم ولكن تأثيرها أقل من تأثير القمر',
          submitted: false,
        },
      },
    };
  }

  componentDidMount() {
    // subscribe state change
    this.player.subscribeToStateChange(this.handleStateChange.bind(this));
    // this.player.actions.toggleFullscreen=()=>{console.log('prevent full screen video');};
  }

  renderQuestion = () => {
    if (!this.state.player || this.state.showQuestion === null) return null;
    setTimeout(() => {
      this.player.pause();
    }, 400);
    return this.getQuestionDisplay(this.state.showQuestion);
  };

  getQuestionDisplay = (qstID) => {
    const q = this.state.questions[qstID];
    return (
      <div className="video-question">
        <Row className="justify-content-center question">
          <Col xs={12} md={6}>
            <p>{q.question}</p>
          </Col>
        </Row>
        <Row className="justify-content-center choices">
          {q.choices.map((el, idx) => (
            <Col key={idx} xs={12} md={8} className="mt-1 mt-md-2">
              <Button
                // eslint-disable-next-line no-nested-ternary
                color={`${
                  !q.submitted
                    ? 'primary'
                    : el === q.solution
                    ? 'success'
                    : 'danger'
                }`}
                outline={!q.submitted}
                block
                onClick={() => {
                  this.handleChoiceClick(qstID, el);
                }}
              >
                {el}
              </Button>
            </Col>
          ))}
        </Row>
      </div>
    );
  };

  handleChoiceClick = (qstID) => {
    this.setState(
      (prevState) => ({
        questions: {
          ...prevState.questions,
          [qstID]: {
            ...prevState.questions[qstID],
            submitted: true,
          },
        },
      }),
      () => {
        setTimeout(() => {
          this.setState(
            (prvState) => ({
              showQuestion: null,
              questions: {
                ...prvState.questions,
                [qstID]: {
                  ...prvState.questions[qstID],
                  submitted: false,
                },
              },
            }),
            () => {
              const seek = this.state.player.currentTime + 0.7;
              this.player.seek(seek);
              if (seek < this.state.player.duration) {
                this.player.play();
              } else {
                this.player.seek(0);
                this.player.pause();
              }
            }
          );
        }, 2000);
      }
    );
  };

  handleStateChange(state, prevState) {
    const curTime = Number.parseInt(state.currentTime, 10);
    let showQuestion = null;
    if (curTime === 49) {
      showQuestion = 0;
    }

    if (curTime === 70) {
      showQuestion = 1;
    }

    if (curTime === 115) {
      showQuestion = 2;
    }

    this.setState({
      player: state,
      showQuestion,
    });
  }

  render() {
    return (
      <Container>
        <Row className="title-section">
          <div className="page-title">فيديوهات تفاعلية لجذب تركيز الطلاب</div>
        </Row>
        <Row>
          <Col>
            <Player
              playsInline
              src={this.state.source}
              ref={(ref) => {
                this.player = ref;
              }}
            >
              <BigPlayButton position="center" />
              <ControlBar>
                <VolumeMenuButton order={8.1} />
                <CurrentTimeDisplay order={4.1} />
                <TimeDivider order={4.2} />
                <PlaybackRateMenuButton rates={[5, 2, 1, 0.75]} order={7.1} />
              </ControlBar>
            </Player>

            {this.renderQuestion()}
          </Col>
        </Row>

        <Row className="paragraph">
          <div className="text">
            إمكانيّة طرح أسئلة تفاعليّة على الطلاب من أجل ترسيخ المعلومة لديهم،
            والتأكّد من تركيزهم خلال مشاهدة الأفلام بشكل تامّ. كما وتمكّن هذه
            الخاصيّة من تقييم فهم الطلاب عند الانتهاء من مشاهدة الفيديو لمعرفة
            مدى فعاليّة المحتوى المعروض ومدى وضوحه.
          </div>
        </Row>
      </Container>
    );
  }
}

export default QuestionVideo;
