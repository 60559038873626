import DataService from './dataService';

function getCurriculum() {
  return new Promise((finalResolve) => {
    DataService.getCurriculum().then(async (curr) => {
      const curriculum = [];
      for (const content of curr.data.content) {
        const clss = [];
        for (const cls of content.classes) {
          const chapters = new Promise((resolve) => {
            DataService.getChapters(cls.chapters).then((response) => {
              // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
              const lessonIds = [];
              for (const c of response.data) {
                lessonIds.push(...c.lessons);
              }
              DataService.getLessons(lessonIds).then((resp) => {
                // TODO: handle response rejection, in case lesson not found

                const lessons = {};

                for (const lesson of resp.data) {
                  lessons[lesson._id] = {
                    title: lesson.title,
                    description: lesson.description,
                    link: lesson._id,
                  };
                }
                const chaps = [];
                for (const c of response.data) {
                  const chapterLessons = [];
                  for (const l of c.lessons) {
                    chapterLessons.push(lessons[l]);
                  }
                  chaps.push({
                    title: c.title,
                    img: `${process.env.REACT_APP_IMG_CDN_URI}/${c.subject}/chapters/${c._id}/${c.img}`,
                    description: c.description,
                    lessons: chapterLessons,
                  });
                }
                resolve(chaps);
              });
              // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
            });
          });

          clss.push(
            new Promise((resolve) => {
              chapters.then((chaps) => {
                resolve({
                  class: cls.className,
                  chapters: chaps,
                });
              });
            })
          );
        }

        curriculum.push(
          new Promise((resolve) => {
            Promise.all(clss).then((classValues) => {
              resolve({
                material: content.material,
                classes: classValues,
              });
            });
          })
        );
      }
      Promise.all(curriculum).then((currValues) => {
        finalResolve(currValues);
      });
    });
  });
}

function parseString(
  input,
  resourcesFolder,
  resourcesId,
  lessonSubject,
  type,
  local
) {
  // NOTE: transform all the required stirng items....
  // Resolve images CDN
  const here = local ? '..' : '.';
  return input.replace(
    new RegExp(`${here}/${resourcesFolder}/`, 'g'),
    `${process.env.REACT_APP_IMG_CDN_URI}/${lessonSubject}/${type}/${resourcesId}/`
  );
}

export { getCurriculum as default, getCurriculum, parseString };
