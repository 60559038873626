import React from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardText,
  CardTitle,
} from 'reactstrap';

function SampleLessons() {
  return (
    <Container>
      <Row>
        <Col sm={12} md={6} lg={4}>
          <Card>
            {/* <CardImg
              top
              width="100%"
              src="/resources/teachers/النجوم.JPG"
              alt="Card image cap"
            /> */}
            <div
              className="image"
              style={{
                backgroundImage: "url('/resources/teachers/النجوم.JPG')",
              }}
            />
            <CardBody>
              <CardTitle>الفضاء والنجوم والمجرّات</CardTitle>
              <CardText>
                ما هي النجوم؟ وكيف تشكّلت؟ وما هو مصيرها المتوقع؟ في أيّ مجرّة
                أنت؟ وكم تبعد عن باقي المجرّات؟
              </CardText>
            </CardBody>
            <Link target="_blank" to="teachers/3">
              تصفّح الدرس...
            </Link>
          </Card>
        </Col>

        <Col sm={12} md={6} lg={4}>
          <Card>
            {/* <CardImg
              top
              width="100%"
              src="/resources/teachers/الخلية.JPG"
              alt="Card image cap"
            /> */}
            <div
              className="image"
              style={{
                backgroundImage: "url('/resources/teachers/الخلية.JPG')",
              }}
            />
            <CardBody>
              <CardTitle>وظائف الخلايا</CardTitle>
              <CardText>
                ما هي الخليّة ومما تتكوّن؟ كم نوعاً للخلايا هناك؟ وكيف يمكن
                رؤيتها؟ على ماذا تتغذّى الخلايا؟ وكيف تساعد الخلايا بعضها البعض؟
              </CardText>
            </CardBody>
            <Link target="_blank" to="teachers/4">
              تصفّح الدرس...
            </Link>
          </Card>
        </Col>

        <Col sm={12} md={6} lg={4}>
          <Card>
            {/* <CardImg
              top
              width="100%"
              src="/resources/teachers/فيثاغورس.JPG"
              alt="Card image cap"
            /> */}
            <div
              className="image"
              style={{
                backgroundImage: "url('/resources/teachers/cube.png')",
              }}
            />
            <CardBody>
              <CardTitle>الأشكال الرباعيّة</CardTitle>
              <CardText>
                ما هي الأشكال الرباعية؟ وما هي خصائصها؟ ما هي أنواع هذه الأشكال؟
                وكيف يمكن إيجاد قياس زاوية مجهولة؟
              </CardText>
            </CardBody>
            <Link target="_blank" to="teachers/1">
              تصفّح الدرس...
            </Link>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default SampleLessons;
