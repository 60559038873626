import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Row,
  Col,
  UncontrolledPopover,
  PopoverBody,
} from 'reactstrap';
import './Portals.scss';

function WhyUs() {
  return (
    <Container className="Portals">
      {/* <Row className="title-section">
        <div className="page-title">موسوعاتنا</div>
      </Row> */}
      <Row>
        <Unit
          image="/resources/portal/encyclopedias-icons/easyscience.jpg"
          title="العلوم الميسرة"
          text="تعرض لكل  التخصصات والمجالات بتقسيمات  شرحية موسعة  مزودة بالفيديو والصور بطريقة تفاعلية"
          targetId="d1"
          position="bottom"
        />
      </Row>
      <Row className="shorter-row">
        <Unit
          image="/resources/portal/encyclopedias-icons/factswithinyourhands.jpg"
          title="حقائق بين يديك"
          text="تبرز شخصيات علمائية  تاريخية عظيمة اثرت  في تطور العلم وسهلت سبل حياتنا وجعلت عالمنا افضل"
          targetId="d2"
          position="right"
        />
        <Col xs={1} md={2} lg={1} className="separator-sm" />
        <Unit
          image="/resources/portal/encyclopedias-icons/maps.png"
          title="اطلسي الاول"
          text="مخصصة للناشئة  بهدف تقديم  المعرفة بشكل مبسط  مدعومة بالصور والرسوم  والوسائل التفاعلية"
          targetId="d3"
          position="left"
        />
      </Row>
      <Row className="shorter-row">
        <Unit
          image="/resources/portal/encyclopedias-icons/educational-scholastic-encyclopedia.png"
          title="الموسوعة التعليمية المدرسية"
          text="موسوعة شاملة  مزودة بالصوت والصورة والفيديو  ومقسمة الى شرح  ونوافذ  واسئلة تهم اي مهتم بمجالات العلوم"
          targetId="d4"
          position="right"
        />
        <Col xs={3} md={5} lg={3} className="separator-md">
          <div>موسوعاتنا</div>
        </Col>
        <Unit
          image="/resources/portal/encyclopedias-icons/inventions.png"
          title="قصص الاختراعات والاكتشافات"
          text="تحكي قصة كل اختراع من بداية سطوع الفكرة وما رافقها من حيثيات  الى ما وصلت اليه في واقعنا المعاصر وما صاحبها  من تطويرات  والرجالات الذين اسهموا فيه"
          targetId="d5"
          position="left"
        />
      </Row>
      <Row className="shorter-row">
        <Unit
          image="/resources/portal/encyclopedias-icons/places.png"
          title="اريد ان اعرف عن"
          text="موسوعة معرفية تقدم  المعلومات تفصيليا  في اكبر عدد ممكن من المجالات العلمية بما يخدم اي باحث في اي مجال مرفقة بالفيديوهات  العلمية المتخصصة والتجارب العلمية الرائدة"
          targetId="d6"
          position="right"
        />
        <Col xs={1} md={2} lg={1} className="separator-sm" />
        <Unit
          image="/resources/portal/encyclopedias-icons/yourguidetoknowledge.jpg"
          title="دليلك الى المعرفة"
          text="تقدم معرفة تفصيلية بتاريخ العلوم وكيفية تطورها  وادواتها بتصوير عالي  الدقة  وعرض شامل  بطريقة الشرح المصور"
          targetId="d7"
          position="left"
        />
      </Row>
      <Row className="shorter-row">
        <Unit
          image="/resources/portal/encyclopedias-icons/knowledgeworld.jpg"
          title="عالم المعرفة والعلوم"
          text="التي تمتاز بمواضيع مقسّمة بطريقة سؤال وجواب  شاملة كل التساؤلات  التي تخطر ببال  طالبي المعرفة"
          targetId="d8"
          position="top"
        />
      </Row>
    </Container>
  );
}

export default WhyUs;

function Unit(props) {
  return (
    <Col xs={10} md={3} className="unit">
      <button id={props.targetId} className="element" type="button">
        <div
          className="image"
          style={{ backgroundImage: `url("${props.image}")` }}
        />
        <div className="text">{props.title}</div>
        <UncontrolledPopover
          placement={props.position}
          target={props.targetId}
          trigger="focus"
          className="large-screen"
        >
          <PopoverBody>{props.text}</PopoverBody>
        </UncontrolledPopover>
        <UncontrolledPopover
          placement="bottom"
          target={props.targetId}
          trigger="focus"
          className="small-screen"
        >
          <PopoverBody>{props.text}</PopoverBody>
        </UncontrolledPopover>
      </button>
    </Col>
  );
}

Unit.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  targetId: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
};
