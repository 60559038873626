import * as THREE from 'three';
import * as FBXLoader from 'three-fbxloader-offical';
// import alphaTexture from './texture.jpg';

export default (scene, modelPath) => {
  // var geometry = new THREE.CylinderBufferGeometry(0, 10, 30, 4, 1);
  // var material = new THREE.MeshPhongMaterial({
  //   color: 0xffffff,
  //   flatShading: true
  // });
  // for (var i = 0; i < 500; i++) {
  //   var mesh = new THREE.Mesh(geometry, material);
  //   mesh.position.x = Math.random() * 1600 - 800;
  //   mesh.position.y = 0;
  //   mesh.position.z = Math.random() * 1600 - 800;
  //   mesh.updateMatrix();
  //   mesh.matrixAutoUpdate = false;
  //   scene.add(mesh);
  // }

  function createManager() {
    const manager = new THREE.LoadingManager();
    manager.onError = (url) => {
      console.log(`There was an error loading ${url}`);
    };
    return manager;
  }

  const mixers = [];
  const manager = createManager();
  const loader = new FBXLoader(manager);
  const clock = new THREE.Clock();

  loader.load(
    modelPath,
    (object) => {
      // console.log(object)

      // mixer = new THREE.AnimationMixer(object);
      // const action = mixer.clipAction(object.animations[0]);
      // action.play();
      // object.traverse((child) => {
      //   if (child.isMesh) {
      //     child.castShadow = true;
      //     child.receiveShadow = true;
      //   }
      // });

      object.mixer = new THREE.AnimationMixer(object);
      mixers.push(object.mixer);
      if (object.animations[0]) {
        const action = object.mixer.clipAction(object.animations[0]);
        action.play();
        object.traverse((child) => {
          if (child.isMesh) {
            child.castShadow = true;
            child.receiveShadow = true;
          }
        });
      }

      scene.add(object);
    },
    () => {},
    (error) => {
      console.log('error: ', error);
    }
  );

  // manager.onProgress = function (item, loaded, total) {
  //   progressBar.style.width = `${(loaded / total) * 100}%`;
  //   console.log(loaded);
  // };

  function update(time) {
    // const angle = time*speed;
    // group.rotation.y = angle;
    // subjectMaterial.alphaMap.offset.y = 0.55 + time * textureOffsetSpeed;
    // subjectWireframe.material.color.setHSL( Math.sin(angle*2), 0.5, 0.5 );
    // const scale = (Math.sin(angle*8)+6.4)/5;
    // subjectWireframe.scale.set(scale, scale, scale)

    const delta = clock.getDelta();
    if (mixers[0]) mixers[0].update(delta);
  }

  return {
    update,
    manager,
  };
};
