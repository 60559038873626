import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import Home from './components/home/Home';
import {
  Exercises,
  ExercisesGen,
  Teachers,
  TeacherDetails,
  Video,
  Three,
  Audio,
  EarlyLearning,
  Encyclopedias,
} from './components/products';
import Services from './components/services/Services';
import Project from './components/about/project/Project';
import Us from './components/about/us/Us';
import Timeline from './components/timeline/Timeline';
import VideoExercise from './components/VideoExercise/VideoExercise';

import './App.scss';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <div className="app">
      <main>
        <Router>
          <div>
            <header>
              <Route
                component={(props) => (
                  <Header location={props.history.location} />
                )}
              />
            </header>
            <div className="main-container">
              <Route
                component={({ location }) => (
                  <TransitionGroup>
                    <CSSTransition
                      key={location.key}
                      timeout={400}
                      classNames="router-fade"
                    >
                      <div>
                        <ScrollToTop />
                        <Switch location={location}>
                          <Route exact path="/" component={Home} />
                          <Route exact path="/about/us" component={Us} />
                          <Route
                            exact
                            path="/about/project"
                            component={Project}
                          />
                          <Route exact path="/services" component={Services} />
                          <Route
                            exact
                            path="/products/audio"
                            component={Audio}
                          />
                          <Route
                            exact
                            path="/products/video"
                            component={Video}
                          />
                          <Route exact path="/products/3d" component={Three} />
                          <Route
                            exact
                            path="/products/teachers"
                            component={Teachers}
                          />
                          <Route
                            exact
                            path="/products/exercises"
                            component={Exercises}
                          />
                          <Route
                            path="/products/exercises/:q"
                            component={ExercisesGen}
                          />
                          <Route
                            path="/products/teachers/:chapterID"
                            component={TeacherDetails}
                          />
                          <Route
                            exact
                            path="/products/early"
                            component={EarlyLearning}
                          />
                          <Route
                            exact
                            path="/products/encyclopedias"
                            component={Encyclopedias}
                          />
                          <Route path="/timeline" component={Timeline} />
                          <Route path="/videx" component={VideoExercise} />
                          <Route render={() => <div>No Match</div>} />
                        </Switch>
                      </div>
                    </CSSTransition>
                  </TransitionGroup>
                )}
              />
            </div>
            <footer>
              <Footer />
            </footer>
          </div>
        </Router>
      </main>
    </div>
  );
}

export default App;
