import React, { Component } from 'react';

import './Project.scss';

class Project extends Component {
  render() {
    return <div>About Project Page</div>;
  }
}

export default Project;
