import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ImgList from './imglist';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    backgroundColor: theme.palette.background.paper,
    padding: 15,
  },

  verticalblock: {
    display: 'flex',
    justifyContent: 'space-around',
    backgroundColor: theme.palette.background.paper,
    padding: 15,
  },

  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  imgTile: {},
}));
const TextImgBlock = (props) => {
  const classes = useStyles();
  function getBlock() {
    if (props.vertical) {
      return (
        <div>
          <Paper className={classes.verticalblock}>
            <Typography
              component="p"
              dangerouslySetInnerHTML={{
                __html: '<h2>' + props.title + '</h2>' + props.text,
              }}
            ></Typography>

            <ImgList
              vertical={props.vertical}
              img={props.img}
              imgFolder={props.imgFolder}
            />
          </Paper>
        </div>
      );
    } else {
      return (
        <div>
          <Paper className={classes.root}>
            <Typography variant="h3" component="h3">
              {props.title}
            </Typography>
            <Typography
              component="p"
              dangerouslySetInnerHTML={{ __html: props.text }}
            />
          </Paper>
          <div>
            <br />
            <Paper className={classes.root}>
              <Typography variant="h3" component="h3">
                <ImgList
                  vertical={props.vertical}
                  img={props.img}
                  imgFolder={props.imgFolder}
                />
              </Typography>
            </Paper>
          </div>
        </div>
      );
    }
  }

  return getBlock();
};
export default TextImgBlock;
