import React, { Component } from 'react';

// const iframeStyle = {
//   width: '100%',
//   height: '100%',
// };
class Frame extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: this.props.url,
    };
    this.myRef = React.createRef();
  }

  componentDidMount() {
    const { current } = this.myRef;
    current.addEventListener('load', this.onLoadHandler);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.url !== nextProps.url) {
      this.setState({ url: nextProps.url });
    }
  }

  componentWillUnmount() {
    const { current } = this.myRef;
    current.removeEventListener('load', this.onLoadHandler);
  }

  onLoadHandler = (e) => {
    e.target.contentWindow.postMessage({ clientToken: this.props.token }, '*');
  };

  render() {
    return <iframe src={this.state.url} ref={this.myRef} title="iframe" />;
  }
}

export default Frame;
