import React, { Component } from 'react';
import { TabContent, TabPane, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import './ServicesProducts.scss';

class ServicesProducts extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1',
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    return (
      <div className="ServicesProducts">
        <div className="tabs-nav">
          <Row>
            <Col xs={3}>
              <div
                className={this.state.activeTab === '1' ? 'active right' : ''}
                onClick={() => {
                  this.toggle('1');
                }}
              >
                منتجاتنا
              </div>
            </Col>
            <Col xs={1}>
              <div className="break" />
            </Col>

            <Col xs={3}>
              <div
                className={this.state.activeTab === '2' ? 'active left' : ''}
                onClick={() => {
                  this.toggle('2');
                }}
              >
                خدماتنا
              </div>
            </Col>
          </Row>
        </div>

        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1" className="products-pane">
            <Row>
              <Col xs={6} md={3}>
                <a
                  href="https://damma.app/"
                  target="_blank"
                  className="rounded-column"
                >
                  <div
                    className="icon"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      src="/resources/home/damma_logo.png"
                      alt="Damma Logo"
                      width={'100%'}
                      height={'80%'}
                      style={{ objectFit: 'contain' }}
                    />
                    {/* <i className="fas fa-shapes" /> */}
                  </div>
                  <div className="title">ضمّة</div>
                </a>
              </Col>
              <Col xs={6} md={3}>
                <Link to="/products/video" className="rounded-column">
                  <div className="icon">
                    <i className="fas fa-video" />
                  </div>
                  <div className="title">إثراء</div>
                  {/* </a> */}
                </Link>
              </Col>
              <Col xs={6} md={3}>
                <Link to="/products/3d" className="rounded-column">
                  <div className="icon">
                    <i className="fas fa-cube" />
                  </div>
                  <div className="title">إسهاب</div>
                </Link>
              </Col>
              <Col xs={6} md={3}>
                <Link to="/products/exercises" className="rounded-column">
                  <div className="icon">
                    <i className="fas fa-pencil-alt" />
                  </div>
                  <div className="title">تمكين</div>
                </Link>
              </Col>
              <Col xs={6} md={3}>
                <Link to="/products/teachers" className="rounded-column">
                  <div className="icon">
                    <i className="fas fa-book" />
                  </div>
                  <div className="title">إتقان</div>
                </Link>
              </Col>
              <Col xs={6} md={3}>
                <Link to="/products/audio" className="rounded-column">
                  <div className="icon">
                    <i className="fas fa-headphones" />
                  </div>
                  <div className="title">تذكير</div>
                </Link>
              </Col>
              <Col xs={6} md={3}>
                <Link to="/products/encyclopedias" className="rounded-column">
                  <div className="icon">
                    <i className="fas fa-search" />
                  </div>
                  <div className="title">معرفة</div>
                </Link>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2" className="services-pane">
            <Row>
              <Col sm="5" className="paragraph">
                <div className="text">
                  يقدم مشروع حقائب المستقبل عدد من الخدمات المميزة، أهمها خاصيّة
                  المرونة التي تسمح للعميل تعديل أو زيادة ما شاء من المنتجات.
                  إضافة إلى ذلك، تقدم خدمات المشروع إمكانية العمل على مختلف
                  الأجهزة الإلكترونية، من الحاسوب إلى الهاتف، مع مراقبة وصيانة
                  دائمة للمنتجات. كما ويتواجد خدمة تدريب المعلّمين والمستخدمين
                  على المنتجات الجديدة، وحتى إمكانية دمج هذه المنتجات بنظام
                  التعليم المستخدم سابقاً.
                </div>
                <Link to="/services">المزيد</Link>
              </Col>

              <Col sm="5">
                <img src="/resources/home/client.png" alt="services" />
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

export default ServicesProducts;
