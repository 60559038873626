import React, { Component } from 'react';

import { Row, Col, Container } from 'reactstrap';
import Slider from './Slider';
import ServicesProducts from './ServicesProducts';
import About from './About';

import './Home.scss';
import WhyUs from './WhyUs';

const dfLogo = '/resources/logos/df_logo.svg';

class Home extends Component {
  settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  render() {
    return (
      <div className="home">
        <section className="intro">
          <Container>
            <Row>
              <Col
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '1rem',
                }}
                className="intro-div"
              >
                <div className="text">
                  حقائب المستقبل للمحتويات التعليميّة الرقميّة إعداد
                </div>
                <img
                  src={dfLogo}
                  alt="Digital Future Logo"
                  width={200}
                  height={100}
                />
              </Col>
              {/* <Col sm="2" className="df-logo"></Col> */}
            </Row>
          </Container>
        </section>

        <section className="slider">
          <Container>
            <Row>
              <Col>
                <Slider />
              </Col>
            </Row>
          </Container>
        </section>

        <section className="services-products">
          <Container>
            <Row>
              <Col>
                <ServicesProducts />
              </Col>
            </Row>
          </Container>
        </section>

        <section className="about">
          <Container>
            <Row>
              <Col>
                <About />
              </Col>
            </Row>
          </Container>
        </section>

        <section className="why-us">
          <WhyUs />
        </section>
      </div>
    );
  }
}

export default Home;
