import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import { TitleAppBar } from './titleappbar';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: 300,
    width: '100%',
    marginTop: 70,
    justifyContent: 'space-around',
  },
  image: {
    position: 'relative',
    height: 200,
    margin: 3,
    [theme.breakpoints.down('xs')]: {
      width: '100% !important', // Overrides inline-style
      height: 100,
    },
    '&:hover, &$focusVisible': {
      zIndex: 1,
      '& $imageBackdrop': {
        opacity: 0.15,
      },
      '& $imageMarked': {
        opacity: 0,
      },
      '& $imageTitle': {
        border: '2px solid currentColor',
      },
    },
  },
  focusVisible: {},
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create('opacity'),
  },
  imageTitle: {
    position: 'relative',
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${
      theme.spacing(1) + 6
    }px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  },
}));

export default function CatList(props) {
  const classes = useStyles();
  const [dataArray, setDataArray] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);

  async function fetchData() {
    //console.log("fetching cat data");
    //console.log("/data/" + props.catPath + "/categories.json");
    axios
      .get('/resources/portal/data/' + props.catPath + '/categories.json')
      .then((res) => {
        const dataArray = res.data.data.categories;
        setDataArray(dataArray);
        setDataLoaded(true);
      });
  }

  const getRenderState = () => {
    if (dataLoaded) {
      //console.log(dataArray);
      const cats = dataArray;
      return (
        <div style={{ textAlign: 'center' }}>
          <TitleAppBar
            title={props.catTitle}
            onBackClicked={props.onBackClicked}
          />
          <div className={classes.root}>
            {cats.map((cat) => (
              <ButtonBase
                focusRipple
                key={cat.catName}
                className={classes.image}
                focusVisibleClassName={classes.focusVisible}
                style={{
                  width: props.cellWidth,
                }}
                onClick={() => props.onCatClicked(cat)}
              >
                <span
                  className={classes.imageSrc}
                  style={{
                    backgroundImage: `url(${
                      '/resources/portal/images/' +
                      props.catPath +
                      '/categories/' +
                      cat.imgName
                    })`,
                  }}
                />
                <span className={classes.imageBackdrop} />
                <span className={classes.imageButton}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    color="inherit"
                    className={classes.imageTitle}
                  >
                    {cat.title}
                    <span className={classes.imageMarked} />
                  </Typography>
                </span>
              </ButtonBase>
            ))}
          </div>
        </div>
      );
    } else {
      return <CircularProgress />;
    }
  };

  useEffect(() => {
    fetchData();
  }, [props.catPath]);

  return getRenderState();
}
