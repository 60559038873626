import Pagination from './pagination';

import shuffle from './shuffle';

function MCQMapper(exercise) {
  const { _id } = exercise;
  const { parts } = exercise.questionContent;
  const questions = parts.map((qst, index) => {
    const v = {
      type: 'mcq',
      id: `${_id}_${index}`,
      context: qst.context,
      question: qst.exercise,
      explanation: qst.explanation,
      // TODO: pass choices to parseString function to resolve imgs urls.
      choices: shuffle(qst.choices),
      answer: qst.solution,
    };

    return v;
  });

  return { _id, questions };
}

function QuestionAndAnswerMapper(exercise) {
  const { _id } = exercise;
  const { parts } = exercise.questionContent;
  const questions = parts.map((qst, index) => {
    const v = {
      type: 'qna',
      id: `${_id}_${index}`,
      context: qst.context,
      // TODO: pass to parseString function to resolve imgs urls.
      question: qst.question,
      explanation: qst.explanation,
      answer: qst.answers,
    };

    return v;
  });

  return { _id, questions };
}

function TrueFalseMapper(exercise) {
  const { _id } = exercise;
  const { parts } = exercise.questionContent;
  const questions = parts.map((qst, index) => {
    const v = {
      type: 'tf',
      id: `${_id}_${index}`,
      context: qst.context,
      question: qst.exercise,
      explanation: qst.explanation,
      answer: qst.solution.toString(),
    };

    return v;
  });

  return { _id, questions };
}

function BlanksMapper(exercise) {
  const { _id } = exercise;
  const { parts } = exercise.questionContent;
  const questions = parts.map((qst, index) => {
    const v = {
      type: 'blanks',
      id: `${_id}_${index}`,
      context: qst.context,
      before: qst.before,
      after: qst.after,
      explanation: qst.explanation,
      answer: qst.answers,
      strictLanguage: qst.strictLanguage,
    };

    return v;
  });

  return { _id, questions };
}

/**
 * FIXME: confirm structure with skhatib, expected to be array.
 *
 * @param {*} exercise
 * @returns
 */
function MatchMapper(exercise) {
  const { _id } = exercise;
  const { /* choices, */ elements, type, question } = exercise.questionContent;

  const findBestPaggingVal = (totalItems, showPerPage) => {
    if (totalItems <= showPerPage) return showPerPage;

    const reminder = Number.parseInt(totalItems % showPerPage, 10);

    if ((reminder >= 3 && reminder <= 5) || reminder === 0) {
      return showPerPage;
    }

    return findBestPaggingVal(totalItems, --showPerPage);
  };

  const numOfQst = findBestPaggingVal(elements.length, 6);

  const p = new Pagination(numOfQst, elements.length, 0);
  const arr = [];

  for (let index = 0; index < numOfQst; index++) {
    p.curPageIdx = index;
    const slice = elements.slice(p.start, p.end);
    if (slice.length > 0) {
      arr.push(slice);
    }
  }

  const removeDuplicates = (array) => {
    const result = [];
    array.forEach((element) => {
      if (!result.includes(element)) {
        result.push(element);
      }
    });

    return result;
  };

  const questions = arr.map((qst, index) => {
    // console.log(qst)
    let toElements = removeDuplicates(
      qst
        .map((el) => el.matches)
        /**
         * used to flatten single level array, since .flat() not supported in Edge.
         * see: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/flat#reduce_and_concat
         */
        .reduce((acc, val) => acc.concat(val), [])
    );
    toElements = toElements.map((el, elIdx) => {
      const toClassName = `${_id}_to_${elIdx}`;
      return { text: el, elClassName: toClassName };
    });

    shuffle(toElements);
    // console.log(toElements)
    const v = {
      type: 'match',
      id: `${_id}_${index}`,
      question,
      matchType: type,
      choices: toElements,
      elements: qst,
    };
    return v;
  });

  return { _id, questions, showPerPage: 1 };
}

function SelectMapper(exercise) {
  const { parts } = exercise.questionContent;
  const questionContent = {
    ...exercise.questionContent,
    parts: parts.map((el) => {
      const v = {
        ...el,
        choices: el.choices.map((ch) => ch.choice),
        solution: el.choices.filter((ch) => ch.isCorrect).map((c) => c.choice),
      };
      return v;
    }),
  };

  return MCQMapper({ ...exercise, questionContent });
}

function MapExercise(exercise) {
  const { questionType } = exercise;

  let mappedEx;

  switch (questionType.toLowerCase()) {
    case 'mcq':
      mappedEx = MCQMapper(exercise);
      break;

    case 'select':
      mappedEx = SelectMapper(exercise);
      break;

    case 'truefalse':
      mappedEx = TrueFalseMapper(exercise);
      break;

    case 'qna':
      mappedEx = QuestionAndAnswerMapper(exercise);
      break;

    case 'blanks':
      mappedEx = BlanksMapper(exercise);
      break;

    case 'match':
      mappedEx = MatchMapper(exercise);
      break;

    default:
      mappedEx = exercise;
  }

  // FIXME: wating skhatib to fix MathJax id's. (should removed)
  mappedEx = JSON.stringify(mappedEx).replace(/id='MathJax-Span-\d*'/g, '');
  mappedEx = JSON.parse(mappedEx);

  return {
    ...mappedEx,
    exerciseQuestion: exercise.question,
    questionType: exercise.questionType,
  };
}

export default MapExercise;
