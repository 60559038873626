import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import {
  Player,
  BigPlayButton,
  ControlBar,
  VolumeMenuButton,
  CurrentTimeDisplay,
  TimeDivider,
  PlaybackRateMenuButton,
} from 'video-react';

class VideoModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({ modal: !prevState.modal }));
  }

  render() {
    return (
      <div>
        <div className="text" onClick={this.toggle}>
          {this.props.videoName}
        </div>
        <Modal isOpen={this.state.modal} toggle={this.toggle} backdrop={false}>
          <ModalHeader toggle={this.toggle}>{this.props.videoName}</ModalHeader>
          <ModalBody>
            <Player
              playsInline
              poster={this.props.thumbnail}
              src={this.props.url}
              autoPlay
            >
              <BigPlayButton position="center" />
              <ControlBar>
                {/* <ReplayControl seconds={10} order={1.1} />
            <ForwardControl seconds={30} order={1.2} /> */}
                <VolumeMenuButton order={8.1} />
                <CurrentTimeDisplay order={4.1} />
                <TimeDivider order={4.2} />
                <PlaybackRateMenuButton rates={[5, 2, 1, 0.75]} order={7.1} />
              </ControlBar>
            </Player>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default VideoModal;

VideoModal.propTypes = {
  videoName: PropTypes.string.isRequired,
  thumbnail: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};
