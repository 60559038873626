import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

import ExercisesViewer from '../../players/exercises/ExercisesViewer';
import MapExercise from '../../players/exercises/utils/exercises-mappers';

import './SampleExercises.scss';

import data from './sample_exercises.json';
import FormOptions from './FormOptions';

class SampleExercises extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeEx: null,
    };
  }

  componentDidMount() {
    this.exercises = data.exercises.map((v) => MapExercise(v));

    this.setState({ activeEx: this.exercises[0]._id });
  }

  render() {
    if (!this.exercises) return null;
    const pages = this.exercises.map(
      (el) => FormOptions.types.find((t) => t.value === el.questionType).label
    );
    return (
      <div className="SampleExercises">
        <div className="exNavs bgc-dark">
          <Row className="tabs-nav justify-content-center py-2">
            {pages.map((p, idx) => (
              <Col xs={4} md={2} key={idx}>
                <span
                  className={`nav-container bgc-secondary py-2 mt-2 mt-md-0 ${
                    this.state.activeEx === this.exercises[idx]._id
                      ? 'active'
                      : ''
                  }`}
                  onClick={() => {
                    this.setState({
                      activeEx: this.exercises[idx]._id,
                    });
                  }}
                >
                  {p}
                </span>
              </Col>
            ))}
          </Row>
        </div>
        {this.exercises.map((ex, index) => (
          <ExercisesViewer
            _id={ex._id}
            showPerPage={ex.showPerPage}
            exercises={ex.questions}
            exQuestion={ex.exerciseQuestion}
            visible={this.state.activeEx === ex._id}
            key={index}
          />
        ))}
      </div>
    );
  }
}

export default SampleExercises;
