import * as THREE from 'three';

export default (scene) => {
  // const lightIn = new THREE.PointLight("#4CAF50", 30);
  // const lightOut = new THREE.PointLight("#2196F3", 10);
  // lightOut.position.set(40,20,40);

  // scene.add(lightIn);
  // scene.add(lightOut);

  // const rad = 80;

  function update(time) {
    // const x = rad * Math.sin(time*0.2)
    // lightOut.position.x = x;
  }
  let light;

  // light = new THREE.DirectionalLight(0xffffff);
  // light.position.set(1000, 0, 0);
  // scene.add(light);
  // light = new THREE.DirectionalLight(0xffffff);
  // light.position.set(-1000, 0, 0);
  // scene.add(light);
  // light = new THREE.DirectionalLight(0xffffff);
  // light.position.set(0, 1000, 0);
  // scene.add(light);
  // light = new THREE.DirectionalLight(0xffffff);
  // light.position.set(0, -1000, 0);
  // scene.add(light);
  // light = new THREE.DirectionalLight(0xffffff);
  // light.position.set(0, 1000, 1000);
  // scene.add(light);

  // light = new THREE.DirectionalLight(0xffffff);
  // light.position.set(0, 0, -1000);
  // scene.add(light);

  // Ambient Light for the whole scene
  // light = new THREE.AmbientLight(0xffffff);
  // scene.add(light);

  light = new THREE.HemisphereLight(0xf0f0f0, 0x444444);
  light.position.set(0, 200, 0);
  scene.add(light);
  light = new THREE.DirectionalLight(0xf0f0f0);
  light.position.set(0, 200, 100);
  light.castShadow = true;
  light.shadow.camera.top = 180;
  light.shadow.camera.bottom = -100;
  light.shadow.camera.left = -120;
  light.shadow.camera.right = 120;
  scene.add(light);

  return {
    update,
  };
};
