import React from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
} from 'reactstrap';

function Mvv() {
  return (
    <Container>
      <Row>
        <Col md="4" className="rounded-column">
          <Card>
            <CardBody>
              <CardTitle className="title">رؤيتنا</CardTitle>
              <CardText>
                ريادة الدّعم التَّعليميّ العربيّ، والنَّشر الثَّقافيّ والمعرفيّ،
                نحو الارتقاء بالأجيال حول العالم.
              </CardText>
            </CardBody>
          </Card>
          {/* <div className="title">رؤيتنا</div>
          <div className="text">
          </div> */}
        </Col>
        <Col md="4" className="rounded-column">
          <Card>
            <CardBody>
              <CardTitle className="title">رسالتنا</CardTitle>
              <CardText>
                تقديم مُنتجاتٍ وخدماتٍ متنوّعة للمُجتمعات التي تُعنى بالعلم
                والمعرفة، عبر إنتاج مُحتوى تعليميّ مُتقن، وتطويره رقمياً، وتوفير
                وسائل تعليميّة مُبتكرة، وكتب وأدوات وبرمجيّات وتطبيقات مُتقدّمة،
                تُعنى بتنمية القُدرات والمهارات، وِفقاً لأحدث التّقنيّات
                البصريّة والسّمعيّة، والأساليب العصريّة والتّفاعليّة الممتعة.
              </CardText>
            </CardBody>
          </Card>
        </Col>
        <Col md="4" className="rounded-column">
          <Card>
            <CardBody>
              <CardTitle className="title">قيَمنا</CardTitle>
              <CardText>
                • المصداقيّة <br />• الاتقان <br />• التّميّز <br />• المرونة{' '}
                <br />• التّنوّع والتّنافسيّة
              </CardText>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Mvv;
