import React from 'react';
import { Container } from 'reactstrap';

import Mvv from './Mvv';
import './Us.scss';
import Map from './Map';
import Domains from './Domains';
import Partners from './Partners';

function Us() {
  return (
    <div className="about-us">
      <section className="definition">
        <Container>
          <i className="fas fa-quote-right icon" />
          المستقبل الرقميّ هي : شركة رائدة في مجال النشر وإنتاج المحتوى
          التّعليميّ الرقميّ. تعمل في كل مستويات وأوجه التٌعلم، سواء الرقميّ
          منها أو التّقليديّ، ولكل المراحل التّعليميّة. مركزُها الرئيسي في لبنان
          في مدينة بيروت، وقد تم إنشائها عام 1986 م.
          <i className="fas fa-quote-left icon" />
        </Container>
      </section>
      {/* MVV: Mission  Vision  Values */}
      <section className="mvv">
        <Mvv />
      </section>

      <section className="map">
        <Map />
      </section>

      <section className="domains">
        <Domains />
      </section>

      <section className="partners">
        <Partners />
      </section>
    </div>
  );
}

export default Us;
