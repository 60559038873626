import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'reactstrap';
import threeEntryPoint from './lib/threeEntryPoint';
import './ThreeViewer.css';

export default class ThreeViewer extends Component {
  sceneManager = null;

  constructor(props) {
    super(props);
    this.resetCamera = this.resetCamera.bind(this);
    this.getCamera = this.getCamera.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      this.sceneManager = threeEntryPoint(
        this.threeRootElement,
        this.props.options,
        this.props.stats
      );
      this.sceneManager.manager().onLoad = () => {
        const element = document.getElementById('three-loader-bar');
        element && element.parentNode.removeChild(element);
      };
    }, 0);
  }

  componentDidUpdate() {
    this.sceneManager.resetModel(this.props.options);
    // threeEntryPoint(this.threeRootElement, this.props.modelPath)
  }

  componentWillUnmount() {
    this.sceneManager.deleteScene();
  }

  getCamera() {
    console.log(this.sceneManager.getCamera());
  }

  resetCamera() {
    this.sceneManager.resetCamera();
  }

  gotoCamera(position, target) {
    this.sceneManager.moveCamera(position, target, 1000);
  }

  render() {
    return (
      <div style={{ height: '100%' }}>
        <div
          className="model-container"
          ref={(element) => {
            this.threeRootElement = element;
          }}
        >
          <div className="three-home">
            <div onClick={this.resetCamera}>
              <i className="fas fa-home" />
            </div>
            {this.props.options.cameras.map((cam, key) =>
              key === 0 ? (
                ''
              ) : (
                <div onClick={() => this.gotoCamera(cam.position, cam.target)}>
                  {key}
                </div>
              )
            )}
          </div>

          {/* <div onClick={this.getCamera} className="three-home">
            SHOW CAM
          </div> */}

          <div className="three-load-bar" id="three-loader-bar">
            <Spinner className="three-spinner" />
            <div>جاري التحميل...</div>
          </div>
        </div>
      </div>
    );
  }
}

ThreeViewer.propTypes = {
  options: PropTypes.objectOf({
    name: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    cameras: PropTypes.arrayOf(
      PropTypes.objectOf({
        position: PropTypes.objectOf({
          x: PropTypes.string.isRequired,
          y: PropTypes.string.isRequired,
          z: PropTypes.string.isRequired,
        }),
        target: PropTypes.objectOf({
          x: PropTypes.string.isRequired,
          y: PropTypes.string.isRequired,
          z: PropTypes.string.isRequired,
        }),
      })
    ).isRequired,
    controls: PropTypes.objectOf({
      minDistance: PropTypes.string,
      maxDistance: PropTypes.string,
      enablePan: PropTypes.string,
      enableZoom: PropTypes.string,
      enableRotate: PropTypes.string,
      minAngle: PropTypes.string,
      maxAngle: PropTypes.string,
    }),
  }).isRequired,
  stats: PropTypes.bool.isRequired,
};
