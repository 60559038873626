import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import './About.scss';

// eslint-disable-next-line react/prefer-stateless-function
class About extends Component {
  render() {
    return (
      <div className="About">
        <Row>
          <Col xs={12} lg={10} xl={6} className="paragraph">
            <div className="title">من نحن</div>
            <div className="text">
              المستقبل الرقميّ هي شركة رائدة في مجال النشر وإنتاج المحتوى
              التّعليميّ الرقميّ، تعمل في كل مستويات وأوجه التٌعلم، سواء الرقميّ
              منها أو التّقليديّ، ولكل المراحل التّعليميّة. مركزُها الرئيسي في
              لبنان في مدينة بيروت، وقد تم إنشائها عام 1986 م.
              <br />
              منذ نشأتها، بدأت الشركة بتطوير التعليم العربي، والعمل على مختلف
              المنتجات التي تنهض بالتعليم، فحققت تطوراً وانتشاراً كبيرين، لتصبح
              من أكبر مُنتجي البرامج التّعليميّة في العالم العربي.{' '}
            </div>
            <Link to="/about/us">المزيد</Link>
          </Col>

          <Col xs={12} lg={10} xl={6} className="paragraph">
            <div className="title">حول المشروع</div>
            <div className="text">
              مشروع حقائب المستقبل هو مشروع يهدف للدعم التعليمي عبر تبسيط
              المفاهيم العلمية لتطوير أداء المدرسين في عملية الشرح. يقدّم
              المشروع عدّة منتجات وخدمات تعليميّة وتقنيّة. يزوّد المشروع
              المدرسين بشرح شامل لدروسهم، يساعدهم على تحضير المادة بشكل ممتع
              ودقيق. ويزيد على ذلك بمكتبة ضخمة من الأسئلة والإختبارات التي تساعد
              على ضمانة فهم الطلاب للدرس. كما ويوفّر المشروع مواد إثرائية من
              فيديوهات وتسجيلات صوتيّة ونماذج ثلاثية الأبعاد تساعد الأستاذ على
              شرح أهم نقاط الدرس، كما وتساعد الطالب على فهم المواد بطريقة مبسّطة
              وسريعة.
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default About;
