import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Row,
  Col,
  UncontrolledPopover,
  PopoverBody,
} from 'reactstrap';

import './WhyUs.scss';

function WhyUs() {
  return (
    <Container className="WhyUs">
      <Row className="title-section">
        <div className="page-title">لماذا نحن</div>
      </Row>
      <Row>
        <Unit
          image="/resources/home/library.jpg"
          title="الخبرة"
          text="خبرتنا في مجالي الإنتاج الإلكتروني والتعليمي تفوق ٣٣ عاماً... نقدّم أحدث المنتجات والخدمات منذ عام ١٩٨٦"
          targetId="d1"
          position="bottom"
        />
      </Row>
      <Row className="shorter-row">
        <Unit
          image="/resources/home/ready.jpg"
          title="الجاهزية"
          text="فريقنا الكبير جاهز لتلبية طلبات عملائنا، والمحتويات التعليمية متوفّرة وجاهزة للتطبيق بشكلٍ مباشر"
          targetId="d2"
          position="right"
        />
        <Col xs={1} md={2} lg={1} className="separator-sm" />
        <Unit
          image="/resources/home/meeting.jpg"
          title="المرونة"
          text="نعمل بتعاونٍ تامٍّ مع عملائنا لتقديم الخدمات الأفضل لهم، وتعديل المنتجات بما يتناسب مع رغباتهم"
          targetId="d3"
          position="left"
        />
      </Row>
      <Row className="shorter-row">
        <Unit
          image="/resources/home/quality.jpeg"
          title="الجودة"
          text="نعتزّ بجودة منتجاتنا المشهود لها من قبل عملائنا، ونحافظ على هذه الجودة باستمرار عبر تخصيص فريقٍ محترفٍ في معايير الجودة العالميّة"
          targetId="d4"
          position="right"
        />
        <Col xs={3} md={5} lg={3} className="separator-md">
          <div>المستقبل الرقمي</div>
        </Col>
        <Unit
          image="/resources/home/earth.jpg"
          title="العالميّة"
          text="توزّع منتجاتنا في أكثر من ١٢٠ دولة حول العالم، ونتعاون مع الكثير من الشركات العالميّة في المجال التعليمي"
          targetId="d5"
          position="left"
        />
      </Row>
      <Row className="shorter-row">
        <Unit
          image="/resources/home/clock.jpg"
          title="الفعاليّة"
          text="ننجز أعمالنا ومشاريعنا بشكلٍ مُتقَن ونعمَل بإنتاجيّة مرتفعة لنحقّق لعملائنا نجاحاتٍ مبهرة في مدّة زمنيّة قياسيّة"
          targetId="d6"
          position="right"
        />
        <Col xs={1} md={2} lg={1} className="separator-sm" />
        <Unit
          image="/resources/home/shake.jpg"
          title="المصداقيّة"
          text="نتعامل مع الجميع بكامل المصداقيّة. شركاؤنا العالميّون يفتخرون بمنجاتنا ويثقون بوعودنا"
          targetId="d7"
          position="left"
        />
      </Row>
      <Row className="shorter-row">
        <Unit
          image="/resources/home/phone.jpg"
          title="الريادة"
          text="نفتخر بأننا سبّاقون دائماً في أعمالنا. نطبّق أحدث التقنيّات لتكون منتجاتنا متميّزةً ورائدة"
          targetId="d8"
          position="top"
        />
      </Row>
    </Container>
  );
}

export default WhyUs;

function Unit(props) {
  return (
    <Col xs={10} md={3} className="unit">
      <button id={props.targetId} className="element" type="button">
        <div
          className="image"
          style={{ backgroundImage: `url("${props.image}")` }}
        />
        <div className="text">{props.title}</div>
        <UncontrolledPopover
          placement={props.position}
          target={props.targetId}
          trigger="focus"
          className="large-screen"
        >
          <PopoverBody>{props.text}</PopoverBody>
        </UncontrolledPopover>
        <UncontrolledPopover
          placement="bottom"
          target={props.targetId}
          trigger="focus"
          className="small-screen"
        >
          <PopoverBody>{props.text}</PopoverBody>
        </UncontrolledPopover>
      </button>
    </Col>
  );
}

Unit.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  targetId: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
};
