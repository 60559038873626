import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import list from './lessons-list.json';

import './SampleIcons.scss';

export default function SampleIcons() {
  return (
    <Container className="early-sample-icons">
      <Row>
        {list.map((lesson) => (
          <Col xs={3} sm={2} className="lesson-container">
            <div
              style={{
                backgroundImage: `url("/resources/earlylearning/list/${lesson.icon}")`,
              }}
              className="icon"
            />
            <div className="title">{lesson.title}</div>
          </Col>
        ))}
      </Row>
    </Container>
  );
}
