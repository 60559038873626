import React from 'react';
import { Container, Row, Col } from 'reactstrap';

function Partners() {
  const partners = [
    {
      logo: '/resources/logos/videos/bbcmotion.jpg',
      nav: 'http://www.motiongallery.com/',
    },
    {
      logo: '/resources/logos/videos/Clickview.jpg',
      nav: 'https://www.clickview.com.au/',
    },
    {
      logo: '/resources/logos/videos/discovery.png',
      nav: 'https://www.discovery.com/',
    },
    {
      logo: '/resources/logos/videos/nationalg.png',
      nav: 'https://www.nationalgeographic.com/',
    },
    {
      logo: '/resources/logos/videos/sterling.png',
      nav: 'http://www.sterlingpixels.com/',
    },
    {
      logo: '/resources/logos/videos/twig.jpg',
      nav: 'https://twigeducation.com/',
    },
  ];
  return (
    <Container className="logos">
      <div className="title">شركاؤنا</div>
      <Row>
        {partners.map((partner, key) => (
          <Col key={key} xs="6" sm="6" md="4" lg="3" className="logo-columns">
            <a href={partner.nav} target="_blank" rel="noopener noreferrer">
              <img src={partner.logo} alt="" />
            </a>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default Partners;
