import React, { Component } from 'react';
import { Row, Col, Container } from 'reactstrap';

import './Footer.scss';

// import dfLogo from '../../resources/imgs/df-logo.png';

const dfLogo = '/resources/logos/df_logo.svg';
const logo = '/resources/logos/logo.svg';

// eslint-disable-next-line react/prefer-stateless-function
class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <Container>
          <Row className="upper-row">
            <Col sm="4" className="logo">
              <a href="/">
                <img src={logo} alt="حقائب المستقبل" />
              </a>
            </Col>

            <Col sm="4" className="social-icons">
              <h5>مواقع التواصل الاجتماعي</h5>

              <Row>
                <Col xs={4} md={4}>
                  <a
                    href="https://www.facebook.com/digitalfuture/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-facebook" aria-hidden="true" />
                  </a>
                </Col>
                <Col xs={4} md={4}>
                  <a
                    href="https://twitter.com/DigitalFutureCA"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-twitter" aria-hidden="true" />
                  </a>
                </Col>
                <Col xs={4} md={4}>
                  <a
                    href="https://plus.google.com/116843837167063366767"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-google-plus" aria-hidden="true" />
                  </a>
                </Col>
                <Col xs={4} md={4}>
                  <a
                    href="https://www.instagram.com/digitalfutureca/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-instagram" aria-hidden="true" />
                  </a>
                </Col>
                <Col xs={4} md={4}>
                  <a
                    href="https://www.youtube.com/user/digitalfuturevideo"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-youtube" aria-hidden="true" />
                  </a>
                </Col>
              </Row>
            </Col>

            <Col sm="4" className="contact-us">
              <h5>تواصل معنا</h5>
              <p>بيروت، لبنان</p>
              <p>
                بريد الكتروني:{' '}
                <a href="mailto:info@digital-future.net">
                  info@digital-future.net
                </a>
              </p>
              <p>هاتف: 856656 1 961+</p>
              <p>فاكس: 837197 1 961+</p>
            </Col>
          </Row>

          <hr />

          <Row className="lower-row">
            <Col sm="6" className="df-logo">
              <img
                src={dfLogo}
                alt="Digital Future Logo"
                width={200}
                height={100}
              />
            </Col>

            <Col sm="6" className="copyright">
              <p>
                جميع الحقوق محفوظة لشركة{' '}
                <a
                  href="http://digital-future.ca"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  المستقبل الرقمي
                </a>
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Footer;
