import React from 'react';
import PropTypes from 'prop-types';
// import queryString from 'query-string';
import ExercisesViewer from './ExercisesViewer';
import MapExercise from './utils/exercises-mappers';

import DataService from '../../../data/dataService';
import { parseString } from '../../../data/data';

import PageNavigator from '../common/PageNavigator';

import './Exercises.scss';

class Exercises extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentExercise: 'mcq',
      chapterTitle: '',
      lessonTitle: '',

      mobileExersMenuShow: false,
    };

    // Make me true to use Static Mocks.
    this.useStaticMocks = false;
  }

  componentDidMount() {
    const { chapterID } = this.props;

    if (chapterID) {
      this.loadDynamicData();
    } else {
      this.loadStaticData();
    }
  }

  loadStaticData = () => {
    const { data } = this.props;

    const v = JSON.parse(
      parseString(
        JSON.stringify(data.exercises),
        data.exercises.resourcesFolder,
        data.exercises._id,
        data.lesson.subject,
        'exercises',
        true
      )
    );

    // map exercises Obj comming from API.
    this.exercises = v.exercises.map((ex) => MapExercise(ex));

    this.setState({
      currentExercise: this.exercises[0]._id,
      chapterTitle: '',
      lessonTitle: data.lesson.title,
    });
  };

  loadDynamicData = () => {
    // const query = queryString.parse(this.props.location.search);
    const query = { id: this.props.chapterID, c: this.props.chapterTitle };

    DataService.getExercises(query.id).then((response) => {
      // console.log(response.data);
      // Check the console to know more how the data looks
      // It is made of two elements:
      // 1- lesson: the parent lesson which the exercises belongs to
      // 2- exercises: contains all the parts...

      // Modify the data as you wish and restructure it
      // Use the parseString function for the data
      // End this function by using setState...

      const v = JSON.parse(
        parseString(
          JSON.stringify(response.data.exercises),
          response.data.exercises.resourcesFolder,
          response.data.exercises._id,
          response.data.lesson.subject,
          'exercises',
          true
        )
      );

      // map exercises Obj comming from API.
      this.exercises = v.exercises.map((ex) => MapExercise(ex));

      this.setState({
        currentExercise: this.exercises[0]._id,
        chapterTitle: query.c,
        lessonTitle: response.data.lesson.title,
      });
    });
  };

  changeExercise = (type) => {
    this.setState(
      {
        currentExercise: this.exercises[type]._id,
      },
      () => {
        document
          .getElementById('exercisesContent')
          .scroll({ top: 0, behavior: 'smooth' });
        this.exersMenuToggle();
      }
    );
  };

  exersMenuToggle = () => {
    this.setState((prev) => ({
      mobileExersMenuShow: !prev.mobileExersMenuShow,
    }));
  };

  render() {
    if (!this.exercises) return null;
    const pages = this.exercises.map((el, index) => `التمرين ${index + 1}`);
    return (
      <div className="exercises">
        <div
          className={`ex-navs-toggle ${
            this.state.mobileExersMenuShow ? 'show' : ''
          }`}
        >
          <span onClick={this.exersMenuToggle}>التمارين</span>
        </div>
        <div
          className={`exercises-navigator ${
            this.state.mobileExersMenuShow ? 'show' : ''
          }`}
        >
          <PageNavigator
            navHandler={this.changeExercise}
            pages={pages}
            currentPage={this.exercises.findIndex(
              (el) => el._id === this.state.currentExercise
            )}
          />
        </div>
        <div id="exercisesContent" className="exercises-body">
          <div className="exercises-title">
            {/* <div className="exercises-chapter-title">{this.state.chapterTitle}</div>
            <div className="exercises-chapter-title exercises-bold">{this.state.lessonTitle}</div>
            <div className="exercises-chapter-title exercises-bold">التمارين الذكيّة</div> */}
          </div>

          {this.exercises.map((ex, index) => (
            <ExercisesViewer
              _id={ex._id}
              showPerPage={ex.showPerPage}
              exercises={ex.questions}
              exQuestion={ex.exerciseQuestion}
              visible={this.state.currentExercise === ex._id}
              key={index}
            />
          ))}
        </div>
      </div>
    );
  }
}

Exercises.propTypes = {
  // location: PropTypes.objectOf(PropTypes.any).isRequired,
  chapterID: PropTypes.string,
  chapterTitle: PropTypes.string,
  data: PropTypes.objectOf(PropTypes.any),
};

Exercises.defaultProps = {
  chapterID: null,
  chapterTitle: null,
  data: null,
};

export default Exercises;
