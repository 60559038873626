import React from 'react';
import { Container } from 'reactstrap';
import Models from './Models';
import data from './models-list.json';
import './Three.scss';

function Three() {
  // TODO: add widget and add more content to page
  // FIXME: fix threejs bug caused by update
  return (
    <div className="three">
      <section className="title-section">
        <Container>
          <div className="page-title">إسهاب</div>
          <div className="page-subtitle">
            نماذج ثلاثية الأبعاد تساعد المدرّس على الإسهاب في الشّرح وتسمح
            للطالب بالتفاعل مع الدروس لفهمها بشكلٍ عمليّ ودقيق
          </div>
        </Container>
      </section>
      <section className="models">
        <Models models={data} />
      </section>
    </div>
  );
}

export default Three;
