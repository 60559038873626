import React, { Component } from 'react';

const HtmlToReactParser = require('html-to-react').Parser;

class Mparser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      htmls: [],
      comps: [],
    };
    this.showPopup = this.showPopup.bind(this);
  }

  componentDidMount() {
    window.showPopup = this.showPopup;
  }

  showPopup(e) {
    var ndx = this.props.functions
      .map(function (d) {
        return d['name'];
      })
      .indexOf('{showPopup}');
    this.props.functions[ndx].fn(e);
  }

  getInnerProps(html, openTag, closeTag) {
    let propsStr = '';
    const spos = html.indexOf(openTag);
    let epos = html.indexOf(closeTag, spos);
    if (spos !== -1) {
      propsStr = html.slice(spos + openTag.length + 1, epos);
    }
    return propsStr;
  }

  getPanelProps(html, openTag) {
    let propsStr = '';
    const spos = html.indexOf(openTag);
    let epos = html.indexOf('>', spos);
    let npos = html.indexOf('<', spos + openTag.length);
    while (npos !== -1 && epos !== -1 && epos > npos) {
      npos = html.indexOf('<', epos);
      epos = html.indexOf('>', epos + 1);
    }
    if (spos !== -1) {
      propsStr = html.slice(spos + openTag.length + 1, epos);
    }
    return propsStr;
  }

  getInnerHtml(html) {
    let spos = html.indexOf('>');
    let npos = html.indexOf('<', 1);
    while (npos !== -1 && spos !== -1 && spos > npos) {
      npos = html.indexOf('<', spos);
      spos = html.indexOf('>', spos + 1);
    }
    const epos = html.indexOf('</Panel>', spos);
    const innerHtml = html.slice(spos + 1, epos);
    return this.htmlToReactParser.parse(innerHtml);
  }

  splitCollapses(html) {
    const cIndex = this.indexOfComponentByName('CollapseComp');
    const pIndex = this.indexOfComponentByName('CollapsePanel');
    let spos = html.indexOf('<Collapse');
    while (cIndex !== -1 && pIndex !== -1 && spos >= 0) {
      let epos = html.indexOf('>', spos);
      let props = html.slice(spos + '<Collapse'.length, epos);
      props += ' accordion=true' + ' key=' + this.guidGenerator();
      let propsJson = this.propsStrToJsn(props);
      propsJson.className = propsJson.class;
      epos = html.indexOf('</Collapse>');
      let collapseChunk = html.slice(spos, epos + '</Collapse>'.length);
      html = html.replace(collapseChunk, '');
      let collapseStr = html.slice(0, spos);
      html = html.replace(collapseStr, '');

      var panels = [];
      let pos = collapseChunk.indexOf('<Panel');
      while (pos !== -1) {
        epos = collapseChunk.indexOf('</Panel>', pos);
        const panelStr = collapseChunk.slice(pos, epos + '</Panel>'.length);
        collapseChunk = collapseChunk.replace(panelStr, '');
        let propsStr = this.getPanelProps(panelStr, '<Panel');
        propsStr = propsStr.replace("'", '{');
        propsStr = propsStr.replace("'", '}');
        let jsonProps = this.propsStrToJsn(propsStr);
        jsonProps.key = this.guidGenerator();
        let innerHtml = this.getInnerHtml(panelStr);

        let panel = React.cloneElement(
          this.props.comps[pIndex].component,
          jsonProps,
          innerHtml
        );

        panels.push(panel);
        pos = collapseChunk.indexOf('<Panel');
      }

      let comp = React.cloneElement(
        this.props.comps[cIndex].component,
        propsJson,
        panels
      );

      this.state.comps.push(comp);
      this.state.htmls.push({ comp, str: collapseStr });
      spos = html.indexOf('<Collapse');
    }
    return html;
  }

  getFirstComponentData(html) {
    let props = '';
    let cIndex = -1;
    let index = 0xffff;
    let epos = -1;
    for (let i = 0; i < this.props.comps.length; i++) {
      const spos = html.indexOf(`<${this.props.comps[i].name}`);
      epos = html.indexOf('/>', spos);
      if (html.indexOf('{`') < epos && html.indexOf('`}') > epos)
        epos = html.indexOf('/>', html.indexOf('`}'));
      if (spos !== -1 && index > spos) {
        index = spos;
        cIndex = i;
        props = html.slice(spos + this.props.comps[i].name.length + 1, epos);
      }
    }
    return { cIndex: cIndex, prop: props, epos: epos };
  }

  addJsonValueToStr(propsJsnStr, value) {
    if (value === 'true' || value === 'false')
      return propsJsnStr.concat([value], [',']);
    else {
      value = value.replace(/"/g, '\\"');
      let newStr = propsJsnStr.concat(['"'], [value], ['"'], [',']);
      newStr = newStr.replace(/\"\\\"/g, '"');
      newStr = newStr.replace(/\\\""/g, '"');
      return newStr;
    }
  }

  filterJsonStr(str) {
    //str = str.replace(/\\"/g, '');
    //str = str.replace(/\"/g, '');
    //str = str.replace(/"/g, '');
    str = str.replace(/'/g, '');
    str = str.replace(/`/g, '');
    return str.replace(/\n/g, ' ').trim();
  }

  propsStrToJsn(str) {
    let props = this.filterJsonStr(str);
    if (props.length === 0) return null;
    let propsJsnStr = '{';
    let npos = props.indexOf('=');
    while (npos !== -1) {
      let value = '';
      const key = props.slice(0, npos);
      props = props.slice(npos + 1, props.length).trim();
      propsJsnStr = propsJsnStr.concat(['"'], [key], ['"'], [':']);
      if (props[0] === '{') {
        npos = props.indexOf('}');
        if (props[1] === '[') {
          value = props.slice(1, npos).trim();
          props = props.slice(npos + 1, props.length).trim();
          value = value.slice(1, value.length - 2).trim();
          const list = value.split(',');
          propsJsnStr = propsJsnStr.concat(['[']);
          for (let i = 0; i < list.length; i++) {
            if (list[i].length > 0) {
              if (list[i][list[i].length - 1] === '\\')
                list[i] = list[i].slice(0, list[i].length - 1);
              propsJsnStr = this.addJsonValueToStr(propsJsnStr, list[i].trim());
            }
          }
          propsJsnStr = propsJsnStr.slice(0, propsJsnStr.length - 1);
          propsJsnStr = propsJsnStr.concat(['],']);
        } else {
          value = props.slice(1, npos).trim();
          props = props.slice(npos + 1, props.length).trim();
          propsJsnStr = this.addJsonValueToStr(propsJsnStr, value);
        }
      } else if (props[0] === '\\') {
        npos = props.indexOf('"', 2);
        value = props.slice(0, npos + 1).trim();
        props = props.slice(npos + 1, props.length).trim();
        propsJsnStr = this.addJsonValueToStr(propsJsnStr, value);
      } else {
        npos = props.indexOf(' ');
        if (npos === -1) npos = props.length;
        value = props.slice(0, npos).trim();
        props = props.slice(npos + 1, props.length).trim();
        propsJsnStr = this.addJsonValueToStr(propsJsnStr, value);
      }
      npos = props.indexOf('=');
    }
    if (propsJsnStr[propsJsnStr.length - 1] === ',')
      propsJsnStr = propsJsnStr.slice(0, propsJsnStr.length - 1);
    propsJsnStr = propsJsnStr.concat('}');
    //console.log("propsJsnStr = ", propsJsnStr);
    return JSON.parse(propsJsnStr);
  }

  cloneComponentFromPropsData(ComponentData) {
    const component = this.props.comps[ComponentData.cIndex].component;
    const propsJsn = this.propsStrToJsn(ComponentData.prop.trim());
    propsJsn.key = this.guidGenerator();
    const comp = React.cloneElement(component, propsJsn);
    this.state.comps.push(comp);
  }

  replaceFunctions(html) {
    for (let i = 0; i < this.props.functions.length; i++) {
      let fn = this.props.functions[i];
      let replaceThis = fn['event'] + '=' + fn['name'];
      let byThis =
        'onclick=window.' +
        fn['name'].slice(1, fn['name'].length - 1) +
        '(this)';
      html = html.replace(new RegExp(replaceThis, 'g'), byThis);
    }
    return html;
  }

  splitHtmls() {
    this.state.htmls = [];
    this.state.comps = [];
    let html = this.replaceFunctions(this.props.string);
    if (this.props.comps && this.props.comps.length > 0) {
      let componentData = this.getFirstComponentData(html);
      while (componentData.cIndex !== -1) {
        this.cloneComponentFromPropsData(componentData);
        let index = html.indexOf(this.props.comps[componentData.cIndex].name);
        var htmlChunk = html.slice(0, index - 1);
        index = componentData.epos;
        const slice = html.slice(0, index + 2);
        html = html.replace(slice, '');
        htmlChunk = this.splitCollapses(htmlChunk);
        this.state.htmls.push({
          comp: this.state.comps[this.state.comps.length - 1],
          str: htmlChunk,
        });
        componentData = this.getFirstComponentData(html);
      }
    }
    if (html.trim().length > 0) {
      html = this.splitCollapses(html);
      this.state.htmls.push({ comp: null, str: html });
    }
  }

  indexOfComponentByName(name) {
    var index = -1;
    var comp = this.props.comps.find(function (item, i) {
      if (item.name === name) {
        index = i;
        return i;
      }
    });
    return index;
  }

  guidGenerator() {
    var S4 = function () {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return (
      S4() +
      S4() +
      '-' +
      S4() +
      '-' +
      S4() +
      '-' +
      S4() +
      '-' +
      S4() +
      S4() +
      S4()
    );
  }

  parse() {
    this.htmlToReactParser = new HtmlToReactParser();
    // console.log("... start ...", this.props.string);
    this.splitHtmls();
    let comps = [];
    for (let i = 0; i < this.state.htmls.length; i++) {
      if (this.state.htmls[i].str.length > 0) {
        let reactElements = (
          <div dangerouslySetInnerHTML={{ __html: this.state.htmls[i].str }} />
        );
        comps.push(reactElements);
      }
      const comp = this.state.htmls[i].comp;
      comps.push(comp);
    }
    // console.log("... finish ...", comps);
    return comps;
  }

  render() {
    return <div> {this.parse()} </div>;
  }
}

export default Mparser;
