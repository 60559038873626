import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Row, Col } from 'reactstrap';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';

import WaveSurfer from 'wavesurfer.js';

import './AudioPlayer.scss';

class AudioPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPlaying: false,
      loaded: false,
      volume: props.volume,
      mute: props.mute,
    };

    this.waveContainer = React.createRef();
  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.src !== this.props.src) {
      this.waveSufer.destroy();
      this.setState({
        isPlaying: false,
        loaded: false,
        volume: this.props.volume,
        mute: this.props.mute,
      });
      this.init();
    }
  }

  componentWillUnmount() {
    this.waveSufer.destroy();
  }

  init = () => {
    this.waveSufer = WaveSurfer.create({
      container: this.waveContainer.current,
      waveColor: '#98c1d9',
      progressColor: '#e0fbfc',
      height: 120,
      barWidth: 3,
      responsive: true,
    });

    this.waveSufer.load(this.props.src);

    // register events handlers
    this.waveSufer.on('ready', () => {
      this.setState({ loaded: true }, () => {
        this.changeVol(this.state.volume);

        if (this.props.autoPlay) this.waveSufer.play();
      });
    });

    this.waveSufer.on('play', () => {
      this.setState({ isPlaying: true });
    });

    this.waveSufer.on('pause', () => {
      this.setState({ isPlaying: false });
    });

    this.waveSufer.on('finish', () => {
      if (this.props.onFinish) this.props.onFinish();
    });

    this.waveSufer.on('error', (e) => {
      console.log(e);
    });
  };

  playPause = () => {
    this.waveSufer.playPause();
  };

  stop = () => {
    this.waveSufer.stop();
    this.setState({ isPlaying: false });
  };

  skipForward = () => {
    this.waveSufer.skipForward(5);
  };

  skipBackward = () => {
    this.waveSufer.skipBackward(5);
  };

  changeVol = (v) => {
    this.setState({ volume: v }, () => {
      this.waveSufer.setVolume(Number.parseFloat(v / 10));
    });
  };

  toggleMute = () => {
    this.setState(
      (prevState) => ({ mute: !prevState.mute }),
      () => {
        this.waveSufer.toggleMute();
      }
    );
  };

  handleOnNextClick = () => {
    if (this.props.onNextClick) this.props.onNextClick();
  };

  handleOnPrevClick = () => {
    if (this.props.onPrevClick) this.props.onPrevClick();
  };

  render() {
    return (
      <div className="audio-player">
        <div className="wave-container" ref={this.waveContainer}>
          {!this.state.loaded && <h3>جاري التحميل...</h3>}
        </div>
        <Row className="controls">
          <Col className="btns justify-content-center justify-content-md-end">
            <div
              className="btn"
              onClick={this.handleOnNextClick}
              type="button"
              disabled={!this.state.loaded}
            >
              <i className="fas fa-step-forward" aria-hidden="true" />
            </div>

            <div
              className="btn"
              onClick={this.skipForward}
              type="button"
              disabled={!this.state.loaded}
            >
              <i className="fas fa-forward" aria-hidden="true" />
            </div>

            <div
              className="btn"
              onClick={this.stop}
              type="button"
              disabled={!this.state.loaded}
            >
              <i className="fas fa-stop" aria-hidden="true" />
            </div>

            <div
              className="btn"
              onClick={this.playPause}
              type="button"
              disabled={!this.state.loaded}
            >
              {!this.state.isPlaying && (
                <i className="fas fa-play" aria-hidden="true" />
              )}
              {this.state.isPlaying && (
                <i className="fas fa-pause" aria-hidden="true" />
              )}
            </div>

            <div
              className="btn"
              onClick={this.skipBackward}
              type="button"
              disabled={!this.state.loaded}
            >
              <i className="fas fa-backward" aria-hidden="true" />
            </div>

            <div
              className="btn"
              onClick={this.handleOnPrevClick}
              type="button"
              disabled={!this.state.loaded}
            >
              <i className="fas fa-step-backward" aria-hidden="true" />
            </div>
          </Col>

          <Col xs={12} md={6} className="volume order-md-first mt-4 mt-md-0">
            <InputRange
              allowSameValues={false}
              minValue={1}
              maxValue={10}
              value={this.state.volume}
              onChange={this.changeVol}
              disabled={this.state.mute}
            />

            <div
              className="btn"
              onClick={this.toggleMute}
              type="button"
              disabled={!this.state.loaded}
            >
              {this.state.mute && (
                <i className="fas fa-volume-mute" aria-hidden="true" />
              )}
              {!this.state.mute && (
                <i className="fas fa-volume-up" aria-hidden="true" />
              )}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

AudioPlayer.propTypes = {
  src: PropTypes.string.isRequired,
  autoPlay: PropTypes.bool,
  volume: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]),
  mute: PropTypes.bool,
  onFinish: PropTypes.func,
  onNextClick: PropTypes.func,
  onPrevClick: PropTypes.func,
};

AudioPlayer.defaultProps = {
  autoPlay: false,
  volume: 5,
  mute: false,
  onFinish: null,
  onNextClick: null,
  onPrevClick: null,
};

export default AudioPlayer;
