import React, { Component } from 'react';
import { TabContent, TabPane, Row, Col } from 'reactstrap';

import './ActivitesTabs.scss';

class ActivitesTabs extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1',
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    return (
      <div className="activites-tabs">
        <Row className="tabs-nav">
          <Col xs={4} md={3}>
            <div
              className={`nav-container ${
                this.state.activeTab === '1' ? 'active' : ''
              }`}
              onClick={() => {
                this.toggle('1');
              }}
            >
              <div className="icon">
                <i className="fas fa-search" aria-hidden="true" />
              </div>

              <div className="title">قدرة بحث متقدّمة</div>
            </div>
          </Col>

          <Col xs={4} md={3}>
            <div
              className={`nav-container ${
                this.state.activeTab === '2' ? 'active' : ''
              }`}
              onClick={() => {
                this.toggle('2');
              }}
            >
              <div className="icon">
                <i className="fas fa-check-double" aria-hidden="true" />
              </div>

              <div className="title">موسوعات متنوّعة</div>
            </div>
          </Col>
        </Row>

        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1" className="activites-pane">
            <Row>
              <Col sm={12} md={6} className="paragraph">
                <div className="title">قدرة بحث متقدّمة</div>
                <div className="text">
                  إمكانيّة البحث المتقدّم في كافّة الموسوعات باستخدام كلمات
                  البحث والموضوعات والفئات العمريّة المحدّدة، مع إمكانيّة تصفّح
                  المحتوى وفقاً للموسوعة المحدّدة والتّنقّل بين موضوعاتها بطريقة
                  سلسة وفقاً لفهرس الموسوعة.
                </div>
              </Col>

              <Col sm={12} md={6}>
                <div
                  className="image"
                  style={{
                    backgroundImage: 'url("/resources/portal/research.jpg")',
                  }}
                />
              </Col>
            </Row>
          </TabPane>

          <TabPane tabId="2" className="activites-pane">
            <Row>
              <Col sm={12} md={6} className="paragraph">
                <div className="title">موسوعات متنوّعة</div>
                <div className="text">
                  تنوّع في مواضيع الموسوعات لتشمل في ذلك كافّة العلوم على اختلاف
                  مستوياتها العمريّة وخصائصها المعرفيّة. وتنوّع في أنواع
                  الموسوعات وكيفيّة عرضها للمعلومات.
                </div>
              </Col>
              <Col sm={12} md={6}>
                <div
                  className="image"
                  style={{
                    backgroundImage:
                      'url("/resources/portal/accurateinfo.jpg")',
                  }}
                />
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

export default ActivitesTabs;
