import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';

import AudioPlaylist from './audio-player/AudioPlaylist';

import './Audio.scss';

const tracks = [
  {
    title: 'الجينات',
    duration: '11:42',
    src: '/resources/audio/a1.mp3',
  },
  {
    title: 'تجارب مندل في الوراثة',
    duration: '16:18',
    src: '/resources/audio/a2.mp3',
  },
  {
    title: 'مادة الوراثة DNA',
    duration: '12:13',
    src: '/resources/audio/a3.mp3',
  },
  {
    title: 'المعادن جواهر الأرض',
    duration: '11:31',
    src: '/resources/audio/a4.mp3',
  },
  {
    title: 'وظائف الخلايا',
    duration: '08:57',
    src: '/resources/audio/a5.mp3',
  },
  {
    title: 'درجة الحرارة',
    duration: '08:34',
    src: '/resources/audio/a6.mp3',
  },
  {
    title: 'ترتيب العمليّات',
    duration: '09:16',
    src: '/resources/audio/a7.mp3',
  },
  {
    title: 'قسمة الأعداد الصّحيحة',
    duration: '09:50',
    src: '/resources/audio/a8.mp3',
  },
  {
    title: 'معادلات الضّرب',
    duration: '07:09',
    src: '/resources/audio/a9.mp3',
  },
  {
    title: 'التّمثيل البيانيّ للدوالّ',
    duration: '10:08',
    src: '/resources/audio/a10.mp3',
  },
  {
    title: 'جمع الأعداد النّسبيّة ذات المقامات المتشابهة وطرحها',
    duration: '06:53',
    src: '/resources/audio/a11.mp3',
  },
  {
    title: 'إيجاد النّسب المئويّة ذهنيّاً',
    duration: '10:46',
    src: '/resources/audio/a12.mp3',
  },
  {
    title: 'التّماثل',
    duration: '06:48',
    src: '/resources/audio/a13.mp3',
  },
];

class Audio extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="Audio">
        <section className="title-section">
          <Container>
            <div className="page-title">تذكير</div>
            <div className="page-subtitle">
              تسجيلات صوتية محترفة، تلخص الدرس للمعلّم وتذكّره بأهم النقاط قبل
              دخوله إلى الصف
            </div>
          </Container>
        </section>
        <section className="demo pb-5 bgc-dark">
          <Container>
            <AudioPlaylist tracksList={tracks} />
          </Container>
        </section>

        <section className="description bgc-secondary">
          <Container>
            <Row className="align-items-center">
              <Col xs={{ size: 12, order: 1 }} md={{ size: 6, order: 0 }}>
                <div
                  style={{
                    backgroundImage: 'url("/resources/audio/earphones.png")',
                  }}
                  className="image"
                />
              </Col>
              <Col xs={12} md={6}>
                <div className="paragraph">
                  <div className="title">
                    تحضير الدروس بالاستماع إلى التسجيل
                  </div>
                  <div className="text">
                    تسجيلات صوتيّة تقارب مدّة الواحد منها العشر دقائق، هدفها
                    تلخيص الدرس للمعلّم بشكل بسيط وعمليّ وتذكيره بأهدافه
                    ومعلوماته، مع اقتراح تمهيد جاذب وأنشطة تفاعليّة وأسئلة يمكن
                    له طرحها على الطلاب. يستطيع المعلّم سماع هذه التسجيلات
                    الصوتية قبل ذهابه لإعطاء الدرس، فيسترجع بذلك جميع نقاط الدرس
                    الأساسية ويدخل إلى صفّه متمكّناً من تقديم درسٍ مميّز.
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="description bgc-white">
          <Container>
            <Row className="align-items-center">
              <Col xs={12} md={6}>
                <div className="paragraph">
                  <div className="title">منتج فريد حول العالم</div>
                  <div className="text">
                    بالإضافة إلى أن التسجيلات الصوتية ذات جودة عالية ومسجّلة من
                    قبل أصوات محترفة ومتميزةً، فإنها الأولى من نوعها حول العالم.
                    تتميّز التسجيلات بأسلوبها الجاذب الملفت الذي يخاطب المدرّس
                    دون ملل، وتقدم له تجربةً فريدة من نوعها.
                  </div>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div
                  style={{
                    backgroundImage: 'url("/resources/audio/studio.jpg")',
                  }}
                  className="image"
                />
              </Col>
            </Row>
          </Container>
        </section>

        <section className="features bgc-dark">
          <Container>
            <Row>
              <Col md="4" lg="3" className="rounded-column">
                <div className="icon">
                  <i className="fab fa-affiliatetheme" />
                </div>
                <div className="title">جودة مرتفعة</div>
                <div className="text">
                  تُسجّل هذه المادّة في أهمّ وأكبر استوديو تسجيل في الشرق الأوسط
                  وبجودة فائقة، وبالإستعانة بأصوات محترفة ومميزة للتأكّد من
                  تقديم مقاطع نقيّة وجذابة للمدرّسين.
                </div>
              </Col>
              <Col md="4" lg="3" className="rounded-column">
                <div className="icon">
                  <i className="fas fa-download" />
                </div>
                <div className="title">إمكانيّة التحميل</div>
                <div className="text">
                  يمكن لمستخدمي تذكير تحميل التسجيلات الصوتية على هواتفهم
                  الخاصّة ليتمكّنوا من سماعها في حال كانوا غير متصلين بالانترنت.
                  يمكنّهم ذلك من الاستمتاع بالتسجيلات أثناء قيادة السيّارة أو
                  أثناء ممارسة الرياضة مثلاً.
                </div>
              </Col>
              <Col md="4" lg="3" className="rounded-column">
                <div className="icon">
                  <i className="fas fa-microphone" />
                </div>
                <div className="title">أصواتٌ متنوّعة</div>
                <div className="text">
                  يشارك في هذه التسجيلات الصوتية نخبةٌ من الأصوات المحترفة
                  والمتميزة، وذلك تفادياً لرتابة الصوت ومنعاً لملل المدرّس. نحرص
                  على تسجيل العبارات المهمّة بأصواتٍ مختلفة من أجل لفت الانتباه
                  لها.
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    );
  }
}

export default Audio;
