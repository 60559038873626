Object.defineProperty(exports, '__esModule', {
  value: true,
});

const _createClass = (function () {
  function defineProperties(target, props) {
    for (let i = 0; i < props.length; i++) {
      const descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ('value' in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }
  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
})();

const _react = require('react');

const _react2 = _interopRequireDefault(_react);

const _propTypes = require('prop-types');

const _propTypes2 = _interopRequireDefault(_propTypes);

const _Panel = require('./Panel');

const _Panel2 = _interopRequireDefault(_Panel);

const _openAnimationFactory = require('./openAnimationFactory');

const _openAnimationFactory2 = _interopRequireDefault(_openAnimationFactory);

const _classnames = require('classnames');

const _classnames2 = _interopRequireDefault(_classnames);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value,
      enumerable: true,
      configurable: true,
      writable: true,
    });
  } else {
    obj[key] = value;
  }
  return obj;
}

function _toConsumableArray(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }
    return arr2;
  }
  return Array.from(arr);
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError('Cannot call a class as a function');
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError(
      "this hasn't been initialised - super() hasn't been called"
    );
  }
  return call && (typeof call === 'object' || typeof call === 'function')
    ? call
    : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== 'function' && superClass !== null) {
    throw new TypeError(
      `Super expression must either be null or a function, not ${typeof superClass}`
    );
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true,
    },
  });
  if (superClass) {
    Object.setPrototypeOf
      ? Object.setPrototypeOf(subClass, superClass)
      : (subClass.__proto__ = superClass);
  }
}

function toArray(activeKey) {
  let currentActiveKey = activeKey;
  if (!Array.isArray(currentActiveKey)) {
    currentActiveKey = currentActiveKey ? [currentActiveKey] : [];
  }
  return currentActiveKey;
}

const Collapse = (function (_Component) {
  _inherits(Collapse, _Component);

  function Collapse(props) {
    _classCallCheck(this, Collapse);

    const _this = _possibleConstructorReturn(
      this,
      (Collapse.__proto__ || Object.getPrototypeOf(Collapse)).call(this, props)
    );

    const _this$props = _this.props;

    const activeKey = _this$props.activeKey;

    const defaultActiveKey = _this$props.defaultActiveKey;

    let currentActiveKey = defaultActiveKey;
    if ('activeKey' in _this.props) {
      currentActiveKey = activeKey;
    }

    _this.state = {
      openAnimation:
        _this.props.openAnimation ||
        (0, _openAnimationFactory2.default)(_this.props.prefixCls),
      activeKey: toArray(currentActiveKey),
    };
    return _this;
  }

  _createClass(Collapse, [
    {
      key: 'componentWillReceiveProps',
      value: function componentWillReceiveProps(nextProps) {
        if ('activeKey' in nextProps) {
          this.setState({
            activeKey: toArray(nextProps.activeKey),
          });
        }
        if ('openAnimation' in nextProps) {
          this.setState({
            openAnimation: nextProps.openAnimation,
          });
        }
      },
    },
    {
      key: 'onClickItem',
      value: function onClickItem(key) {
        let activeKey = this.state.activeKey;
        if (this.props.accordion) {
          activeKey = activeKey[0] === key ? [] : [key];
        } else {
          activeKey = [].concat(_toConsumableArray(activeKey));
          const index = activeKey.indexOf(key);
          const isActive = index > -1;
          if (isActive) {
            // remove active state
            activeKey.splice(index, 1);
          } else {
            activeKey.push(key);
          }
        }
        this.setActiveKey(activeKey);
      },
    },
    {
      key: 'getItems',
      value: function getItems() {
        const _this2 = this;

        const activeKey = this.state.activeKey;
        const _props = this.props;

        const prefixCls = _props.prefixCls;

        const accordion = _props.accordion;

        const destroyInactivePanel = _props.destroyInactivePanel;

        const expandIcon = _props.expandIcon;

        const newChildren = [];

        _react.Children.forEach(this.props.children, (child, index) => {
          if (!child) return;
          // If there is no key provide, use the panel order as default key
          const key = child.key || String(index);
          const _child$props = child.props;

          const header = _child$props.header;

          const headerClass = _child$props.headerClass;

          const disabled = _child$props.disabled;

          let isActive = false;
          if (accordion) {
            isActive = activeKey[0] === key;
          } else {
            isActive = activeKey.indexOf(key) > -1;
          }

          const props = {
            key,
            header,
            headerClass,
            isActive,
            prefixCls,
            destroyInactivePanel,
            openAnimation: _this2.state.openAnimation,
            accordion,
            children: child.props.children,
            onItemClick: disabled
              ? null
              : function () {
                  return _this2.onClickItem(key);
                },
            expandIcon,
          };

          newChildren.push(_react2.default.cloneElement(child, props));
        });

        return newChildren;
      },
    },
    {
      key: 'setActiveKey',
      value: function setActiveKey(activeKey) {
        if (!('activeKey' in this.props)) {
          this.setState({ activeKey });
        }
        this.props.onChange(this.props.accordion ? activeKey[0] : activeKey);
      },
    },
    {
      key: 'render',
      value: function render() {
        let _classNames;

        const _props2 = this.props;

        const prefixCls = _props2.prefixCls;

        const className = _props2.className;

        const style = _props2.style;

        const accordion = _props2.accordion;

        const collapseClassName = (0, _classnames2.default)(
          ((_classNames = {}),
          _defineProperty(_classNames, prefixCls, true),
          _defineProperty(_classNames, className, !!className),
          _classNames)
        );
        return _react2.default.createElement(
          'div',
          {
            className: collapseClassName,
            style,
            role: accordion ? 'tablist' : null,
          },
          this.getItems()
        );
      },
    },
  ]);

  return Collapse;
})(_react.Component);

Collapse.propTypes = {
  children: _propTypes2.default.any,
  prefixCls: _propTypes2.default.string,
  activeKey: _propTypes2.default.oneOfType([
    _propTypes2.default.string,
    _propTypes2.default.arrayOf(_propTypes2.default.string),
  ]),
  defaultActiveKey: _propTypes2.default.oneOfType([
    _propTypes2.default.string,
    _propTypes2.default.arrayOf(_propTypes2.default.string),
  ]),
  openAnimation: _propTypes2.default.object,
  onChange: _propTypes2.default.func,
  accordion: _propTypes2.default.bool,
  className: _propTypes2.default.string,
  style: _propTypes2.default.object,
  destroyInactivePanel: _propTypes2.default.bool,
  expandIcon: _propTypes2.default.func,
};

Collapse.defaultProps = {
  prefixCls: 'rc-collapse',
  onChange: function onChange() {},

  accordion: false,
  destroyInactivePanel: false,
};

Collapse.Panel = _Panel2.default;

exports.default = Collapse;
module.exports = exports.default;
