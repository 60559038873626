import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
//import CardActions from "@material-ui/core/CardActions";
import CardContent from '@material-ui/core/CardContent';
//import CardMedia from "@material-ui/core/CardMedia";
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import SkipNextIcon from '@material-ui/icons/SkipNext';
//import Dialog from "@material-ui/core/Dialog";
//import CancelIcon from "@material-ui/icons/Cancel";
//import DialogActions from "@material-ui/core/DialogActions";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ThemeProvider } from '@material-ui/styles';
//import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";
import ImgList from './imglist';
import TableBlock from './table';

//const actions2 = [<Button label="Close" primary={true} />];
//let theme = createMuiTheme();
//theme = responsiveFontSizes(theme);
const CardBlock = (props) => {
  const getTextSize = () => {
    let addTitleHeight = 0;
    if (props.title.trim() === '') {
      addTitleHeight = 65;
    }

    if (props.imgPos === 'none') {
      return 400 + addTitleHeight;
    } else {
      return 150 + addTitleHeight;
    }
  };

  const useStyles = makeStyles((theme) => ({
    /*general classes */
    actionarea: {
      display: 'flex',
      //flexDirection: props.direction
      //alignItems: "center",
      justifyContent: 'center',
    },

    hideArea: {
      display: 'none',
    },

    showArea: {},

    order1: {
      order: 1,
    },

    order2: {
      order: 2,
    },

    flexrow: {
      flexDirection: 'row',
    },

    flexcol: {
      flexDirection: 'column',
    },

    /*  big size default */
    card: {
      maxWidth: 365,
      textAlign: 'center',
    },
    media: {
      height: 250,
      objectFit: 'contain',
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    playIcon: {
      height: 38,
      width: 38,
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    typography: {
      // In Chinese and Japanese the characters are usually larger,
      // so a smaller fontsize may be appropriate.
      height: 60,
      fontSize: '1.3rem',
      fontWeight: 'bold',
      direction: 'rtl',
    },

    typography2: {
      fontSize: '1.1rem',
      direction: 'rtl',
      textAlign: 'justify',
      height: getTextSize(),
    },

    /*  Full size to fill container */
    cardFull: {
      maxWidth: '100%',
      textAlign: 'center',
    },

    mediaFull: {
      minWidth: 'calc(300px + 5%)',
      height: 'auto',
      objectFit: 'contain',
      padding: 15,
    },
    controlsFull: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    playIconFull: {
      height: 38,
      width: 38,
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    typographyFull: {
      //height: 30,
      //fontSize: "calc(1.3em + 1vw)",
      fontSize: '1.8rem',
      fontWeight: 'bold',
      direction: 'rtl',
    },

    typography2Full: {
      //fontSize: "calc(1.2em + 1vw) ",
      fontSize: '1.6rem',
      textAlign: 'justify',
      direction: 'rtl',
    },

    /*  Xlarge size to fill container */
    cardXlarge: {
      textAlign: 'center',
    },

    mediaXlarge: {
      //width: "60%",
      minWidth: 'calc(250px + 5%)',
      objectFit: 'contain',
      padding: 15,
    },
    controlsXlarge: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    playIconXlarge: {
      height: 38,
      width: 38,
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    typographyXlarge: {
      height: 40,
      fontSize: '2.0em',
      fontWeight: 'bold',

      direction: 'rtl',
    },

    typography2Xlarge: {
      fontSize: '1.3rem',
      direction: 'rtl',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      textAlign: 'justify',
      height: 400,
    },

    /*  medium size default */
    cardMedium: {
      width: 250,
      textAlign: 'center',
    },
    mediaMedium: {
      height: 175,
      objectFit: 'contain',
    },
    controlsMedium: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    playIconMedium: {
      height: 38,
      width: 38,
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    typographyMedium: {
      height: 40,
      fontSize: '1.1rem',
      fontWeight: 'bolder',
      direction: 'rtl',
    },

    typography2Medium: {
      fontSize: '1.1rem',
      direction: 'rtl',
      textAlign: 'justify',
      fontWeight: 'bold',
      height: 110,
    },

    /*  small size */
    cardSmall: {
      maxWidth: 125,
      textAlign: 'center',
    },
    mediaSmall: {
      height: 125,
      objectFit: 'contain',
    },
    controlsSmall: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    playIconSmall: {
      height: 38,
      width: 38,
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    typographySmall: {
      height: 30,
      fontSize: '1.0em',
      fontWeight: 'bold',
      direction: 'rtl',
    },

    typography2Small: {
      fontSize: '0.5em',
      direction: 'rtl',
      height: 25,
    },
  }));

  const classes = useStyles();
  const theme = useTheme();
  const bigScreen = useMediaQuery('(min-width:600px)');
  //const mediumScreen = useMediaQuery("(min-width:1200px)");

  //const [open, setOpen] = useState(false);
  //const [currentImg, setcurrentImg] = useState("");

  /*
  const handleClick = img => {
    if (img != null) {
      setOpen(true);
      setcurrentImg("/images/" + props.imgFolder + "/" + img[0]);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  */

  /*
  const getImgSrc = () => {
    let imgSrc = "";
    if (props.img == null) {
      imgSrc = "/images/" + props.imgFolder + "/00.jpg";
    } else {
      imgSrc = "/images/" + props.imgFolder + "/" + props.img[0];
    }
    return imgSrc;
  };
  */

  const getMediaSrc = () => {
    if (props.media) {
      return (
        <div className={classes.controls}>
          <IconButton aria-label="previous">
            {theme.direction === 'rtl' ? (
              <SkipNextIcon />
            ) : (
              <SkipPreviousIcon />
            )}
          </IconButton>
          <IconButton aria-label="play/pause">
            <PlayArrowIcon className={classes.playIcon} />
          </IconButton>
          <IconButton aria-label="next">
            {theme.direction === 'rtl' ? (
              <SkipPreviousIcon />
            ) : (
              <SkipNextIcon />
            )}
          </IconButton>
        </div>
      );
    } else {
      return '';
    }
  };

  const getFlexDir = () => {
    if (
      (props.cardSize === 'Full' || props.cardSize === 'Xlarge') &&
      (props.imgPos === 'Left' || props.imgPos === 'Right') &&
      bigScreen
    ) {
      return classes.flexrow;
    } else {
      return classes.flexcol;
    }
  };

  const getMediaOrder = () => {
    if (props.imgPos === 'none') {
      return classes.hideArea;
    } else {
      if (props.imgPos === 'Left' || props.imgPos === 'Top') {
        return classes.order1;
      } else {
        return classes.order2;
      }
    }
  };

  const getTitleVisiblity = () => {
    //console.log("props" + props.title);
    if (typeof props.title === 'string') {
      if (props.title.trim() === '') {
        return classes.hideArea;
      } else {
        return classes.showArea;
      }
    } else {
      return classes.hideArea;
    }
  };

  const getSubTitleVisiblity = () => {
    if (typeof props.text === 'string') {
      if (props.text.trim() === '') {
        return classes.hideArea;
      } else {
        return classes.showArea;
      }
    } else {
      return classes.hideArea;
    }
  };

  const getContentOrder = () => {
    if (props.imgPos === 'Left' || props.imgPos === 'Top') {
      return classes.order2;
    } else {
      return classes.order1;
    }
  };

  const renderTable = () => {
    if (typeof props.table === 'undefined') {
      return '';
    } else {
      return (
        <div style={{ width: '100%', textAlign: 'center' }}>
          <TableBlock table={props.table} />
        </div>
      );
    }
  };

  const getImgList = () => {
    if (typeof props.img === 'undefined') {
      return (
        <ImgList
          vertical={!bigScreen}
          img={['00.jpg']}
          imgFolder={props.imgFolder}
        />
      );
    } else {
      if (props.imgPos === 'none') {
        return '';
      } else {
        //console.log("getting image list " + props.img);
        return (
          <ImgList
            vertical={!bigScreen}
            img={props.img}
            imgSize={props.imgSize}
            imgFolder={props.imgFolder}
          />
        );
      }
    }
  };

  return (
    <Card
      className={classes['card' + props.cardSize]}
      style={{ backgroundColor: props.bgColor }}
    >
      <CardActionArea className={`${classes.actionarea} ${getFlexDir()}`}>
        {/* 
        <CardMedia
          onClick={() => handleClick(props.img)}
          className={`${classes["media" + props.cardSize]} ${getMediaOrder()}`}
          component="img" 
          src={getImgSrc()}
        />
        */}
        <div
          className={`${classes['media' + props.cardSize]} ${getMediaOrder()}`}
        >
          {getImgList()}
        </div>

        <CardContent className={getContentOrder()}>
          <ThemeProvider theme={theme}>
            <Typography
              gutterBottom
              variant="h4"
              component="h3"
              className={`${
                classes['typography' + props.cardSize]
              } ${getTitleVisiblity()}`}
              style={{ color: props.titleColor }}
              dangerouslySetInnerHTML={{ __html: props.title }}
            ></Typography>
            <Typography
              variant="h5"
              color="textSecondary"
              component="p"
              style={{ color: props.subTitleColor }}
              className={`${
                classes['typography2' + props.cardSize]
              } ${getSubTitleVisiblity()}`}
              dangerouslySetInnerHTML={{ __html: props.text }}
            ></Typography>
          </ThemeProvider>
        </CardContent>
      </CardActionArea>
      {getMediaSrc()}
      {/* 

      <Dialog open={open} onClose={handleClose}>
        <img
          src={currentImg}
          alt=""
          style={{ height: "100%", width: "100%" }}
        />
        <DialogActions>
          <IconButton onClick={handleClose}>
            <CancelIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
        */}

      {renderTable()}
    </Card>
  );
};
CardBlock.defaultProps = { cardSize: '', imgPos: 'Top', title: '', text: '' };
export default CardBlock;
