import React from 'react';
import { Container } from 'reactstrap';
import './Video.scss';
import SampleVideos from './SampleVideos';
// import VideoSlider from './VideoSlider';
import SellingPoints from './SellingPoints';
import QuestionVideo from './QuestionVideo';
import Partners from './Partners';
import List from './List';

function Video() {
  return (
    <div className="video">
      <section className="title-section">
        <Container>
          <div className="page-title">إثراء</div>
          <div className="page-subtitle">
            مكتبة ضخمة من الفيديوهات عالية الجودة، يمكن للمدرّس عرضها على طلابه
            لإيصال أفكار الدروس بأسلوب جذاب
          </div>
        </Container>
      </section>

      <section className="sample-videos">
        <SampleVideos />
      </section>

      {/* <section className="multi-video-navigator">
        <VideoSlider videos={[]} />
      </section> */}

      <section className="selling-points">
        <SellingPoints />
      </section>

      <section className="question-video">
        <QuestionVideo />
      </section>

      <hr />

      <section className="partners">
        <Partners />
      </section>

      <section className="video-list">
        <List />
      </section>
    </div>
  );
}

export default Video;
