import React, { Component } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Dropdown,
  ListGroup,
  ListGroupItem,
  DropdownToggle,
  DropdownMenu,
  Container,
} from 'reactstrap';
import { NavLink, Link } from 'react-router-dom';
import './Header.scss';

const logo = '/resources/logos/logo.svg';

class Header extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.toggleProducts = this.toggleProducts.bind(this);
    this.collapseExited = this.collapseExited.bind(this);
    this.state = {
      isOpen: false,
      isProductsOpen: false,
      isProductsCollapseOpen: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  }

  toggleProducts() {
    this.setState((prevState) => {
      if (prevState.isProductsOpen) {
        return { isProductsCollapseOpen: false };
      }
      return { isProductsOpen: true, isProductsCollapseOpen: true };
    });
  }

  collapseExited() {
    this.setState({ isProductsOpen: false });
  }

  render() {
    return (
      <div className="header">
        <Container>
          <Navbar expand="md">
            <NavbarBrand href="/">
              <Link to="/">
                <img src={logo} alt="حقائب المستقبل" />
              </Link>
            </NavbarBrand>
            <NavbarToggler
              onClick={this.toggle}
              type="button"
              tag={() => (
                <i
                  className="fa fa-bars navbar-toggler"
                  onClick={this.toggle}
                />
              )}
            />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="ml-auto" navbar>
                <NavItem>
                  <NavLink
                    exact
                    to="/"
                    activeClassName="active"
                    className="nav-link"
                  >
                    الرئيسية
                  </NavLink>
                </NavItem>
                <Dropdown
                  nav
                  inNavbar
                  isOpen={this.state.isProductsOpen}
                  toggle={this.toggleProducts}
                >
                  <DropdownToggle
                    nav
                    className={
                      this.props.location.pathname.includes('/products')
                        ? 'active'
                        : ''
                    }
                  >
                    منتجاتنا
                  </DropdownToggle>
                  <DropdownMenu>
                    <Collapse
                      isOpen={this.state.isProductsCollapseOpen}
                      onExited={this.collapseExited}
                      nav="true"
                    >
                      <ListGroup>
                        <a href="https://damma.app/" target="_blank">
                          <ListGroupItem>ضمّة</ListGroupItem>
                        </a>
                        <NavLink
                          to="/products/video"
                          activeClassName="active2"
                          onClick={this.toggleProducts}
                        >
                          <ListGroupItem>إثراء</ListGroupItem>
                        </NavLink>
                        <NavLink
                          activeClassName="active2"
                          to="/products/3d"
                          onClick={this.toggleProducts}
                        >
                          <ListGroupItem>إسهاب</ListGroupItem>
                        </NavLink>
                        <NavLink
                          to="/products/exercises"
                          activeClassName="active2"
                          onClick={this.toggleProducts}
                        >
                          <ListGroupItem>تمكين</ListGroupItem>
                        </NavLink>
                        <NavLink
                          to="/products/teachers"
                          activeClassName="active2"
                          onClick={this.toggleProducts}
                        >
                          <ListGroupItem>إتقان</ListGroupItem>
                        </NavLink>
                        <NavLink
                          to="/products/audio"
                          activeClassName="active2"
                          onClick={this.toggleProducts}
                        >
                          <ListGroupItem>تذكير</ListGroupItem>
                        </NavLink>
                        <NavLink
                          to="/products/encyclopedias"
                          activeClassName="active2"
                          onClick={this.toggleProducts}
                        >
                          <ListGroupItem>معرفة</ListGroupItem>
                        </NavLink>
                      </ListGroup>
                    </Collapse>
                  </DropdownMenu>
                </Dropdown>

                <NavItem>
                  <NavLink
                    exact
                    activeClassName="active"
                    className="nav-link"
                    to="/services"
                  >
                    خدماتنا
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    exact
                    activeClassName="active"
                    className="nav-link"
                    to="/about/us"
                  >
                    من نحن
                  </NavLink>
                </NavItem>
              </Nav>
              <a href="https://manahej.net:4001/" id="mcview">
                بوابة المحتوى
              </a>
            </Collapse>
          </Navbar>
        </Container>
      </div>
    );
  }
}

export default Header;
