import React, { Component } from 'react';
import './ExerciseWrapper.scss';

import ExerciseStatuses from '../../utils/exercise-statuses';

export default function wrapWithExerciseBase(ExerciseComponent) {
  return class extends Component {
    constructor(props) {
      super(props);
      this.myRef = React.createRef();

      // this.zoomImg = this.zoomImg.bind(this);
    }

    componentDidMount() {
      this.restorePrevStateAndDoActionIfExists();
      const imgList = this.myRef.current.querySelectorAll('img');
      for (let index = 0; index < imgList.length; index++) {
        imgList[index].addEventListener('click', this.zoomImg);
      }
    }

    componentDidUpdate() {
      this.reset();

      this.restorePrevStateAndDoActionIfExists();

      const imgList = this.myRef.current.querySelectorAll('img');
      for (let index = 0; index < imgList.length; index++) {
        imgList[index].addEventListener('click', this.zoomImg);
      }
    }

    componentWillUnmount() {
      const imgList = this.myRef.current.querySelectorAll('img');
      for (let index = 0; index < imgList.length; index++) {
        imgList[index].removeEventListener('click', this.zoomImg);
      }
    }

    get exStatus() {
      return this.ref.state.exStatus;
    }

    get checkAnswer() {
      return this.ref.checkAnswer();
    }

    /**
     * TODO: store user progress in Storage
     *
     */
    submit = () => {
      if (this.ref.state.exStatus === ExerciseStatuses.UNTOUCHED) return;

      const isCorrect = this.ref.checkAnswer();

      this.ref.setState({
        exStatus: isCorrect ? ExerciseStatuses.CORRECT : ExerciseStatuses.WRONG,
      });

      return isCorrect;
    };

    reset = () => {
      this.ref.reset();
    };

    showAnswer = () => {
      this.ref.showAnswer();
    };

    // FIXME: wrap this comp with div and scroll to it
    scrollToMyRef = () => {
      document.querySelector('#exercisesContent').scroll({
        top: this.myRef.current.offsetTop,
        behavior: 'smooth',
      });
    };

    zoomImg = (e) => {
      const imgList = document.querySelectorAll('img.zoom');
      e.target.classList.toggle('zoom');

      for (let index = 0; index < imgList.length; index++) {
        if (imgList[index] !== e.target) {
          imgList[index].classList.remove('zoom');
        }
      }
    };

    restorePrevStateAndDoActionIfExists() {
      if (this.props.action === 'reset') {
        this.ref.reset();
        return;
      }

      if (this.props.action === 'showAnswer') {
        this.ref.showAnswer();
        return;
      }

      if (this.props.state && this.props.action === 'submit') {
        this.ref.setState(
          {
            ...this.props.state,
            canAnswer: false,
          },
          () => {
            this.submit();
          }
        );
      } else if (this.props.state) {
        this.ref.setState({
          answer: this.props.state.answer,
          exStatus: this.props.state.exStatus,
          canAnswer: this.props.state.canAnswer,
        });
      }
    }

    render() {
      return (
        <div className="question-wrapper" ref={this.myRef}>
          <ExerciseComponent
            {...this.props}
            // FIXME: don't use arrow function here.
            // ref={this.ref}
            ref={(ref) => {
              this.ref = ref;
            }}
          />
        </div>
      );
    }
  };
}
