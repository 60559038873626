import React, { Component } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  Row,
  Col,
} from 'reactstrap';

import './Slider.scss';

// TODO: get better items for slider (include vids and 3d)
// const items = [
//   {
//     src: '/resources/home/kid.jpg',
//     caption: 'آلاف المواد التعليمية بحوزتك',
//     text: 'محتوى شامل للمرحلة المتوسّطة، يغطّي كافّة دروس العلوم والرياضيات بمئات النماذج ثلاثيّة الأبعاد، آلاف الفيديوهات التعليمية، وعشرات آلاف الأسئلة',
//   },
//   {
//     src: '/resources/home/puzzle.jpg',
//     caption: 'محتوى متناسق حول احتياجاتك',
//     text: 'مرونة تامّة في إعداد المحتويات وفقاً لمناهجك واحتياجاتك، مع إمكانيّة إعداد منصّتك الخاصّة لعرض كافّة المحتويات',
//   },
//   {
//     src: '/resources/home/hands.jpg',
//     caption: 'تعليمك في أيدٍ أمينة',
//     text: 'خبرة عريقة في مجال نشر الثقافة والتعليم يعزّز ثقة عملائنا بجودة منتجاتنا وفعاليّة أدائنا',
//   },
// ];
const items = [
  {
    src: '/resources/home/kid.jpg',
    caption: 'تطوير المحتوى التعليمي',
    text: 'أعدّت شركة المستقبل الرقمي إحدى أكبر مكتبات المحتوى التعليميّ باللغة العربي. يتكوّن المحتوى من الفيديوهات التعليمية، النماذج ثلاثيّة الأبعاد، المحتويات التّفاعليّة، بنوك الأسئلة، والمواسوعات التعليميّة. تتوفّر هذه المادّة باللغة العربية، وهي قابلة للإدراج ضمن أيّ بوّابة تعليميّة.',
  },
  {
    src: '/resources/home/damma.png',
    caption: 'التعليم المبكّر: ضمّة',
    text: 'تطبيق تعليميّ تفاعليّ للأطفال، يعتني بمرحلة التعليم المبكر من سنّ 3 إلى 8 سنوات، يحتوي على أكثر من 250 درساً، تمّ تقسيمها إلى ثلاثة مستويات. يعالج ضمَّة أنماط التعلم المختلفة عبر دمج الصورة، والصوت، والألفاظ والأنشطة اليدوية، وهو تطبيق مثالي للأهل وأداة متكاملة للمعلّمين ووسيلة تعليميّة ممتعة للأطفال. يتضمّن المشروع حقيبة صفيّة وبوّابة للمدرّسين.',
    link: 'https://damma.app/',
  },
  {
    src: '/resources/home/qissasi.png',
    caption: 'مكتبة القراءة: قصصي',
    text: 'مكتبة رقميّة تتضمّن أكثر من 1000 كتاب من كتب المستقبل الرقميّ متوفّر للقراءة والاستماع ضمن التطبيق نصّاً وصوتاً. تقدّم المكتبة قصصاً متدرّجة المستوى، مقسّمة حسب المرحلة التعليميّة، وتتضمّن العديد من مخرجات القراءة والأسئلة حول النصّ المقروء ما يجعلها أداة مثاليّة للاستخدام المدرسيّ.',
  },
];

class Slider extends Component {
  constructor(props) {
    super(props);
    this.state = { activeIndex: 0 };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    this.setState((prevState) => {
      const nextIndex =
        prevState.activeIndex === items.length - 1
          ? 0
          : prevState.activeIndex + 1;
      return {
        activeIndex: nextIndex,
      };
    });
  }

  previous() {
    if (this.animating) return;
    this.setState((prevState) => {
      const nextIndex =
        prevState.activeIndex === 0
          ? items.length - 1
          : prevState.activeIndex - 1;
      return {
        activeIndex: nextIndex,
      };
    });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  render() {
    const { activeIndex } = this.state;

    return (
      <Carousel
        className="Slider"
        activeIndex={activeIndex}
        next={this.next}
        previous={this.previous}
      >
        <CarouselIndicators
          items={items}
          activeIndex={activeIndex}
          onClickHandler={this.goToIndex}
        />
        {items.map((item, key) => (
          <CarouselItem
            onExiting={this.onExiting}
            onExited={this.onExited}
            key={key}
          >
            <Row className="slider-container">
              <Col xs={12} lg={6} className="paragraph">
                <div className="title">{item.caption}</div>
                <div className="text">{item.text}</div>
                {item.link && (
                  <a href={item.link} target="_blank">
                    لمزيد من المعلومات
                  </a>
                )}
              </Col>

              <Col>
                <div
                  className="image"
                  style={{
                    backgroundImage: `url("${item.src}")`,
                    backgroundPosition: 'top',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                  }}
                />
              </Col>
            </Row>
          </CarouselItem>
        ))}
        <CarouselControl
          direction="prev"
          directionText="Previous"
          onClickHandler={this.previous}
        />
        <CarouselControl
          direction="next"
          directionText="Next"
          onClickHandler={this.next}
        />
      </Carousel>
    );
  }
}

export default Slider;
