import React, { Component } from 'react';
import CardBlock from './cardblock';
import Grid from '@material-ui/core/Grid';
import { TitleHeaderNav } from './headernav';
import Pagination from 'material-ui-flat-pagination';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextImgBlock from './textimgblock';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';

const theme = createMuiTheme();

const divStyle = {
  margin: '0 auto',
  //bottom: "1%",
  //width: "100%",
  textAlign: 'center',
  //position: "fixed"
  //backgroundColor: "grey"
};

//const rootDiv = () => {
//  const classes = useStyles();
//  return <div className={classes.root}></div>;
//};

class Topic extends Component {
  //const BlockTestGrid = () => {
  // state = {};
  //const classes = useStyles();
  //  <div className={classes.root}>
  state = {
    currentTopic: 1,
    offset: 0,
    dataLoaded: false,
    dataArray: null,
  };

  getTopicBlocks(topicNo) {
    //console.log(animals.topics[topicNo].topicBlocks);
    //console.log(topicNo);
    //console.log(this.state.dataArray.topics);
    return this.state.dataArray.topics[topicNo - 1].topicBlocks;
  }

  changeCurrentTopic = (numTopic) => {
    this.setState({ currentTopic: numTopic });
    //fetch a data
    //or update a query to get data
  };

  handleTopicClick = (offset, topic) => {
    this.setState({ offset: offset, currentTopic: topic });
  };

  handleTocItem = (topicNo) => {
    this.setState({ offset: (topicNo - 1) * 10, currentTopic: topicNo });
  };

  updateJsonData() {
    //console.log("mounted " + this.props.encyName);
    //console.log(
    //  "/data/" + this.props.catName + "/" + this.props.encyName + ".json"
    //);
    axios
      .get(
        '/resources/portal/data/' +
          this.props.booksType +
          '/' +
          this.props.catName +
          '/' +
          this.props.encyName +
          '.json'
      )
      .then((res) => {
        const dataArray = res.data.data;
        this.setState({ dataArray: dataArray, dataLoaded: true });
      });
  }

  componentDidMount() {
    this.updateJsonData();
  }
  componentDidUpdate(PrevProps, PrevState) {
    // window.scrollTo(0, 0);

    /*
    console.log(
      "prev props : " +
        PrevProps.encyName +
        "current props : " +
        this.props.encyName
    );
    */
    if (PrevProps.encyName !== this.props.encyName) {
      this.updateJsonData();
    }
  }

  getBlockCode(value) {
    /*
    const value2 = {
      blockNo: "3_1",
      type: 3,
      title: "العلم والقصص",
      text:
        "جمع بليني الأكبر (79-23م، الصورة أعلاه) الأفكار السائدة في زمنه عن العالم القديم في كتابه «التّاريخُ الطبيعيُّ» الذي كان منْ أَوَائِلِ الموسوعات العلميَّة. لم يقتصر كتابه على احتواء العلوم التي عرفها النّاس في حينه، بل حوى أيضاً قصصاً وخرافاتٍ وأفكارًا تتعلق بالسحر.",
      sound: "4_1.mp3",
      images: ["003.jpg"],
      bgColor: "#fcd59d", //default white
      cardSize: "Full", //Full,Small, Medium,Large(default)
      imgPos: "none" //Left,Right(only for full mode) none, Bottom, Top(Default) (for all cardsizes)
    };
    */

    let blockBgColor = value.bgColor;
    if (typeof blockBgColor !== 'string') blockBgColor = this.getblockBgColor();

    switch (value.type) {
      case 2:
        return (
          <TextImgBlock
            vertical={false}
            title={value.title}
            text={value.text}
            img={value.images}
            imgFolder={
              this.props.booksType +
              '/' +
              this.props.catName +
              '/' +
              this.props.encyName
            }
          />
        );

      case 3:
        return (
          <CardBlock
            title={value.title}
            text={value.text}
            img={value.images}
            table={value.table}
            media={false}
            cardSize={value.cardSize}
            imgSize={value.imgSize}
            imgPos={value.imgPos}
            bgColor={blockBgColor}
            titleColor={value.titleColor}
            subTitleColor={value.subTitleColor}
            imgFolder={
              this.props.booksType +
              '/' +
              this.props.catName +
              '/' +
              this.props.encyName
            }
          />
        );

      default:
        return <div>This type not functional yet</div>;
    }
  }
  getBlockGrid(value) {
    if (value.cardSize === 'Full') {
      return (
        <Grid key={value.blockNo} item xs={12}>
          {this.getBlockCode(value)}
        </Grid>
      );
    } else if (value.cardSize === 'Xlarge') {
      return (
        <Grid key={value.blockNo} item xs={12} md={6}>
          {this.getBlockCode(value)}
        </Grid>
      );
    } else {
      return (
        <Grid key={value.blockNo} item>
          {this.getBlockCode(value)}
        </Grid>
      );
    }
  }

  getTopicTitle() {
    const topicTitle =
      this.state.dataArray.topics[this.state.currentTopic - 1].topicTitle;
    if (topicTitle) return ' - ' + topicTitle;
    else return '';
  }
  getTopicBgColor() {
    if (
      typeof this.state.dataArray.topics[this.state.currentTopic - 1]
        .topicBgColor == 'string'
    ) {
      return this.state.dataArray.topics[this.state.currentTopic - 1]
        .topicBgColor;
    } else {
      if (typeof this.state.dataArray.topicBgColor == 'string') {
        return this.state.dataArray.topicBgColor;
      } else {
        return 'white ';
      }
    }
  }

  getblockBgColor() {
    if (
      typeof this.state.dataArray.topics[this.state.currentTopic - 1]
        .blockBgColor == 'string'
    ) {
      return this.state.dataArray.topics[this.state.currentTopic - 1]
        .blockBgColor;
    } else {
      if (typeof this.state.dataArray.blockBgColor == 'string') {
        return this.state.dataArray.blockBgColor;
      } else {
        return 'white';
      }
    }
  }

  getTocBgColor() {
    if (typeof this.state.dataArray.tocBgColor == 'string') {
      return this.state.dataArray.tocBgColor;
    } else {
      return 'white';
    }
  }
  getRenderState() {
    if (this.state.dataLoaded) {
      return (
        <div style={{ backgroundColor: this.getTopicBgColor() }}>
          <TitleHeaderNav
            bgColor={this.getTocBgColor()}
            tocobj={this.state.dataArray.tableOfContents}
            onTocItem={this.handleTocItem}
            onBackClicked={this.props.onBackClicked}
            imgFolder={
              this.props.booksType +
              '/' +
              this.props.catName +
              '/' +
              this.props.encyName
            }
            title={
              this.state.dataArray.seriesTitle +
              ' - ' +
              this.state.dataArray.title +
              this.getTopicTitle()
            }
          />
          <Grid
            container
            spacing={2}
            style={{
              margin: 0,
              marginTop: 70,
              width: '100%',
              direction: 'rtl',
              textAlign: 'center',
            }}
          >
            <Grid
              container
              justify="center"
              spacing={2}
              style={{ margin: 0, width: '100%' }}
            >
              {this.getTopicBlocks(this.state.currentTopic).map((value) =>
                this.getBlockGrid(value)
              )}
            </Grid>
          </Grid>
          <br />

          {/* <div style={divStyle}>
            <MuiThemeProvider theme={theme}>
              <CssBaseline />
              <Pagination
                limit={10}
                offset={this.state.offset}
                total={this.state.dataArray.topicCount * 10}
                onClick={(e, offset, topic) =>
                  this.handleTopicClick(offset, topic)
                }
                size="large"
              />
            </MuiThemeProvider>
          </div> */}
        </div>
      );
    } else {
      return <CircularProgress />;
    }
  }
  render() {
    return this.getRenderState();
  }
}
Topic.defaultProps = { bgColor: 'white' };
export default Topic;
