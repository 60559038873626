import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';

import TeachersPlayer from '../../players/teachers/Teachers';

import ActivitesTabs from './ActivitesTabs';

import './Teachers.scss';
import SampleLessons from './SampleLessons';

class Teachers extends Component {
  chapter = {
    chapterID: '2',
    chapterTitle: 'الحركة والقوى والآلات البسيطة',
  };

  render() {
    return (
      <div className="prd-teachers">
        <section className="teachers-demo">
          <section className="title-section">
            <Container>
              <div className="page-title">إتقان</div>
              <div className="page-subtitle">
                مادّة مُعينة للمدرّسين لتحضير الدروس، مُرتّبة بأسلوب منهجيّ، مع
                شرحٍ وافٍ للأهداف والمفردات، وأنشطة تفاعليّة داعمة
              </div>
            </Container>
          </section>
          <Container>
            <Row>
              <Col>
                <TeachersPlayer {...this.chapter} />
              </Col>
            </Row>
          </Container>
        </section>
        <section className="description">
          <Container>
            <Row>
              <Col sm={12} md={6}>
                <img
                  src="/resources/teachers/أهداف.png"
                  alt="section descripotion"
                />
              </Col>

              <Col sm={12} md={6}>
                <div className="paragraph">
                  <div className="title">تفصيلٌ وافٍ وواضح لأهداف كلّ درس</div>
                  <div className="text">
                    نحرص على تقديم شرحٍ مفصّل لطريقة عرض كلّ هدف من أهداف دروس
                    المنهاج، وذلك من أجل تقديم تسلسل واضح يتّبعه المدرّس في
                    حصّته لضمان فهم طلّابه. كما ونقدّم فكرة نشاط يمكن للمدرّس
                    إعداده لتحقيق أهداف الدّرس، وبعض الأسئلة حول الكفايات
                    التعليميّة لتقييم تحقيق هذه الأهداف.
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <hr />

        <section className="tabs">
          <Container>
            <ActivitesTabs />
          </Container>
        </section>

        <section className="lessons">
          <section className="title-section">
            <div className="page-title">العلوم والرياضيات للمرحلة المتوسطة</div>
          </section>

          <SampleLessons />
        </section>
      </div>
    );
  }
}

export default Teachers;
