import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import {
  Player,
  BigPlayButton,
  ControlBar,
  VolumeMenuButton,
  CurrentTimeDisplay,
  TimeDivider,
  PlaybackRateMenuButton,
} from 'video-react';

import 'video-react/dist/video-react.css';

function SampleVideos() {
  return (
    <Container>
      <Row>
        <Col
          md={{ size: 6, order: 1 }}
          xs={{ size: 12, order: 1 }}
          className="paragraph"
        >
          <div className="title">مئات مقاطع الفيديو لتغطية شاملة وممتعة</div>
          <div className="text">
            شرح مرئي بسيط ومفصل لكافة الدروس العلمية الأساسية بأسلوب ثلاثي
            الأبعاد وبتحريك جذاب لشد انتباه الطلاب. تغطّي الفيديوهات كامل
            المنهاج وتسعى لإيصال أهداف الدرس بشكل دقيق وممتع.
          </div>
        </Col>
        <Col md={{ size: 6, order: 12 }} xs={{ size: 12, order: 12 }}>
          <Player
            playsInline
            poster="/resources/videos/حركة الأرض.JPG"
            src="/resources/videos/حركة الأرض.mp4"
          >
            <BigPlayButton position="center" />
            <ControlBar>
              {/* <ReplayControl seconds={10} order={1.1} />
            <ForwardControl seconds={30} order={1.2} /> */}
              <VolumeMenuButton order={8.1} />
              <CurrentTimeDisplay order={4.1} />
              <TimeDivider order={4.2} />
              <PlaybackRateMenuButton rates={[5, 2, 1, 0.75]} order={7.1} />
            </ControlBar>
          </Player>
        </Col>
      </Row>

      <Row>
        <Col
          md={{ size: 6, order: 12 }}
          xs={{ size: 12, order: 1 }}
          className="paragraph"
        >
          <div className="title">تنفيذ محترف وجذّاب </div>
          <div className="text">
            إنتاج محترف بجودة عالية HD مسجّل في أكبر استوديو في الشرق الأوسط،
            ومعلومات مدقّقة بمساعدة عشرات المدرّسين المحترفين في العالم العربي.
            تتضمّن الفيديوهات شرح أفكار الدروس المطلوبة، بالإضافة إلى حل أسئلة
            عمليّة تضمن فهم الطالب للمادّة.
          </div>
        </Col>
        <Col md={{ size: 6, order: 1 }} xs={{ size: 12, order: 12 }}>
          <Player
            playsInline
            poster="/resources/videos/الطفرات.JPG"
            src="/resources/videos/الطفرات.mp4"
          >
            <BigPlayButton position="center" />
            <ControlBar>
              {/* <ReplayControl seconds={10} order={1.1} />
            <ForwardControl seconds={30} order={1.2} /> */}
              <VolumeMenuButton order={8.1} />
              <CurrentTimeDisplay order={4.1} />
              <TimeDivider order={4.2} />
              <PlaybackRateMenuButton rates={[5, 2, 1, 0.75]} order={7.1} />
            </ControlBar>
          </Player>
        </Col>
      </Row>
    </Container>
  );
}

export default SampleVideos;
