class Pagination {
  constructor(showPerPage, totalElements, curPageIdx) {
    this.showPerPage = showPerPage;
    this.totalElements = totalElements;
    this.curPageIdx = curPageIdx;
  }

  get numOfPages() {
    return Math.ceil(this.totalElements / this.showPerPage);
  }

  get start() {
    return parseInt(this.curPageIdx * this.showPerPage, 10);
  }

  get end() {
    return parseInt((this.curPageIdx + 1) * this.showPerPage, 10);
  }

  get isLastPage() {
    return this.curPageIdx === this.numOfPages - 1;
  }

  get isFirstPage() {
    return this.curPageIdx === 0;
  }

  isVisible = (index) => {
    const v = index >= this.start && index < this.end;
    return v;
  };

  findPageIndex(index) {
    return Number.parseInt(index / this.showPerPage, 10);
  }
}

export default Pagination;
