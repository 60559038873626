import React, { Component } from 'react';
import Home from './Home';
import ContentViewer from './ContentViewer';
import './DemoNav.scss';

export default class DemoNav extends Component {
  constructor(props) {
    super(props);
    this.state = { level: 1, home: true };
  }

  selectHome = (level) => {
    this.setState({ level, home: false });
  };

  deselectHome = () => {
    this.setState({ home: true });
  };

  changeLevel = (level) => {
    this.setState({ level });
  };

  render() {
    return (
      <div className="early-demo-nav-container">
        {this.state.home ? (
          <Home select={this.selectHome} />
        ) : (
          <ContentViewer
            level={this.state.level}
            home={this.deselectHome}
            changeLevel={this.changeLevel}
          />
        )}
      </div>
    );
  }
}
