import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Nav, NavItem, NavLink } from 'reactstrap';

import { default as ExercisesPlayer } from '../../../players/exercises/Exercises';

import './ExercisesGen.scss';
import list from './exercises.json';
import FormOptions from '../FormOptions';

class ExercisesGen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeLesson: 0,
      data: null,
    };

    this.query = [];
    this.parseQuery(props.match.params.q);

    this.ExList = Object.values(list);

    this.LessonsList = this.getFlattenLessonsList();

    // console.log(this.LessonsList);
    // console.log(this.query);
  }

  componentDidMount() {
    let data;

    if (this.props.merge) {
      data = this.mergeData();
    } else {
      data = this.query.map((el) => this.generate(el));
    }

    this.setState({ data });
  }

  parseQuery = (q) => {
    const rows = q.split(',');
    rows.splice(rows.length - 1, 1);
    rows.forEach((v) => {
      const row = v.split('|');
      const rowObj = {
        chapter: Number.parseInt(row[0], 10),
        lesson: Number.parseInt(row[1], 10),
        types: row[2].split('+'),
        number: Number.parseInt(row[3], 10),
      };

      this.query.push(rowObj);
    });
  };

  getFlattenLessonsList = () => {
    let i = 1;
    const res = {};
    this.ExList.forEach((el) => {
      Object.values(el).forEach((l) => {
        res[i] = l;
        i += 1;
      });
    });

    return res;
  };

  generate = (lessonQuery) => {
    // console.log(lessonQuery);

    // const filteredExList = lessson.exercises.exercises
    //   .filter(v => lessonQuery.types.includes(v.questionType));
    // console.log(filteredExList);

    let genRes = {};
    const exRes = [];

    const lesson = this.LessonsList[lessonQuery.lesson];
    const { exercises } = lesson.exercises;
    let numOftypes = lessonQuery.number < 16 ? 2 : 4;

    // console.log(exercises);

    const types = this.pickRandomElements(
      numOftypes,
      this.pickStartIdx(lessonQuery, FormOptions.types.length - 1),
      FormOptions.types
    );

    let numOfExPerType = 0;

    if (types.length === 4) {
      const matchExIdx = exercises.findIndex((v) => v.questionType === 'match');
      exRes.push(exercises[matchExIdx]);
      numOfExPerType = Number.parseInt(
        (lessonQuery.number -
          exercises[matchExIdx].questionContent.elements.length) /
          3,
        10
      );
      numOftypes -= 1;
    } else {
      numOfExPerType = Number.parseInt(lessonQuery.number / numOftypes, 10);
    }

    const excludeMatch = exRes.length > 0;
    types.forEach((el, idx) => {
      if (!(excludeMatch && el.value === 'match') && el.value !== 'qna') {
        // add reminder to last element.
        const numPerType =
          types.length - 1 === idx && lessonQuery.number % 2 !== 0
            ? numOfExPerType + (lessonQuery.number % numOftypes)
            : numOfExPerType;

        const typeExercis = exercises.find((v) => v.questionType === el.value);
        const partsName = el.value === 'match' ? 'elements' : 'parts';
        const partsArr = typeExercis.questionContent[partsName];
        const exObj = {
          ...typeExercis,
          questionContent: {
            ...typeExercis.questionContent,
            [partsName]: this.pickRandomElements(
              numPerType,
              this.pickStartIdx(lessonQuery, partsArr.length, 12),
              partsArr
            ),
          },
        };

        exRes.push(exObj);
      }
    });

    // console.log(exRes);

    genRes = {
      ...lesson,
      exercises: {
        ...lesson.exercises,
        exercises: exRes,
      },
    };
    // console.log(genRes);
    // console.log('****************');

    return genRes;
  };

  mergeData = () => {
    const grouppedEx = {};
    let lessonObj = {};
    this.query.forEach((el) => {
      const { exercises, lesson } = this.generate(el);
      lessonObj = lesson;
      exercises.exercises.forEach((ex) => {
        const partsName = ex.questionType === 'match' ? 'elements' : 'parts';
        if (!grouppedEx[ex.questionType]) {
          grouppedEx[ex.questionType] = {
            ...ex,
            questionContent: { [partsName]: [] },
          };
        }
        grouppedEx[ex.questionType] = {
          ...grouppedEx[ex.questionType],
          questionContent: {
            ...grouppedEx[ex.questionType].questionContent,
            [partsName]: grouppedEx[ex.questionType].questionContent[
              partsName
            ].concat(ex.questionContent[partsName]),
          },
        };
      });
    });

    const mergedData = {
      lesson: lessonObj,
      exercises: {
        exercises: Object.values(grouppedEx).map((v) => v),
      },
    };

    return mergedData;
  };

  pickStartIdx = (query, total, factor = 1) =>
    ((query.chapter + query.lesson + query.number) * factor) % total;

  pickRandomElements = (num, startIdx, elements) => {
    const pickedEls = [];
    let t = startIdx - 1;
    // eslint-disable-next-line no-plusplus
    for (let idx = 0; idx < num; idx++) {
      pickedEls.push(elements[(t + 1) % elements.length]);

      t += 1;
    }

    return pickedEls;
  };

  toggle = (idx) => {
    this.setState({ activeLesson: idx });
  };

  render() {
    if (!this.state.data) return null;

    return this.props.merge ? (
      <div className="ExercisesGen">
        <section className="player bgc-dark py-3">
          <Container>
            <Row>
              <Col className="bgc-light">
                <ExercisesPlayer data={this.state.data} />
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    ) : (
      <div className="ExercisesGen">
        <section className="tab-navs bgc-dark pt-3">
          <Container>
            <Nav tabs>
              {this.query.map((el, idx) => (
                <NavItem key={idx}>
                  <NavLink
                    className={`${
                      this.state.activeLesson === idx ? 'active' : ''
                    }`}
                    onClick={() => {
                      this.toggle(idx);
                    }}
                  >
                    {FormOptions.lessons[el.lesson - 1].label}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          </Container>
        </section>
        <section className="player bgc-dark pb-3">
          <Container>
            <Row>
              <Col className="bgc-light">
                {this.query.map(
                  (el, idx) =>
                    this.state.activeLesson === idx && (
                      <ExercisesPlayer
                        key={idx}
                        data={this.state.data[this.state.activeLesson]}
                      />
                    )
                )}
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    );
  }
}

ExercisesGen.propTypes = {
  merge: PropTypes.bool,
  match: PropTypes.objectOf(PropTypes.any),
};

ExercisesGen.defaultProps = {
  merge: false,
  match: null,
};

export default ExercisesGen;
