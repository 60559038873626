import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';

// import { default as ExercisesPlayer } from '../../players/exercises/Exercises';
import SampleExercises from './SampleExercises';

import ExercisesForm from './ExercisesForm';

import './Exercises.scss';

class Exercises extends Component {
  chapter = {
    chapterID: '5c1a4eada88c8a161447c239',
    chapterTitle: 'الحركة والقوى والآلات البسيطة',
  };

  render() {
    return (
      <div className="prd-exercises">
        <section className="title-section bgc-dark">
          <Container>
            <div className="page-title">تمكين</div>
            <div className="page-subtitle">
              بنكٌ مكوّن من عشرات آلاف الأسئلة، يمكن للطالب حلّها للتأكد من فهمه
              للدرس، ويمكن للمعلّم استخدامها لتحضير إختباراته
            </div>
          </Container>
        </section>
        <section className="exercises-demo bgc-dark">
          <Container>
            <Row>
              <Col className="bgc-light px-0">
                {/* <ExercisesPlayer {...this.chapter} /> */}
                <SampleExercises />
              </Col>
            </Row>
          </Container>
        </section>

        <section className="features">
          <Container>
            <Row>
              <Col md="4" lg="3" className="rounded-column">
                <div className="icon">
                  <i className="fas fa-question" />
                </div>
                <div className="title">آلاف الأسئلة</div>
                <div className="text">
                  أكثر من عشرة آلاف سؤال مختلف، تغطي كافة أهداف الدروس وتسمح
                  للطالب بمراجعة المبادئ بشكل عمليّ وسريع.
                </div>
              </Col>
              <Col md="4" lg="3" className="rounded-column">
                <div className="icon">
                  <i className="fas fa-highlighter" />
                </div>
                <div className="title">جودة التمارين</div>
                <div className="text">
                  مئات التمارين المتنوعة لكل درس، جميعها مدققّ من قبل عدّة
                  مختصّين ومعلّمين، لضمان ثقة المستخدمين بكامل المحتوى.
                </div>
              </Col>
              <Col md="4" lg="3" className="rounded-column">
                <div className="icon">
                  <i className="fas fa-check-double" />
                </div>
                <div className="title">تصحيح فوريّ</div>
                <div className="text">
                  تقييم أداء الطلاب بشكل مباشر عبر التصحيح الحاسوبي لجميع
                  الأسئلة وإظهار النتيجة، مع إتاحة إمكانيّة إظهار الإجابات
                  الصحيحة للمدرّسين
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="exercises-form bgc-secondary">
          <section className="title-section">
            <div className="page-title">إعداد ورقة الأسئلة</div>
          </section>

          <Container>
            <ExercisesForm />
          </Container>
        </section>
      </div>
    );
  }
}

export default Exercises;
