import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import data from './content.json';

const levels = [
  ['rgb(163, 29, 31)', 'rgb(243, 97, 104)'],
  ['rgb(194, 103, 16)', 'rgb(255, 164, 0)'],
  ['rgb(42, 178, 98)', 'rgb(133, 219, 121)'],
  ['rgb(19, 163, 151)', 'rgb(62, 235, 209)'],
  ['rgb(90, 69, 126)', 'rgb(166, 137, 215)'],
  ['rgb(49, 122, 171)', 'rgb(130, 186, 217)'],
];

const dataPath = '/resources/earlylearning/demo';
export default function ContentViewer(props) {
  return (
    <div className="content-viewer">
      <img src={`${dataPath}/mainRes/bj.jpg`} alt="" className="background" />
      <div className="home-btn" onClick={props.home}>
        <div>
          <img src={`${dataPath}/mainRes/home_1.png`} alt="" />
        </div>
        <div className="selected">
          <img
            src={`${dataPath}/mainRes/home_2.png`}
            alt=""
            className="selected"
          />
        </div>
      </div>
      <img
        src={`${dataPath}/mainRes/diban.png`}
        alt=""
        className="foreground-box"
      />
      <img src={`${dataPath}/mainRes/title.png`} alt="" className="app-title" />
      <div className="inner-frame">
        <div className="object-container">
          <Row>
            {data[props.level - 1].contents.map((element) => (
              <Col xs={6} md={4} className="column">
                <div className="object">
                  <div
                    className="object-icon"
                    style={{
                      backgroundImage: `url("${dataPath}/${element.icon}")`,
                    }}
                  />
                  <div className="object-text">{element.title}</div>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </div>
      <div className="level-navigation">
        {levels.map((levelColour, index) => (
          <div
            className={
              index + 1 === props.level ? 'level-btn selected' : 'level-btn'
            }
            style={{
              background:
                index + 1 === props.level
                  ? 'rgb(247, 228, 178)'
                  : `linear-gradient(to right, ${levels[index][0]} 0%, ${levels[index][1]} 100%)`,
            }}
            onClick={() => props.changeLevel(index + 1)}
          >
            {index + 1}
          </div>
        ))}
      </div>
    </div>
  );
}

ContentViewer.propTypes = {
  level: PropTypes.number.isRequired,
  changeLevel: PropTypes.func.isRequired,
};
