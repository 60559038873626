import React, { Component } from 'react';
import {
  Container,
  Row,
  Col,
  Collapse,
  Card,
  CardBody,
  CardTitle,
  CardText,
} from 'reactstrap';
import PropTypes from 'prop-types';

class DisplayList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataText: props.data.text,
      dataTitle: props.data.title,
      dataImage: props.data.image,
    };
  }

  select(title, text, image) {
    return this.state.dataText === text
      ? this.setState({
          dataTitle: this.props.data.title,
          dataText: this.props.data.text,
          dataImage: this.props.data.image,
        })
      : this.setState({ dataTitle: title, dataText: text, dataImage: image });
  }

  render() {
    return (
      <Container className="display-list">
        <Row className="large-screen">
          <Col className="elements" xs="5" lg="4">
            {this.props.data.elements.map((element, key) => (
              <div
                key={key}
                onClick={() => {
                  this.select(element.title, element.text, element.image);
                }}
                className="element"
              >
                <div>{element.title}</div>
                {this.state.dataText === element.text ? (
                  <i className="fas fa-caret-down icon open" />
                ) : (
                  <i className="fas fa-caret-left icon" />
                )}
              </div>
            ))}
          </Col>
          <Col className="text">
            <Card>
              <CardBody>
                <CardTitle className="title">{this.state.dataTitle}</CardTitle>
                <CardText>
                  <div>{this.state.dataText}</div>
                  {this.state.dataImage && (
                    <img src={this.state.dataImage} alt="" />
                  )}
                </CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row className="small-screen">
          <Col className="elements">
            {this.props.data.elements.map((element, key) => (
              <Card key={key}>
                <CardBody>
                  <CardTitle
                    className="element"
                    onClick={() =>
                      this.select(element.title, element.text, element.image)
                    }
                  >
                    <div>{element.title}</div>
                    {this.state.dataText === element.text ? (
                      <i className="fas fa-caret-down icon open" />
                    ) : (
                      <i className="fas fa-caret-left icon" />
                    )}
                  </CardTitle>
                  <CardText>
                    <Collapse
                      isOpen={this.state.dataText === element.text}
                      className="text"
                    >
                      <div>{element.text}</div>
                      {element.image && <img src={element.image} alt="" />}
                    </Collapse>
                  </CardText>
                </CardBody>
              </Card>
            ))}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default DisplayList;

DisplayList.propTypes = {
  data: PropTypes.objectOf({
    title: PropTypes.string,
    text: PropTypes.string,
    elements: PropTypes.arrayOf({
      title: PropTypes.string,
      text: PropTypes.string,
    }),
  }).isRequired,
};
