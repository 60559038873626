import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

export default function SimpleTable(props) {
  const useStyles = makeStyles((theme) => ({
    root: {
      //margin: theme.spacing(2),
      margin: '0 auto',
      direction: 'rtl',
      textAlign: 'center',
      overflowX: 'auto',
      maxWidth: props.table.width,
    },
    table: {
      textAlign: 'center',
      borderCollapse: 'collapse',
      backgroundColor: props.table.color1,
    },
    header: {
      backgroundColor: props.table.headerColor,
    },

    rowText: {
      fontSize: '1.1rem',
    },

    row2: {
      backgroundColor: props.table.color2,
    },

    headerText: {
      fontSize: '1.2rem',
      fontWeight: 'bolder',
    },
  }));
  const classes = useStyles();
  return (
    <div style={{ width: '100%', textAlign: 'center' }}>
      <Paper className={classes.root}>
        <Table align="center" className={classes.table}>
          <TableHead className={classes.header}>
            <TableRow>
              {props.table.columns.map((column) => (
                <TableCell
                  key={column.id}
                  align="right"
                  style={{ minWidth: column.minWidth }}
                >
                  <Typography
                    color="textSecondary"
                    style={{
                      color: props.table.headerFontColor,
                      fontSize: props.table.headerFontSize,
                      fontWeight: props.table.headerFontWeight,
                    }}
                    className={classes.headerText}
                    dangerouslySetInnerHTML={{ __html: column.label }}
                  ></Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.table.rows.map((row, index) => (
              <TableRow key={row + index}>
                {props.table.columns.map((column) => (
                  <TableCell
                    key={row + index + column.id}
                    align="right"
                    className={classes['row' + ((index % 2) + 1)]}
                  >
                    <Typography
                      className={classes['rowText']}
                      style={{
                        color: column.color,
                        fontSize: column.fontSize,
                        fontWeight: column.fontWeight,
                      }}
                      dangerouslySetInnerHTML={{ __html: row[column.id] }}
                    ></Typography>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
}
