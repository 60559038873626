import React from 'react';
import { Container, Row, Col } from 'reactstrap';

function SellingPoints() {
  return (
    <Container>
      <Row>
        <Col md="4" lg="3" className="rounded-column">
          <div className="icon">
            <i className="fas fa-video" />
          </div>
          <div className="title">جاذبة ومعاصرة</div>
          <div className="text">
            فيديوهات ثلاثيّة الأبعاد بجودة تخاطب الجيل المعاصر، وتجذب الطالب من
            خلال ألوانها وسهولتها وقدرتها على إيصال الأفكار المطلوبة.
          </div>
        </Col>
        <Col md="4" lg="3" className="rounded-column">
          <div className="icon">
            <i className="fas fa-clock" />
          </div>
          <div className="title">موجزة ومحدّدة</div>
          <div className="text">
            تتراوح مدّتها ما بين دقيقة ودقيقتين، توصل الأهداف المطلوبة بطريقة
            سريعة ودقيقة، محافظةً بذلك على تركيز المشاهد وعدم ملله.
          </div>
        </Col>
        <Col md="4" lg="3" className="rounded-column">
          <div className="icon">
            <i className="fas fa-puzzle-piece" />
          </div>
          <div className="title">مفصّلة للمنهاج</div>
          <div className="text">
            فيديوهات مصمّمة خصيصاً لمنهجكم الدراسيّ المعتمد، تغطي الأهداف
            التعليميّة بشكل شامل وبأسلوب علمي وممتع.
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default SellingPoints;
