import React from 'react';
import { Container } from 'reactstrap';
import './Encyclopedias.scss';
import PortalPlayer from './PortalPlayer';
import Portals from './Portals';
import ActivitiesTabs from './ActivitesTabs';

function EarlyLearning() {
  return (
    <div className="prd-encyclopedias">
      <section className="demo">
        <section className="title-section">
          <Container>
            <div className="page-title">معرفة</div>
            <div className="page-subtitle">
              موسوعات متنوّعة، مفيدة للمعلّم والمتعلّم على حدٍّ سواء، تغطّي
              مجموعةً واسعةً من المواضيع بأسلوبٍ منظّم ومنسّق
            </div>
          </Container>
        </section>
        <Container className="player">
          <PortalPlayer />
        </Container>
      </section>

      <section className="content-list">
        <section className="title-section">
          <div className="page-title">بعض من عناويننا</div>
        </section>
        {/* <Container className="encyclopedies-sample-icons">
          <Row>
            {contentList.map(l => (
              <Col sm={2} xs={3} className="container">
                <div
                  style={{
                    backgroundImage: `url("/resources/encyclopedias-icons/${l.icon}")`,
                  }}
                  className="icon"
                />
                <div className="title">{l.title}</div>
              </Col>
            ))}
          </Row>
        </Container> */}
        <Container>
          <Portals />
        </Container>
      </section>

      {/* <hr /> */}

      <section className="lessons">
        <section className="tabs">
          <Container>
            <ActivitiesTabs />
          </Container>
        </section>
      </section>
    </div>
  );
}

export default EarlyLearning;
