import React, { Component } from 'react';
import PropTypes from 'prop-types';
import swal from '@sweetalert/with-react';

import MCQ from './components/MCQ/MCQ';
import QuestionAndAnswer from './components/QuestionAndAnswer/QuestionAndAnswer';
import TrueFalse from './components/TrueFalse/TrueFalse';
import Blanks from './components/Blanks/Blanks';
import Match from './components/Match/Match';
import ExerciseStatuses from './utils/exercise-statuses';
import Pagination from './utils/pagination';
// import ProgressStore from './utils/exercises-progress-store';
// import { Pagination, ProgressStore } from '../..';

import './ExercisesViewer.scss';

class ExercisesViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      curPageIdx: 0,
    };

    this.myRef = React.createRef();

    // this flag is used to do submit/calculate results,
    // and it should be reset to false directly after that.
    this.isSubmit = false;

    this.init();

    // this.restoreProgressIfExists();
  }

  shouldComponentUpdate() {
    // take snapshot of exStates before any update on viewer
    this.exRefs.forEach((exRef) => {
      const refIdx = this.exStates.findIndex(
        (exState) => exState.exMeta === exRef.props.exMeta
      );
      if (refIdx < 0) {
        this.exStates.push({
          exMeta: exRef.props.exMeta,
          state: exRef.ref.state,
        });
      } else {
        this.exStates[refIdx].state = exRef.ref.state;
        this.exStates[refIdx].result =
          exRef.exStatus > 0 ? exRef.checkAnswer : undefined;
        this.exStates[refIdx].status =
          this.exStates[refIdx].action !== 'reset' ? exRef.exStatus : 0;
      }
    });

    // reset preparing to refill it after updating.
    this.exRefs = [];

    // calculate results
    if (this.isSubmit) {
      let correctCount = 0;
      let unTouchedCount = 0;
      let wrongCount = 0;
      this.exStates.forEach((el) => {
        // if there is no preState OR exercise status is UNTOUCHED, increament the untouched counter
        if (!el.status || el.status === ExerciseStatuses.UNTOUCHED) {
          unTouchedCount += 1;
        } else {
          // otherwise get the result in add it to the correct counter.
          correctCount += el.result || 0;
        }
      });

      wrongCount = this.exStates.length - unTouchedCount - correctCount;

      this.result = {
        correctCount,
        wrongCount,
        unTouchedCount,
      };

      this.renderResults();

      // reset flag, to avoid recalculat result on changing page.
      // this.isSubmit = false;
    }

    return true;
  }

  componentDidUpdate(prevProps) {
    // reinitiate viewer ONLY when exercises prop changed
    if (prevProps.exercises !== this.props.exercises) {
      this.init();
      this.changePage(0);
    }

    if (this.isSubmit) {
      // save snapshot to localstorage
      const exToSave = {};
      this.exStates
        .filter((el) => el.status > ExerciseStatuses.UNTOUCHED)
        .forEach((el) => {
          exToSave[el.exMeta.id] = {
            state: el.state,
            result: el.result,
            submitted: el.submitted,
          };
        });

      // ProgressStore.saveExerciseProgress(this.props._id, exToSave);

      this.myRef.current.classList.toggle('locked');

      this.isSubmit = false;
    }
  }

  init = (props = this.props) => {
    this.exRefs = [];

    // declare & init exState array
    // NOTE: exState array used below instead of props.exercises to get the exMetas.
    this.exStates = props.exercises
      // No need to filter anymore, since this prop is expected to be questions ONLY.
      // .filter(e => e.type !== 'questionText')
      .map((el, index) => {
        const v = {
          exMeta: { ...el, order: index },
        };

        return v;
      });

    this.pagination = new Pagination(
      props.showPerPage,
      this.exStates.length,
      this.state.curPageIdx
    );

    // reset results when changing exercises array.
    // it should be removed when saving states of viewer on changing exercises array.
    this.result = null;
  };

  // restoreProgressIfExists = () => {
  //   const exProgress = ProgressStore.getExerciseProgress(this.props._id);

  //   Object.keys(exProgress).forEach((el) => {
  //     const exIdx = this.exStates.findIndex(ex => ex.exMeta.id === el);

  //     if (exIdx > -1) {
  //       this.exStates[exIdx].state = exProgress[el].state;
  //       this.exStates[exIdx].result = exProgress[el].result;
  //       this.exStates[exIdx].submitted = exProgress[el].submitted;
  //       this.exStates[exIdx].action = 'submit';
  //     }
  //   });
  // };

  submit = () => {
    this.exStates = this.exStates.map((el) => {
      let stateObj = {};
      // TODO: lock all questions after submit (make canAnswer false).
      stateObj = { ...el, action: 'submit', submitted: true };
      if (stateObj.state) {
        stateObj.state.canAnswer = false;
      } else {
        stateObj.state = { canAnswer: false };
      }
      return stateObj;
    });

    this.isSubmit = true;

    // this will trigger shouldComponentUpdate with isSubmit flag true,
    // so results will calculated and displayed
    this.refresh();
  };

  reset = () => {
    swal({
      titleText: 'تحذير',
      content: <p>سيتم محو كافة الاجابات... هل أنت متأكد؟</p>,
      icon: 'warning',
      buttons: {
        confirm: {
          text: 'نعم',
          value: true,
          visible: true,
          closeModal: true,
        },
        cancel: {
          text: 'كلا',
          value: false,
          visible: true,
          closeModal: true,
        },
      },
    }).then((result) => {
      if (!result) return;
      this.exStates = this.exStates.map((el) => {
        let stateObj = {};
        stateObj = {
          ...el,
          state: null,
          action: 'reset',
          status: null,
          submitted: null,
          result: undefined,
        };

        return stateObj;
      });

      // ProgressStore.resetExerciseProgress(this.props._id);

      // reset result to remove result box if user clicked submit before.
      this.result = null;

      this.myRef.current.classList.remove('locked');

      this.refresh();
    });
  };

  showAnswer = () => {
    this.exStates = this.exStates.map((el) => {
      let stateObj = {};
      if (el.submitted) {
        stateObj = { ...el };
      } else {
        stateObj = { ...el, action: 'showAnswer' };
      }

      return stateObj;
    });

    this.result = null;
    this.answersShown = true;
    this.refresh();
  };

  pushToRefs = (ref) => {
    if (!ref) return;
    this.exRefs.push(ref);
  };

  renderPagesNumbers = () => {
    const numbers = [];
    [...Array(this.pagination.numOfPages).keys()].forEach((el) => {
      numbers.push(
        <span
          key={el}
          style={{ color: this.state.curPageIdx === el ? 'red' : 'black' }}
          onClick={this.changePage.bind(this, el)}
        >
          {el + 1}
        </span>
      );
    });
    return numbers;
  };

  renderResults = () => {
    if (!this.result) return null;

    return swal({
      text: '',
      buttons: {
        ok: 'موافق',
      },
      content: (
        <div id="ex-viewer-results">
          <p>
            عدد الاجابات الصحيحة:
            {'  '}
            {this.result.correctCount}
          </p>

          <p>
            عدد الاجابات الخاطئة:
            {'  '}
            {this.result.wrongCount}
          </p>

          <p>
            عدد الأسئلة الغير مكتملة:
            {'  '}
            {this.result.unTouchedCount}
          </p>

          <p>
            عدد الأسئلة الإجمالي:
            {this.exStates.length}
          </p>
        </div>
      ),
    });
  };

  changePage = (pageIdx, scrollToIdx = 0) => {
    this.pagination.curPageIdx = pageIdx;
    this.setState(
      {
        curPageIdx: pageIdx,
      },
      () => {
        // FIXME: delay untile images loaded to make scroll accurate.
        setTimeout(() => {
          this.exRefs[scrollToIdx].scrollToMyRef();
        }, 300);
      }
    );
  };

  nextPage = () => {
    if (this.pagination.isLastPage) return;

    this.changePage(this.state.curPageIdx + 1);
  };

  prevPage = () => {
    if (this.pagination.isFirstPage) return;

    this.changePage(this.state.curPageIdx - 1);
  };

  // update the viewer and its child comps,
  // empty setState used here to make sure componentShouldUpdate() triggered.
  // FIXME: try to find better solution
  refresh = () => {
    this.setState({});
  };

  initilizeExercises = () => {
    const exercisesArray = [];
    const a = this.exStates
      .map((e) => e.exMeta)
      .slice(this.pagination.start, this.pagination.end);

    a.forEach((exercise, index) => {
      let exComponent = null;

      const prevState = this.exStates.find(
        (exState) => exState.exMeta === exercise
      );
      const action = prevState ? prevState.action : 0;
      const props = {
        exMeta: exercise,
        qNum: exercise.order + 1,
        key: index,
        state: prevState ? prevState.state : null,
        action,
        onChange: this.refresh,
      };

      if (prevState) {
        prevState.action = 0;
      }

      switch (exercise.type) {
        case 'mcq':
          exComponent = <MCQ {...props} ref={this.pushToRefs.bind(this)} />;
          break;

        case 'questionAndAnswer':
          exComponent = (
            <QuestionAndAnswer {...props} ref={this.pushToRefs.bind(this)} />
          );
          break;

        case 'tf':
          exComponent = (
            <TrueFalse {...props} ref={this.pushToRefs.bind(this)} />
          );
          break;

        case 'blanks':
          exComponent = <Blanks {...props} ref={this.pushToRefs.bind(this)} />;
          break;

        case 'match':
          exComponent = <Match {...props} ref={this.pushToRefs.bind(this)} />;
          break;

        case 'qna':
          exComponent = (
            <QuestionAndAnswer {...props} ref={this.pushToRefs.bind(this)} />
          );
          break;

        default:
          break;
      }

      if (exComponent) {
        exercisesArray.push(exComponent);
      }
    });

    return exercisesArray;
  };

  renderSideNav() {
    return (
      <div id="qstSideNav">
        <div className="qstNavsList">
          {this.exStates.map((ex, idx) => (
            <span className="qstNavWrapper" key={idx}>
              <span
                className={`qstNav st-${ex.status} ${
                  ex.submitted &&
                  ex.result !== undefined &&
                  (ex.result ? 'st-2' : 'st-3')
                }`}
                style={{
                  borderColor: !this.pagination.isVisible(idx)
                    ? 'lightgrey'
                    : '',
                }}
                onClick={this.changePage.bind(
                  this,
                  this.pagination.findPageIndex(ex.exMeta.order),
                  Number.parseInt(idx % this.pagination.showPerPage, 10)
                )}
              >
                <span className="qstNavContent">{ex.exMeta.order + 1}</span>
              </span>
            </span>
          ))}
        </div>

        <div className="navBtns">
          <span
            onClick={this.prevPage}
            className={`${this.pagination.isFirstPage ? 'disabled' : ''}`}
          >
            {'< '}
            السابق
          </span>

          <span
            onClick={this.nextPage}
            className={`${this.pagination.isLastPage ? 'disabled' : ''}`}
          >
            التالي
            {' >'}
          </span>
        </div>

        <div className="actionBtns">
          <span onClick={this.reset}>&#10227; إعادة</span>
          <span onClick={this.showAnswer}>&#9998; الأجوبة</span>
          <span onClick={this.submit}>
            &#10004;
            {this.result ? 'إظهار النتائج' : 'إنهاء'}
          </span>
        </div>
      </div>
    );
  }

  render() {
    // const exercises = this.initilizeExercises();
    if (!this.props.visible) return null;

    // this.renderResults();

    return (
      <div className="exercises-viewer-container">
        <div className="exercises-viewer" ref={this.myRef}>
          {/* <QuestionText question={this.props.exQuestion} /> */}
          <p dangerouslySetInnerHTML={{ __html: this.props.exQuestion }} />

          {this.initilizeExercises()}

          {/* {this.renderResults()} */}
        </div>
        {this.renderSideNav()}
      </div>
    );
  }
}

ExercisesViewer.propTypes = {
  exercises: PropTypes.arrayOf(PropTypes.any).isRequired,
  showPerPage: PropTypes.number,
  visible: PropTypes.bool,
};

ExercisesViewer.defaultProps = {
  showPerPage: 5,
  visible: true,
};

export default ExercisesViewer;
