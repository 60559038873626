import React from 'react';
import {
  Player,
  BigPlayButton,
  ControlBar,
  VolumeMenuButton,
  CurrentTimeDisplay,
  TimeDivider,
  PlaybackRateMenuButton,
} from 'video-react';

export default function SampleVideo() {
  // FIXME: fix video source
  return (
    <div>
      <Player
        playsInline
        poster="/resources/earlylearning/فيفي وبيتها في السوق.jpg"
        src="/resources/earlylearning/فيفي وبيتها في السوق.mp4"
      >
        <BigPlayButton position="center" />
        <ControlBar>
          {/* <ReplayControl seconds={10} order={1.1} />
            <ForwardControl seconds={30} order={1.2} /> */}
          <VolumeMenuButton order={8.1} />
          <CurrentTimeDisplay order={4.1} />
          <TimeDivider order={4.2} />
          <PlaybackRateMenuButton rates={[5, 2, 1, 0.75]} order={7.1} />
        </ControlBar>
      </Player>
    </div>
  );
}
