import React, { Component } from 'react';
import { TabContent, TabPane, Row, Col } from 'reactstrap';

import './ActivitesTabs.scss';

class ActivitesTabs extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1',
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    return (
      <div className="activites-tabs">
        <Row className="tabs-nav">
          <Col xs={4} md={3}>
            <div
              className={`nav-container ${
                this.state.activeTab === '1' ? 'active' : ''
              }`}
              onClick={() => {
                this.toggle('1');
              }}
            >
              <div className="icon">
                <i className="fas fa-network-wired" aria-hidden="true" />
              </div>

              <div className="title">أنشطة ممتعة</div>
            </div>
          </Col>

          <Col xs={4} md={3}>
            <div
              className={`nav-container ${
                this.state.activeTab === '2' ? 'active' : ''
              }`}
              onClick={() => {
                this.toggle('2');
              }}
            >
              <div className="icon">
                <i className="fas fa-list-ul" aria-hidden="true" />
              </div>

              <div className="title">مدخل جاذب</div>
            </div>
          </Col>

          <Col xs={4} md={3}>
            <div
              className={`nav-container ${
                this.state.activeTab === '3' ? 'active' : ''
              }`}
              onClick={() => {
                this.toggle('3');
              }}
            >
              <div className="icon">
                <i className="fas fa-pencil-alt" />
              </div>

              <div className="title">تقييم فعّال</div>
            </div>
          </Col>
        </Row>

        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1" className="activites-pane">
            <Row>
              <Col sm={12} md={6} className="paragraph">
                <div className="title">أسلوب عمليّ مسلٍّ</div>
                <div className="text">
                  نقدّم للمدرّس أنشطةً تفاعليّة مختلفة، تسعى إلى تمكين الطالب من
                  فهم أهم نقاط الدرس، وتضمن استيعابه للأفكار المشروحة سابقاً.
                  تتبع هذه الأنشطة أسلوباً يحثّ الطلاب على التفكير بشكلٍ جماعيّ،
                  وتعطي المدرّس أفكاراً لعمل المجموعات.
                </div>
              </Col>

              <Col sm={12} md={6}>
                <div
                  className="image"
                  style={{
                    backgroundImage: 'url("/resources/teachers/النشاط.JPEG")',
                  }}
                />
              </Col>
            </Row>
          </TabPane>

          <TabPane tabId="2" className="activites-pane">
            <Row>
              <Col sm={12} md={6} className="paragraph">
                <div className="title">جذبٌ تامٌّ لتركيز الطّلّاب</div>
                <div className="text">
                  نقدّم للمدرّس تمهيداً جاذباً للدرس، وذلك عبر طرح أسئلة
                  تمهيديّة يمكن للمدرّس الاستفادة منها في الصفّ. تحفّز هذه
                  الأسئلة الطلاب على التفكير باهتمامٍ حول موضوع الدرس وذلك عبر
                  ربطه بأسئلة تلامس واقعهم.
                </div>
              </Col>
              <Col sm={12} md={6}>
                <div
                  className="image"
                  style={{
                    backgroundImage: 'url("/resources/teachers/أسئلة.JPG")',
                  }}
                />
              </Col>
            </Row>
          </TabPane>

          <TabPane tabId="3" className="activites-pane">
            <Row>
              <Col sm={12} md={6} className="paragraph">
                <div className="title">تقييمٌ دقيق للأهداف</div>
                <div className="text">
                  نقدّم للمدرّس أسئلة تفاعليّة مفتوحة حول الكفايات التعليميّة
                  للدرس تمكنّه من تقييم إيصاله للأهداف. تكون هذه الأهداف مختصرةً
                  ومحدّدة، كما ونقدّم إجاباتها المختصرة لتمكين المدرّس من
                  تقييمها.
                </div>
              </Col>

              <Col sm={12} md={6}>
                <div
                  className="image"
                  style={{
                    backgroundImage: 'url("/resources/teachers/تلميذ.JPG")',
                  }}
                />
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

export default ActivitesTabs;
