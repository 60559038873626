import React, { Component } from 'react';
import PropTypes from 'prop-types';

import LineTo from './react-lineto';

import './Match.scss';

import ExerciseStatuses from '../../utils/exercise-statuses';
import wrapWithExerciseBase from '../ExerciseWrapperHOC/ExerciseWrapper';

class Match extends Component {
  constructor(props) {
    super(props);
    this.state = { ...this.initalState() };
  }

  initalState = () => {
    const v = {
      exStatus: ExerciseStatuses.UNTOUCHED,
      canAnswer: true,
      answer: [],
      activeLine: false,
      choosenEl: null,
    };
    return v;
  };

  handleFromElClick = (fromElClass) => {
    if (!this.state.canAnswer) return;

    let { choosenEl } = this.state;

    if (choosenEl && choosenEl.classList.contains(fromElClass)) {
      choosenEl.classList.toggle('active');
      choosenEl = null;
    } else {
      if (choosenEl) {
        choosenEl.classList.toggle('active');
      }

      // eslint-disable-next-line prefer-destructuring
      choosenEl = document.getElementsByClassName(fromElClass)[0];

      choosenEl.classList.toggle('active');
    }

    this.setState({ choosenEl });
  };

  handleToElClick = (toElClass) => {
    let { choosenEl } = this.state;

    if (!choosenEl || !this.state.canAnswer) return;

    if (this.state.exStatus === ExerciseStatuses.UNTOUCHED) {
      this.setState({
        exStatus: ExerciseStatuses.TOUCHED,
      });
    }

    const fromClassName = choosenEl.classList[2];
    const toClassName = toElClass;

    const answerObj = {
      from: fromClassName,
      to: toClassName,
    };

    choosenEl.classList.toggle('active');
    choosenEl = null;

    this.setState(
      (prev) => {
        const v = { answer: prev.answer.concat(answerObj), choosenEl };
        return v;
      },
      () => {
        if (this.props.onChange) {
          this.props.onChange();
        }
      }
    );
  };

  handleLineClick = (a) => {
    if (!this.state.canAnswer) return;

    let { choosenEl } = this.state;
    if (choosenEl) {
      choosenEl.classList.toggle('active');
      choosenEl = null;
    }

    const lineIdx = this.state.answer.findIndex((el) => el === a);

    if (lineIdx >= 0) {
      this.setState(
        (prev) => {
          prev.answer.splice(lineIdx, 1);
          const exStatus =
            prev.answer.length > 0
              ? ExerciseStatuses.TOUCHED
              : ExerciseStatuses.UNTOUCHED;

          return { answer: prev.answer, exStatus, choosenEl };
        },
        () => {
          if (this.props.onChange) {
            this.props.onChange();
          }
        }
      );
    }
  };

  handelLineHover = (p) => {
    if (p) {
      this.setState({
        activeLine: { from: p.from, to: p.to },
      });
    } else {
      this.setState({
        activeLine: { from: '', to: '' },
      });
    }
  };

  checkAnswer = () => {
    const { answer } = this.state;
    let isAllCorrect = true;
    answer.forEach((el, index) => {
      const v = this.solution.findIndex(
        (s) => s.from === el.from && s.to === el.to
      );
      if (v > -1) {
        answer[index].status = ExerciseStatuses.CORRECT;
      } else {
        answer[index].status = ExerciseStatuses.WRONG;
        isAllCorrect = false;
      }
    });

    const exStatus = isAllCorrect
      ? ExerciseStatuses.CORRECT
      : ExerciseStatuses.WRONG;

    this.setState({
      exStatus,
      answer,
    });

    return isAllCorrect;
  };

  reset = () => {
    this.setState({
      ...this.initalState(),
    });
  };

  showAnswer = () => {
    this.setState({
      answer: [...this.solution],
    });
  };

  renderFromCol() {
    const _id = this.props.exMeta.id;
    const { choices, elements } = this.props.exMeta;
    this.solution = [];

    return elements.map((el, index) => {
      const fromClassName = `${_id}_from_${index}`;
      // build solution array
      this.solution = this.solution.concat(
        el.matches.map((match) => {
          const toElIndex = choices.findIndex((ch) => ch.text === match);
          const toClassName = choices[toElIndex].elClassName;
          const v = {
            from: fromClassName,
            to: toClassName,
          };

          return v;
        })
      );

      const fromCol = (
        <div
          className={`match-el match-from ${fromClassName}`}
          key={index}
          onClick={this.handleFromElClick.bind(this, fromClassName)}
          dangerouslySetInnerHTML={{ __html: el.element }}
        >
          {/* {el} */}
        </div>
      );

      return fromCol;
    });
  }

  renderToCol() {
    const { choices } = this.props.exMeta;

    return choices.map((el, index) => {
      // const toClassName = `${_id}_to_${index}`;
      const toClassName = el.elClassName;

      const toEl = (
        <div
          className={`match-el match-to ${toClassName} ${
            this.state.choosenEl ? 'active' : ''
          }`}
          key={index}
          onClick={this.handleToElClick.bind(this, toClassName)}
          dangerouslySetInnerHTML={{ __html: el.text }}
        >
          {/* {el.element} */}
        </div>
      );

      return toEl;
    });
  }

  renderQNumber() {
    const { qNum } = this.props;

    if (!qNum) return null;

    return (
      <span className="question-number">
        <strong>{this.props.qNum}.</strong>
      </span>
    );
  }

  renderContext() {
    const { context } = this.props.exMeta;

    if (!context || context === '') return null;

    return (
      <div className="question-context">
        <span dangerouslySetInnerHTML={{ __html: context }} />
        <br />
      </div>
    );
  }

  renderQuestion() {
    const { question } = this.props.exMeta;

    if (!question || question === '') return null;

    return (
      <span
        className="question-text"
        dangerouslySetInnerHTML={{ __html: question }}
      />
    );
  }

  renderExplanation() {
    const { explanation } = this.props.exMeta;

    if (!explanation || explanation === '') return null;

    return (
      <span
        className="question-explanation"
        dangerouslySetInnerHTML={{ __html: explanation }}
      />
    );
  }

  render() {
    return (
      <div
        className={`exercise match ${
          this.state.exStatus === ExerciseStatuses.CORRECT ? 'correct' : ''
        } ${this.state.exStatus === ExerciseStatuses.WRONG ? 'wrong' : ''}`}
      >
        {/* <div className="qst-header">
          {this.renderQNumber()}

          {this.renderContext()}

          {this.renderQuestion()}
        </div> */}

        <div style={{ position: 'relative' }} className="match-columns">
          <div className="match-column right">
            {this.renderFromCol().map((el) => el)}
          </div>

          {this.state.answer.map((a, index) => (
            <LineTo
              {...a}
              key={index}
              className={`match-line st-${
                a.status &&
                this.state.exStatus > ExerciseStatuses.TOUCHED &&
                (a.status === ExerciseStatuses.CORRECT
                  ? ExerciseStatuses.CORRECT
                  : ExerciseStatuses.WRONG)
              }`}
              borderWidth={3}
              orientation="h"
              within="match-columns"
              fromAnchor="center left"
              toAnchor="center right"
              delay={300}
              // eslint-disable-next-line react/jsx-no-bind
              onClick={this.handleLineClick.bind(this, a)}
              onLineHover={this.handelLineHover}
              active={
                this.state.activeLine.from === a.from &&
                this.state.activeLine.to === a.to
              }
            />
          ))}

          <div className="match-column left">
            {this.renderToCol().map((el) => el)}
          </div>
        </div>
      </div>
    );
  }
}

Match.propTypes = {
  exMeta: PropTypes.objectOf(PropTypes.any).isRequired,
  qNum: PropTypes.number,
  onChange: PropTypes.func,
};

Match.defaultProps = {
  qNum: null,
  onChange: null,
};

export default wrapWithExerciseBase(Match);
export { Match as MatchPURE };
