import React from 'react';
import PropTypes from 'prop-types';

import './PopupElement.scss';

class PopupElement extends React.Component {
  constructor(props) {
    super(props);
    this.currentContent = this.props.content;
  }

  componentDidMount() {
    // FIXME: timeout forced in order to account for MathJax popups problem
    this.props.recalculateBounds();
    setTimeout(() => {
      this.props.recalculateBounds();
    }, 0);
    window.addEventListener('resize', this.props.hide);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.props.hide);
  }

  getContent() {
    // FIXME: check for all image types
    // FIXME: switch the change into the parser and dangerously set all
    if (
      this.props.content.includes('.jpg') ||
      this.props.content.includes('.PNG') ||
      this.props.content.includes('.png') ||
      this.props.content.includes('.svg')
    ) {
      return (
        <img
          src={this.props.content}
          className="page-popup-img"
          alt=""
          onLoad={this.props.recalculateBounds}
        />
      );
    }
    return <div dangerouslySetInnerHTML={{ __html: this.props.content }} />;
  }

  render() {
    return (
      <div className="popupElement">
        {this.props.content && (
          <div
            className="arabic page-popup-text"
            id="popupBox"
            style={{ left: this.props.left, top: this.props.top }}
          >
            {this.getContent()}
          </div>
        )}
      </div>
    );
  }
}

PopupElement.propTypes = {
  left: PropTypes.number,
  top: PropTypes.number,
  content: PropTypes.string.isRequired,
  recalculateBounds: PropTypes.func.isRequired,
  hide: PropTypes.func.isRequired,
};

PopupElement.defaultProps = {
  left: 0,
  top: 0,
};

export default PopupElement;
