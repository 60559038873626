import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './SampleApps.scss';

const appsList = [
  {
    img: 'cat.PNG',
    title: 'الهر ذو الجزمة',
    links: {
      android:
        'https://play.google.com/store/apps/details?id=ca.digitalfuture.cxzdm',
      ios: 'https://itunes.apple.com/us/app/%D8%A7%D9%84%D9%87%D8%B1-%D8%B0%D9%88-%D8%A7%D9%84%D8%AC%D8%B2%D9%85%D8%A9/id1360595749?ls=1&mt=8',
    },
  },
  {
    img: 'IMG_0582.PNG',
    title: 'مغناطيس يبحث عن أصدقاء',
    links: {
      android:
        'https://play.google.com/store/apps/details?id=ca.digitalfuture.ctzpy',
      ios: 'https://itunes.apple.com/us/app/%D9%85%D8%BA%D9%86%D8%A7%D8%B7%D9%8A%D8%B3-%D9%8A%D8%A8%D8%AD%D8%AB-%D8%B9%D9%86-%D8%A3%D8%B5%D8%AF%D9%82%D8%A7%D8%A1/id1360595674?ls=1&mt=8',
    },
  },
  {
    img: 'IMG_0601.PNG',
    title: 'من أين تأتي الكهرباء',
    links: {
      android:
        'https://play.google.com/store/apps/details?id=ca.digitalfuture.dcnll',
      ios: 'https://itunes.apple.com/us/app/%D9%85%D9%86-%D8%A3%D9%8A%D9%86-%D8%AA%D8%A3%D8%AA%D9%8A-%D8%A7%D9%84%D9%83%D9%87%D8%B1%D8%A8%D8%A7%D8%A1/id1360596678?ls=1&mt=8',
    },
  },
];

class SampleApps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeApp: -1,
    };
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  selectApp(newActive) {
    this.setState({ activeApp: newActive });
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ activeApp: -1 });
    }
  }

  render() {
    return (
      <Container className="early-sample-apps">
        <Row className="shorter-row">
          {appsList.map((app, idx) => (
            <Col xs={10} md={4} lg={4} className="unit">
              <button
                className="element"
                type="button"
                onClick={() => this.selectApp(idx)}
              >
                <div
                  className="image"
                  style={{
                    backgroundImage: `url("/resources/earlylearning/apps/${app.img}")`,
                  }}
                >
                  {this.state.activeApp === idx ? (
                    <div className="links-to-apps" ref={this.setWrapperRef}>
                      <a
                        href={app.links.android}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="link"
                      >
                        <div className="icon and-icon">
                          <i className="fab fa-android" />
                        </div>
                      </a>
                      <br />
                      <a
                        href={app.links.ios}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="link"
                      >
                        <div className="icon">
                          <i className="fab fa-apple" />
                        </div>
                      </a>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="text">{app.title}</div>
              </button>
            </Col>
          ))}
        </Row>
      </Container>
    );
  }
}
export default SampleApps;
