Object.defineProperty(exports, '__esModule', {
  value: true,
});

const _cssAnimation = require('css-animation');

const _cssAnimation2 = _interopRequireDefault(_cssAnimation);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

function animate(node, show, transitionName, done) {
  let height = void 0;
  return (0, _cssAnimation2.default)(node, transitionName, {
    start: function start() {
      if (!show) {
        node.style.height = `${node.offsetHeight}px`;
      } else {
        height = node.offsetHeight;
        node.style.height = 0;
      }
    },
    active: function active() {
      node.style.height = `${show ? height : 0}px`;
    },
    end: function end() {
      node.style.height = '';
      done();
    },
  });
}

function animation(prefixCls) {
  return {
    enter: function enter(node, done) {
      return animate(node, true, `${prefixCls}-anim`, done);
    },
    leave: function leave(node, done) {
      return animate(node, false, `${prefixCls}-anim`, done);
    },
  };
}

exports.default = animation;
module.exports = exports.default;
