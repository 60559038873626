import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';

import data from './video-list.json';
import active from './active-videos.json';
import VideoModal from './VideoModal.jsx';

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSubject: Object.keys(data)[0],
      selectedTopic: Object.keys(data[Object.keys(data)[0]])[0],
    };
  }

  selectTopic(topic) {
    this.setState({ selectedTopic: topic });
  }

  selectSubject(subject) {
    this.setState({
      selectedSubject: subject,
      selectedTopic: Object.keys(data[subject])[0],
    });
  }

  render() {
    return (
      <Container>
        <Row>
          {/* Controller */}
          <Col xs="12" md="4" lg="3">
            <Row className="vertical-button-group">
              <div className="title">المادّة</div>
              <Col>
                {Object.keys(data).map((subj, key) => (
                  <Row
                    key={key}
                    className={
                      this.state.selectedSubject === subj
                        ? 'button active'
                        : 'button'
                    }
                    onClick={() => {
                      this.selectSubject(subj);
                    }}
                  >
                    <Col>{subj}</Col>
                  </Row>
                ))}
              </Col>
            </Row>

            <Row className="vertical-button-group">
              <div className="title">الموضوع</div>
              <Col>
                {Object.keys(data[this.state.selectedSubject]).map(
                  (subj, key) => (
                    <Row
                      key={key}
                      className={
                        this.state.selectedTopic === subj
                          ? 'button active'
                          : 'button'
                      }
                      onClick={() => {
                        this.selectTopic(subj);
                      }}
                    >
                      <Col>{subj}</Col>
                    </Row>
                  )
                )}
              </Col>
            </Row>
          </Col>

          {/* LIST */}
          <Col>
            <Container className="vid-list-group active-vids">
              <Row className="title">
                <Col xs="12" md="6">
                  <div className="col-text">أفلام نموذجيّة</div>
                </Col>
              </Row>
              <Row>
                {active[this.state.selectedSubject].map((vid, k) => (
                  <Col md="6" lg="4" className="vid-object" key={k}>
                    <Row>
                      <Col xs="auto">
                        <i className="icon fas fa-video" />
                      </Col>
                      <Col>
                        <VideoModal
                          videoName={vid}
                          thumbnail={`/resources/videos/${vid}.JPG`}
                          url={`/resources/videos/${vid}.mp4`}
                        />
                      </Col>
                    </Row>
                  </Col>
                ))}
              </Row>
            </Container>
            {Object.keys(
              data[this.state.selectedSubject][this.state.selectedTopic]
            ).map((section, key) => (
              <Container className="vid-list-group" key={key}>
                <Row className="title">
                  <Col xs="12" md="6">
                    <div className="col-text">
                      {section === 'all' ? this.state.selectedTopic : section}
                    </div>
                  </Col>
                </Row>
                <Row>
                  {data[this.state.selectedSubject][this.state.selectedTopic][
                    section
                  ].map((vid, k) => (
                    <Col md="6" lg="4" className="vid-object" key={k}>
                      <Row>
                        <Col xs="auto">
                          <i className="icon fas fa-video" />
                        </Col>
                        <Col>{vid}</Col>
                      </Row>
                    </Col>
                  ))}
                </Row>
              </Container>
            ))}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default List;
