import React from 'react';
import PropTypes from 'prop-types';

import ExerciseStatuses from '../../utils/exercise-statuses';
// import { QuestionText } from '../QuestionTextComponent';
import wrapWithExerciseBase from '../ExerciseWrapperHOC/ExerciseWrapper';

class TrueFalse extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...this.initalState() };
  }

  initalState = () => {
    const v = {
      exStatus: ExerciseStatuses.UNTOUCHED,
      canAnswer: true,
      answer: null,
    };

    return v;
  };

  checkAnswer = () => this.state.answer === this.props.exMeta.answer.toString();

  reset = () => {
    this.setState({
      ...this.initalState(),
    });
  };

  showAnswer = () => {
    this.setState({
      answer: this.props.exMeta.answer.toString(),
    });
  };

  handleRadioChange = (e) => {
    const { value } = e.target;
    let status;

    this.setState(
      (prev) => {
        let { answer } = prev;
        if (answer === value) {
          answer = null;
          status = ExerciseStatuses.UNTOUCHED;
        } else {
          answer = value;
          status = ExerciseStatuses.TOUCHED;
        }

        return { answer, exStatus: status };
      },
      () => {
        if (this.props.onChange) {
          this.props.onChange();
        }
      }
    );
  };

  renderQNumber() {
    const { qNum } = this.props;

    if (!qNum) return null;

    return (
      <span className="question-number">
        <strong>{this.props.qNum}. </strong>
      </span>
    );
  }

  renderContext() {
    const { context } = this.props.exMeta;

    if (!context || context === '') return null;

    return (
      <div className="question-context">
        <span dangerouslySetInnerHTML={{ __html: context }} />
        <br />
      </div>
    );
  }

  renderQuestion() {
    const { question } = this.props.exMeta;

    if (!question || question === '') return null;

    return (
      <span
        className="question-text"
        dangerouslySetInnerHTML={{ __html: question }}
      />
    );
  }

  renderExplanation() {
    const { explanation } = this.props.exMeta;

    if (!explanation || explanation === '') return null;

    return (
      <span
        className="question-explanation"
        dangerouslySetInnerHTML={{ __html: explanation }}
      />
    );
  }

  render() {
    return (
      <div
        className={`exercise tf ${
          this.state.exStatus === ExerciseStatuses.CORRECT ? 'correct' : ''
        } ${this.state.exStatus === ExerciseStatuses.WRONG ? 'wrong' : ''}`}
      >
        <div className="qst-header">
          {this.renderQNumber()}

          {this.renderContext()}

          {this.renderQuestion()}
        </div>

        <div className="tf-choices">
          <div className="choice">
            <input
              type="radio"
              value="true"
              checked={this.state.answer === 'true' || false}
              onClick={this.handleRadioChange}
              disabled={
                !this.state.canAnswer ||
                this.state.exStatus >= ExerciseStatuses.CORRECT
              }
            />
            <label>
              <p>صح</p>
            </label>
          </div>

          <div className="choice">
            <input
              type="radio"
              value="false"
              checked={this.state.answer === 'false' || false}
              onClick={this.handleRadioChange}
              disabled={
                !this.state.canAnswer ||
                this.state.exStatus >= ExerciseStatuses.CORRECT
              }
            />
            <label>
              <p>خطأ</p>
            </label>
          </div>
        </div>

        {this.renderExplanation()}
      </div>
    );
  }
}

TrueFalse.propTypes = {
  exMeta: PropTypes.objectOf(PropTypes.any).isRequired,
  qNum: PropTypes.number,
  onChange: PropTypes.func,
};

TrueFalse.defaultProps = {
  qNum: null,
  onChange: null,
};

export default wrapWithExerciseBase(TrueFalse);
export { TrueFalse as TrueFalsePURE };
