import React from 'react';

import './Services.scss';
import data from './services-data.json';
import DisplayList from './DisplayList';

function Services() {
  return (
    <div className="services">
      <section className="educational title-section">
        <div className="page-title">خدماتنا التعليمية</div>
        <DisplayList data={data.educational} />
      </section>

      <section className="technological title-section">
        <div className="page-title">خدماتنا التقنية</div>
        <DisplayList data={data.technological} />
      </section>
    </div>
  );
}

export default Services;
