import React from 'react';
// import JsxParser from 'react-jsx-parser';
// import Collapse, { Panel } from 'rc-collapse';

import Collapse, { Panel } from '../common/collapse/Collapse';
import PopupElement from './PopupElement';
import Mparser from '../../../data/Mparser';

import './Page.scss';

// JsxParser.defaultProps = {
//   renderInRapper: false,
//   blacklistedAttrs: [],
// };

class Page extends React.Component {
  state = {
    content: this.props.content,
    currentPage: this.props.currentPage,
    popup: false,
    popupDetails: {},
  };

  componentDidUpdate() {
    if (this.state.currentPage !== this.props.currentPage) this.resetState();
  }

  recalculateBounds = () => {
    let reBounds = document.getElementById('popupBox');
    if (reBounds) reBounds = reBounds.getBoundingClientRect();
    else return;
    this.setState((prevState) => ({
      popupDetails: {
        originalLeft: prevState.popupDetails.originalLeft,
        originalTop: prevState.popupDetails.originalTop,
        left: prevState.popupDetails.originalLeft - reBounds.width + 20,
        top: prevState.popupDetails.originalTop - reBounds.height - 10,
        content: prevState.popupDetails.content,
      },
    }));
  };

  resetState = () => {
    this.setState({
      content: this.props.content,
      currentPage: this.props.currentPage,
      popup: false,
    });
  };

  // NOTE: temporary fix for mparser, removed e.target.innerText to e.innerText
  // innerText to innerHTML and replaced for mathjax
  showPopup = (e) => {
    const keyword = e.innerText.trim();
    // .replace('<span onclick="window.showPopup(this)" class="math-tex">', '').replace(/<\/span>/g, '');
    const bounds = e.getBoundingClientRect();
    let { left, top } = bounds;
    let popWord = '';
    for (const word of this.props.popups) {
      if (word.keyword.replace(/<(.|\n)*?>/g, '') === keyword) {
        popWord = word.display;
        break;
      }
    }

    // if(this.state.popup){
    //   // this.hidePopup();
    // }
    const container = document.getElementById('teachersContent');
    const containerBounds = container.getBoundingClientRect();

    left = left + container.scrollLeft - containerBounds.left;
    top = top + container.scrollTop - containerBounds.top;
    this.setState({
      popup: true,
      popupDetails: {
        content: popWord,
        originalLeft: left,
        originalTop: top,
      },
    });
    document.addEventListener('mousedown', this.hidePopup);
  };

  hidePopup = () => {
    if (this.state.popup) this.setState({ popup: false, popupDetails: {} });
    document.removeEventListener('mousedown', this.hidePopup);
  };

  render() {
    return (
      <div className="page">
        <div className="page-title">
          {/* <div className="page-chapter-title">{this.props.chapterTitle}</div> */}
          <div className="page-chapter-title page-bold">
            {this.props.lessonTitle}
          </div>
          {/* <div className="page-chapter-title page-bold">معين المدرّس</div> */}
        </div>
        <div className="page-content-title">
          <img
            src={this.props.contentIcon}
            className="page-content-title-icon"
            alt=""
          />
          <div className="page-content-title-text">
            {this.props.contentTitle}
          </div>
        </div>
        {/* TODO: Find better approach for parsing JSX from strings.
            Popular approach is using babel... */}
        <div className="page-content">
          {/* <JsxParser
            jsx={this.state.content}
            bindings={{
              showPopup: this.showPopup,
              hidePopup: this.hidePopup,
            }}
            components={{ Collapse, Panel }}
          /> */}
          {this.state.content && (
            <Mparser
              string={this.state.content}
              comps={[
                { name: 'CollapsePanel', component: <Panel /> },
                { name: 'CollapseComp', component: <Collapse /> },
              ]}
              functions={[
                { fn: this.showPopup, name: '{showPopup}', event: 'onClick' },
              ]}
            />
          )}
        </div>
        {this.state.popup ? (
          <PopupElement
            {...this.state.popupDetails}
            recalculateBounds={this.recalculateBounds}
            hide={this.hidePopup}
          />
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default Page;
