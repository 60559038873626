import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import DemoNav from './DemoNav/DemoNav';
import SampleIcons from './sampleicons/SampleIcons';
import SampleApps from './sampleApps/SampleApps';
import SampeVideo from './SampleVideo';
import './EarlyLearning.scss';

function EarlyLearning() {
  return (
    <div className="prd-early-learning">
      <section className="demo">
        <section className="title-section">
          <Container>
            <div className="page-title">التعليم المبكر</div>
            <div className="page-subtitle">
              وسائل تعليميّة إلكترونيّة لمرحلة ما قبل الدّراسة، تيسّر على
              المربّين إيصال المفاهيم الأساسيّة للمتعلّمين الصغار بأسلوب معاصر
              وممتع
            </div>
          </Container>
        </section>
        <section>
          <Container>
            <DemoNav />
          </Container>
        </section>
      </section>
      <section className="description">
        <Container>
          <Row>
            <Col sm={12} md={6}>
              <div
                style={{
                  backgroundImage:
                    'url("/resources/earlylearning/smartboard.jpg")',
                }}
                className="image"
              />
            </Col>

            <Col sm={12} md={6}>
              <div className="paragraph">
                <div className="title">مادّة تفاعليّة إلكترونيّة مميّزة</div>
                <div className="text">
                  منهاج متكامل لمرحلة ما قبل الدّراسة، يتضمّن أكثر من 300 وسيلة
                  تعليميّة إلكترونيّة تساعد المربّين على إيصال الأفكار المهمّة
                  للمتعلّمين الصغار. تتراوح مواضيعها ما بين الموادّ العلميّة
                  والرياضيّات، وصولاً إلى الفنون والّلغة والتعبير.
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <hr />

      <section className="sample-apps">
        <Container>
          <section className="title-section">
            <div className="page-title">دمجٌ بين المتعة والفائدة</div>
          </section>
          <SampleApps />
          <Row className="paragraph">
            {/* TODO: complete this section or make it separate with icons */}
          </Row>
        </Container>
      </section>
      <hr />
      <section className="sample-video">
        <section className="title-section">
          <div className="page-title">فيديوهات تفاعلية لجذب التركيز </div>
        </section>
        <Container>
          <SampeVideo />
        </Container>
      </section>
      <hr />

      <section className="sample-icons">
        <section className="title-section">
          <div className="page-title">
            الكثير من المواضيع المفيدة لمرحلة ما قبل الدراسة
          </div>
        </section>
        <SampleIcons />
      </section>
    </div>
  );
}

export default EarlyLearning;
