import React, { Component } from 'react';
import PropsTypes from 'prop-types';

import { ListGroup, ListGroupItem, Badge } from 'reactstrap';

import AudioPlayer from './AudioPlayer';

import './AudioPlaylist.scss';

class AudioPlaylist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTrack: 0,
      autoPlay: false,
    };
  }

  handleOnTrackFinish = () => {
    this.setState((prevState) => {
      const nextTrack =
        (prevState.currentTrack + 1) % this.props.tracksList.length;
      return { currentTrack: nextTrack };
    });
  };

  handlePrevTrack = () => {
    this.setState((prevState) => {
      if (prevState.currentTrack === 0) {
        return { currentTrack: this.props.tracksList.length - 1 };
      }
      const prevTrack =
        (prevState.currentTrack - 1) % this.props.tracksList.length;
      return { currentTrack: prevTrack };
    });
  };

  render() {
    return (
      <div className="Audio-Playlist">
        <AudioPlayer
          src={this.props.tracksList[this.state.currentTrack].src}
          onFinish={this.handleOnTrackFinish}
          autoPlay={this.state.autoPlay}
          onNextClick={this.handleOnTrackFinish}
          onPrevClick={this.handlePrevTrack}
        />
        <div className="list-container">
          <ListGroup>
            {this.props.tracksList.map((track, index) => (
              <ListGroupItem
                key={index}
                tag="a"
                href="#"
                action
                active={index === this.state.currentTrack}
                onClick={() =>
                  this.setState({ currentTrack: index, autoPlay: true })
                }
                className="justify-content-between d-flex"
              >
                <span>{track.title}</span>

                {track.duration && (
                  <Badge pill className="d-flex align-items-center">
                    {track.duration}
                  </Badge>
                )}
              </ListGroupItem>
            ))}
          </ListGroup>
        </div>
      </div>
    );
  }
}

AudioPlaylist.propTypes = {
  tracksList: PropsTypes.arrayOf(PropsTypes.any).isRequired,
};

export default AudioPlaylist;
