import axios from 'axios';

// get all chapters
// get curriculum
// get specific lesson
// get chapter lessons

function initializeQuery(ids = [], search = []) {
  let query = '?';
  for (const id of ids) {
    query += `_id[]=${id}&`;
  }
  for (const s of search) {
    query += `${s}&`;
  }
  return query;
}

class DataService {
  constructor() {
    this.api = process.env.REACT_APP_API_URL;
    this.curriculumID = process.env.REACT_APP_CURRICULUM;
  }

  // TODO: Implement get curriculum along curriculum, not with chapters...

  // FIXME: pass chapter as well as lesson id
  getLesson(id, chapter) {
    const query = initializeQuery([], [`title=${chapter}`, `lessons=${id}`]);
    return {
      chapter: axios.get(`${this.api}/chapters${query}`),
      lesson: axios.get(`${this.api}/lessons/${id}`),
    };
  }

  getTeachers(id) {
    return axios.get(`${this.api}/lessons/${id}/teachers`);
  }

  getStudents(id) {
    return axios.get(`${this.api}/lessons/${id}/students`);
  }

  getExercises(id) {
    return axios.get(`${this.api}/lessons/${id}/exercises`);
    // TODO: get user details as well? Or get the details on login?
  }

  getResources(id) {
    return axios.get(`${this.api}/lessons/${id}/resources`);
  }

  getAudio(id) {
    return axios.get(`${this.api}/lessons/${id}/audio`);
  }

  getChapters(ids, search) {
    const query = initializeQuery(ids, search);
    return axios.get(`${this.api}/chapters${query}`);
  }

  getLessons(ids, search) {
    const query = initializeQuery(ids, search);
    return axios.get(`${this.api}/lessons${query}`);
  }

  // TODO: change the curriculum ID from env value to user related value
  getCurriculum() {
    if (process.env.REACT_APP_DATA_MODE === 'all')
      return this.getAllCurriculum();
    return axios.get(`${this.api}/curriculums/${this.curriculumID}`);
  }

  getAllCurriculum() {
    return new Promise((resolve) => {
      this.getChapters().then((res) => {
        resolve({
          data: {
            content: [
              {
                material: 'جميع المواد',
                classes: [
                  {
                    className: 'جميع المستويات',
                    chapters: res.data.map((chap) => chap._id),
                  },
                ],
              },
            ],
          },
        });
      });
    });
  }
}

export default new DataService();
