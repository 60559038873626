import React from 'react';
import PropTypes from 'prop-types';

// import { QuestionText } from '../QuestionTextComponent';
import ExerciseStatuses from '../../utils/exercise-statuses';
import wrapWithExerciseBase from '../ExerciseWrapperHOC/ExerciseWrapper';

class Blanks extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...this.initalState() };
  }

  initalState = () => {
    const v = {
      exStatus: ExerciseStatuses.UNTOUCHED,
      canAnswer: true,
      answer: '',
    };
    return v;
  };

  /**
   * TODO: use "strictLanguage" prop in checking process
   *
   */
  checkAnswer = () => this.props.exMeta.answer.includes(this.state.answer);

  reset = () => {
    this.setState({
      ...this.initalState(),
    });
  };

  showAnswer = () => {
    this.setState({
      answer: this.props.exMeta.answer[0],
    });
  };

  handleInputChange = (e) => {
    if (this.state.exStatus === ExerciseStatuses.UNTOUCHED) {
      this.setState({
        exStatus: ExerciseStatuses.TOUCHED,
      });
    }
    const { value } = e.target;
    const status =
      value.trim() === ''
        ? ExerciseStatuses.UNTOUCHED
        : ExerciseStatuses.TOUCHED;

    this.setState(
      {
        answer: value,
        exStatus: status,
      },
      () => {
        if (this.props.onChange) {
          this.props.onChange();
        }
      }
    );
  };

  renderQNumber() {
    const { qNum } = this.props;

    if (!qNum) return null;

    return (
      <span className="question-number">
        <strong>{this.props.qNum}. </strong>
      </span>
    );
  }

  renderContext() {
    const { context } = this.props.exMeta;

    if (!context || context === '') return null;

    return (
      <div className="question-context">
        <span dangerouslySetInnerHTML={{ __html: context }} />
        <br />
      </div>
    );
  }

  renderExplanation() {
    const { explanation } = this.props.exMeta;

    if (!explanation || explanation === '') return null;

    return (
      <span
        className="question-explanation"
        dangerouslySetInnerHTML={{ __html: explanation }}
      />
    );
  }

  render() {
    return (
      <div
        className={`exercise question-answer ${
          this.state.exStatus === ExerciseStatuses.CORRECT ? 'correct' : ''
        } ${this.state.exStatus === ExerciseStatuses.WRONG ? 'wrong' : ''}`}
      >
        <div className="qst-header">
          {this.renderQNumber()}

          {this.renderContext()}

          <div>
            <span
              dangerouslySetInnerHTML={{
                __html: this.props.exMeta.before || '',
              }}
            />

            <span>
              {' '}
              <input
                type="text"
                value={this.state.answer}
                onChange={this.handleInputChange}
                disabled={
                  !this.state.canAnswer ||
                  this.state.exStatus >= ExerciseStatuses.CORRECT
                }
              />{' '}
            </span>

            <span
              dangerouslySetInnerHTML={{
                __html: this.props.exMeta.after || '',
              }}
            />
          </div>
        </div>

        {this.renderExplanation()}
      </div>
    );
  }
}

Blanks.propTypes = {
  exMeta: PropTypes.objectOf(PropTypes.any).isRequired,
  qNum: PropTypes.number,
  onChange: PropTypes.func,
};

Blanks.defaultProps = {
  qNum: null,
  onChange: null,
};

export default wrapWithExerciseBase(Blanks);
export { Blanks as BlanksPURE };
