import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { makeStyles } from '@material-ui/core/styles';

const Toc = (props) => {
  const useStyles = makeStyles((theme) => ({
    avatar: {
      margin: 10,
      objectFit: 'cover',
      height: '100%',
      backgroundColor: 'red',
    },
    listText: {
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: '22',
    },
    purpleAvatar: {
      margin: 5,
      height: 30,
      width: 30,
      fontSize: '1rem',
      backgroundColor: '#1976d2',
    },
  }));
  const classes = useStyles();
  return (
    <List style={{ backgroundColor: props.bgColor }}>
      {props.tocobj.map((value, index) => (
        <ListItem
          key={value + index}
          button
          onClick={() => props.onTocItem(value.topicNo)}
        >
          <ListItemAvatar>
            <Avatar className={classes.purpleAvatar}>{index + 1}</Avatar>
          </ListItemAvatar>
          <ListItemText className={classes.listText} primary={value.text} />
          <ListItemAvatar>
            <Avatar
              alt=""
              src={
                '/resources/portal/images/' + props.imgFolder + '/' + value.icon
              }
              className={classes.avatar}
            />
          </ListItemAvatar>
        </ListItem>
      ))}
    </List>
  );
};

export default Toc;
