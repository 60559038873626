import React from 'react';
import MainNav from '../../players/portal/mainnav';

import './PortalCustomStyle.scss';

export default function PortalPlayer() {
  return (
    <div className="portal-player">
      <MainNav />
    </div>
  );
}
