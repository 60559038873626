import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import swal from '@sweetalert/with-react';

import { Col, Button, Form, FormGroup, Input } from 'reactstrap';
import Select from 'react-select';

import './ExercisesForm.scss';

import FormOptions from './FormOptions';

// TODO: fix all bugs and double check....
class ExercisesForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formRes: {
        0: {
          chapter: FormOptions.chapters[0],
          lesson: FormOptions.lessons[0],
          types: [],
          number: 10,
        },
      },
      submit: false,
      submitOnProgress: false,
      query: null,
    };

    this.query = '';

    this.lessons = [];
  }

  getChaptersList = () => {
    return FormOptions.chapters.filter((ch) => {
      const chList = Object.values(this.state.formRes).filter(
        (v) => v.chapter.value === ch.value
      );
      const lessonsNum = ch.to - ch.from + 1;

      return chList.length !== lessonsNum;
    });
  };

  getLessonsList = (rowID) => {
    const chID = this.state.formRes[rowID].chapter.value - 1;

    if (isNaN(chID)) return [];

    return FormOptions.lessons.filter((les) => {
      const t = Object.values(this.state.formRes).find(
        (v) => v.lesson.value === les.value
      );

      return (
        !t &&
        les.value >= FormOptions.chapters[chID].from &&
        les.value <= FormOptions.chapters[chID].to
      );
    });
  };

  handleFilterLessons = (rowID, lesson) => {
    const t = Object.values(this.state.formRes).find(
      (v) => v.lesson.value === lesson.value
    );

    // const t = this.lessons.find(v => v.value === lesson.value);

    return (
      this.state.formRes[rowID] &&
      !t &&
      lesson.value >= this.state.formRes[rowID].chapter.from &&
      lesson.value <= this.state.formRes[rowID].chapter.to
    );
  };

  handleChapterChange = (rowID, value) => {
    this.setState(
      (prevState) => ({
        formRes: {
          ...prevState.formRes,
          [rowID]: { ...prevState.formRes[rowID], chapter: value },
        },
      }),
      () => {
        this.setState((prevState) => ({
          formRes: {
            ...prevState.formRes,
            [rowID]: {
              ...prevState.formRes[rowID],
              lesson: this.getLessonsList(rowID)[0],
            },
          },
        }));
      }
    );
  };

  handleLessonsChange = (rowID, value) => {
    const t = this.lessons.findIndex((v) => v.value === value.value);
    if (t < 0) this.lessons.push(value);
    else this.lessons.splice(t, 1);

    this.setState((prevState) => ({
      formRes: {
        ...prevState.formRes,
        [rowID]: { ...prevState.formRes[rowID], lesson: value },
      },
    }));
  };

  handleTypesChange = (rowID, value) => {
    this.setState((prevState) => ({
      formRes: {
        ...prevState.formRes,
        [rowID]: { ...prevState.formRes[rowID], types: value },
      },
    }));
  };

  handleNumberChange = (rowID, event) => {
    // console.log(rowID, value.target.value);
    const { value } = event.target;
    const num = Number.parseInt(value, 10);
    this.setState((prevState) => ({
      formRes: {
        ...prevState.formRes,
        [rowID]: { ...prevState.formRes[rowID], number: num },
      },
    }));
  };

  validateNumber = (rowID, event) => {
    const { max, min } = event.target;

    const value = this.state.formRes[rowID].number;

    if (value < min || value > max) {
      let num;

      if (value < min) {
        num = min;
      } else {
        num = max;
      }

      this.setState((prevState) => ({
        formRes: {
          ...prevState.formRes,
          [rowID]: { ...prevState.formRes[rowID], number: num },
        },
      }));
    }
  };

  handleAddClick = () => {
    let resLength;
    let newKey;
    this.setState(
      (prevState) => {
        resLength = Object.keys(prevState.formRes).length;
        newKey =
          Number.parseInt(Object.keys(prevState.formRes)[resLength - 1], 10) +
          1;
        const newRow = {
          chapter: this.getChaptersList()[0],
          lesson: [],
          types: [],
          number: 10,
        };
        return { formRes: { ...prevState.formRes, [newKey]: newRow } };
      },
      () => {
        this.setState((prevState) => ({
          formRes: {
            ...prevState.formRes,
            [newKey]: {
              ...prevState.formRes[newKey],
              lesson: this.getLessonsList(newKey)[0],
            },
          },
        }));
      }
    );
  };

  handleDelClick = (rowID) => {
    this.setState((prevState) => {
      const { formRes } = prevState;
      delete formRes[rowID];

      return { formRes };
    });
  };

  handleSubmitClick = () => {
    this.setState({ submitOnProgress: true }, () => {
      setTimeout(() => {
        let q = '';
        Object.values(this.state.formRes).forEach((v) => {
          // const types = v.types.length === 1
          //   ? v.types[0].value
          //   : v.types.reduce((acc, cur) => `${acc.value}+${cur.value}`);
          q += `${v.chapter.value}|${v.lesson.value}||${v.number},`;
        });

        // alert(q);
        this.query = q;

        this.setState({ query: q, submitOnProgress: false });
      }, 2000);
    });
  };

  handleResetClick = () => {
    swal({
      titleText: 'تحذير',
      content: <p>سيتم محو كافة الخيارات... هل أنت متأكد؟</p>,
      icon: 'warning',
      buttons: {
        confirm: {
          text: 'نعم',
          value: true,
          visible: true,
          closeModal: true,
        },
        cancel: {
          text: 'كلا',
          value: false,
          visible: true,
          closeModal: true,
        },
      },
    }).then((result) => {
      if (!result) return;
      this.setState({
        formRes: {
          0: {
            chapter: FormOptions.chapters[0],
            lesson: [],
            types: [],
            number: 10,
          },
        },
        query: null,
      });
    });
  };

  getFullQueryLink = () => {
    const { href } = window.location;
    const { query } = this.state;

    return `${href}${href.endsWith('/') ? '' : '/'}${query}`;
  };

  render() {
    if (this.state.submit)
      return <Redirect to={`exercises/${this.state.query}`} />;

    return (
      <div className="ExercisesForm">
        <Form>
          {Object.keys(this.state.formRes).map((key) => (
            <FormGroup key={key} row>
              <Col xs={12} md={4} className="mt-2 mt-md-0">
                <Select
                  name="chapter"
                  // options={FormOptions.chapters}
                  options={this.getChaptersList()}
                  placeholder="اختر الفصل"
                  value={this.state.formRes[key].chapter}
                  // eslint-disable-next-line react/jsx-no-bind
                  onChange={this.handleChapterChange.bind(this, key)}
                  isDisabled={this.state.submitOnProgress}
                />
              </Col>
              <Col xs={12} md={4} className="mt-2 mt-md-0">
                <Select
                  name="lesson"
                  // options={FormOptions.lessons}
                  options={this.getLessonsList(key)}
                  placeholder="اختر الدرس"
                  value={this.state.formRes[key].lesson}
                  // eslint-disable-next-line react/jsx-no-bind
                  onChange={this.handleLessonsChange.bind(this, key)}
                  // eslint-disable-next-line react/jsx-no-bind
                  // filterOption={this.handleFilterLessons.bind(this, key)}
                  isDisabled={this.state.submitOnProgress}
                />
              </Col>
              {/* <Col xs={12} md={4} className="mt-2 mt-md-0">
                <Select
                  isMulti
                  name="types"
                  options={FormOptions.types}
                  placeholder="اختر نوع الأسئلة"
                  value={this.state.formRes[key].types}
                  onChange={this.handleTypesChange.bind(this, key)}
                />
              </Col> */}
              <Col xs={12} md={2} className="mt-2 mt-md-0">
                <Input
                  type="number"
                  name="number"
                  min={10}
                  max={100}
                  placeholder="اختر عدد الأسئلة"
                  value={this.state.formRes[key].number}
                  // eslint-disable-next-line react/jsx-no-bind
                  onChange={this.handleNumberChange.bind(this, key)}
                  // eslint-disable-next-line react/jsx-no-bind
                  onBlur={this.validateNumber.bind(this, key)}
                  disabled={this.state.submitOnProgress}
                />
              </Col>
              <Col xs={12} md={1} className="mt-2 mt-md-0">
                <Button
                  type="button"
                  color="success"
                  disabled={
                    this.getChaptersList().length === 0 ||
                    this.state.submitOnProgress
                  }
                  onClick={this.handleAddClick}
                  block
                >
                  <i className="fas fa-plus" /> {/* إضافة */}
                </Button>
              </Col>
              <Col xs={12} md={1} className="mt-2 mt-md-0">
                {key !== '0' && (
                  <Button
                    block
                    outline
                    type="button"
                    color="danger"
                    // eslint-disable-next-line react/jsx-no-bind
                    onClick={this.handleDelClick.bind(this, key)}
                    disabled={this.state.submitOnProgress}
                  >
                    <i className="fas fa-trash" />
                  </Button>
                )}
              </Col>
            </FormGroup>
          ))}

          <FormGroup row className="justify-content-end">
            <Col xs={12} md={2}>
              <Button
                type="button"
                color="danger"
                onClick={this.handleResetClick}
                block
                disabled={this.state.submitOnProgress}
                className="mt-2 mt-md-0"
              >
                <i className="fas fa-redo" /> تفريغ
              </Button>
            </Col>
            <Col xs={12} md={2}>
              <Button
                type="button"
                color="success"
                onClick={this.handleSubmitClick}
                block
                disabled={this.state.submitOnProgress}
                outline={this.state.submitOnProgress}
                className={`mt-2 mt-md-0 ${
                  this.state.submitOnProgress ? 'submitOnProgress' : ''
                }`}
              >
                <i className="fas fa-cog" />{' '}
                {this.state.submitOnProgress ? 'جاري الإعداد...' : 'إنشاء'}
              </Button>
            </Col>
          </FormGroup>

          {this.state.query && (
            <FormGroup
              row
              className="justify-content-center py-3 bgc-dark query-link"
            >
              <h4>تم إنشاء رابط لورقة الأسئلة... قم بالنقر على الرابط لنسخه</h4>
              <Col xs={12} md={8}>
                <Input
                  type="text"
                  style={{ textAlign: 'center', direction: 'ltr' }}
                  value={`${this.getFullQueryLink()}`}
                  onClick={(e) => {
                    e.target.select();
                    document.execCommand('copy');
                  }}
                />
              </Col>

              <Col>
                <Button
                  className="mx-1 mt-2 mt-md-0"
                  type="button"
                  color="info"
                  onClick={() => {
                    window.open(`${this.getFullQueryLink()}`, '_blank');
                  }}
                >
                  <i className="fas fa-globe" />
                  {'  '}
                  افتح الرابط
                </Button>
                <Button
                  className="mx-1 mt-2 mt-md-0"
                  type="button"
                  color="info"
                  onClick={() => {
                    window.location.href = `mailto:?subject=ورقة أسئلة&body=${this.getFullQueryLink()}`;
                  }}
                >
                  <i className="fas fa-envelope" />
                  {'  '}
                  ارسال عبر البريد الالكتروني
                </Button>
              </Col>
            </FormGroup>
          )}
        </Form>
      </div>
    );
  }
}

export default ExercisesForm;
