import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Container, Row, Col } from 'reactstrap';

import TeachersPlayer from '../../players/teachers/Teachers';

import './TeacherDetails.scss';

class TeacherDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.chapterID = this.props.match.params.chapterID;
  }

  render() {
    return (
      <div className="TeacherDetails">
        <section className="player bgc-dark py-3">
          <Container>
            <Row>
              <Col className="bgc-light">
                <TeachersPlayer chapterID={this.chapterID} chapterTitle="" />
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    );
  }
}

export default TeacherDetails;

TeacherDetails.propTypes = {
  match: PropTypes.objectOf(PropTypes.any),
};

TeacherDetails.defaultProps = {
  match: null,
};
