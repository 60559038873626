import React, { Component } from 'react';
import Topic from './topic';
import CatList from './catlist';

class MainNav extends Component {
  state = {
    booksType: 'encyclopedias',
    booksTitle: 'الموسوعات',
    encyName: 'animals',
    catName: 'easyscience',
    catTitle: 'موسوعة العلوم الميسرة',
    //catName: "infoency",
    //catTitle: "موسوعة عالم المعرفة والعلوم",
    catDisplay: true,
    subCatDisplay: false,
  };

  handleCatClicked = (cat) => {
    //console.log("cat clicked");
    //console.log("CatDisplay" + this.state.catDisplay);
    //console.log("SubCatDisplay" + this.state.subCatDisplay);
    //console.log("encyName" + this.state.encyName);
    //console.log("caaaaaaaaaaaaatt" + cat);
    if (this.state.catDisplay) {
      this.setState({
        catName: cat.catName,
        catTitle: cat.title,
        catDisplay: false,
        subCatDisplay: true,
      });
    } else {
      if (this.state.subCatDisplay) {
        this.setState({
          encyName: cat.catName,
          catDisplay: false,
          subCatDisplay: false,
        });
      }
    }
  };

  handleSubCatBackClicked = () => {
    this.setState({ subCatDisplay: true });
  };

  handleCatBackClicked = () => {
    //console.log("cat clicked back");
    this.setState({ catDisplay: true });
  };

  displayselection() {
    //console.log(this.state.catDisplay);
    //console.log(this.state.subCatDisplay);
    if (this.state.catDisplay) {
      //console.log("rendering books");
      return (
        <CatList
          cellWidth={'40%'}
          catTitle={this.state.booksTitle}
          catPath={this.state.booksType}
          onCatClicked={this.handleCatClicked}
        />
      );
    } else {
      if (this.state.subCatDisplay) {
        return (
          <CatList
            cellWidth={'18%'}
            catTitle={this.state.catTitle}
            catPath={this.state.booksType + '/' + this.state.catName}
            onCatClicked={this.handleCatClicked}
            onBackClicked={this.handleCatBackClicked}
          />
        );
      } else {
        return (
          <Topic
            booksType={this.state.booksType}
            encyName={this.state.encyName}
            catName={this.state.catName}
            onBackClicked={this.handleSubCatBackClicked}
          />
        );
      }
    }
  }

  render() {
    return <div>{this.displayselection()}</div>;
  }
}

export default MainNav;
