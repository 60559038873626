import * as THREE from 'three';
import * as OrbitControls from 'three-orbitcontrols';
import * as TWEEN from '@tweenjs/tween.js';
import SceneSubject from './SceneSubject';
import GeneralLights from './GeneralLights';

export default (canvas, options) => {
  const clock = new THREE.Clock();
  let initialCam = options.cameras[0].position || { x: 0, y: 0, z: 0 };
  let initialControls = options.cameras[0].target || { x: 0, y: 0, z: 0 };

  const screenDimensions = {
    width: canvas.width,
    height: canvas.height,
  };
  const mousePosition = {
    x: 0,
    y: 0,
  };

  const scene = buildScene();
  const renderer = buildRender(screenDimensions);
  const camera = buildCamera(screenDimensions, options.cameras[0]);
  const controls = buildControls();
  setControls(options.controls);
  const sceneSubjects = createSceneSubjects(scene, options);

  function buildScene() {
    const scene = new THREE.Scene();
    // scene.background = new THREE.Color("#FFF");

    scene.background = new THREE.Color(0xcccccc);
    // scene.fog = new THREE.FogExp2( 0xcccccc, 0.002 );
    scene.fog = null;
    return scene;
  }

  function buildRender({ width, height }) {
    const renderer = new THREE.WebGLRenderer({
      canvas,
      antialias: true,
      alpha: true,
    });
    const DPR = window.devicePixelRatio ? window.devicePixelRatio : 1;
    renderer.setPixelRatio(DPR);
    renderer.setSize(width, height);

    renderer.gammaInput = true;
    renderer.gammaOutput = true;
    return renderer;
  }

  function buildCamera({ width, height }) {
    const aspectRatio = width / height;
    const fieldOfView = 50;
    const nearPlane = 1;
    const farPlane = 10000;
    const camera = new THREE.PerspectiveCamera(
      fieldOfView,
      aspectRatio,
      nearPlane,
      farPlane
    );

    // camera.position.z = 40;

    // let camera = new THREE.PerspectiveCamera( 60, window.innerWidth / window.innerHeight, 1, 1000 );
    camera.position.set(initialCam.x, initialCam.y, initialCam.z);
    return camera;
  }

  function buildControls() {
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.screenSpacePanning = true;
    controls.target.set(
      initialControls.x,
      initialControls.y,
      initialControls.z
    );
    controls.update();

    return controls;
  }

  function setControls(options) {
    controls.minDistance = options.minDistance;
    controls.maxDistance = options.maxDistance;
    controls.enablePan = options.enablePan;
    controls.enableRotate = options.enableRotate;
    controls.enableZoom = options.enableZoom;
    controls.minAzimuthAngle = options.minAngle;
    controls.maxAzimuthAngle = options.maxAngle;
  }

  function createSceneSubjects(scene, options) {
    const sceneSubjects = [
      new GeneralLights(scene),
      new SceneSubject(scene, options.path),
    ];

    return sceneSubjects;
  }

  function update() {
    // FIXME: what is time elapsed doing?
    const elapsedTime = clock.getElapsedTime();

    for (let i = 0; i < sceneSubjects.length; i++) {
      sceneSubjects[i].update(elapsedTime);
    }

    TWEEN.update();
    controls.update();
    renderer.render(scene, camera);
  }

  function onWindowResize() {
    const { width, height } = canvas;

    screenDimensions.width = width;
    screenDimensions.height = height;

    camera.aspect = width / height;
    camera.updateProjectionMatrix();

    // controls.handleResize();

    renderer.setSize(width, height);
  }

  function onMouseMove(x, y) {
    mousePosition.x = x;
    mousePosition.y = y;
  }

  function resetModel(options) {
    while (scene.children.length > 0) {
      scene.remove(scene.children[0]);
    }
    createSceneSubjects(scene, options);
    initialCam = options.cameras[0].position || { x: 0, y: 0, z: 0 };
    initialControls = options.cameras[0].target || { x: 0, y: 0, z: 0 };
    resetCamera();
    setControls(options.controls);
  }

  function resetCamera() {
    moveCamera(initialCam, initialControls, 1000);
  }
  function getCamera() {
    return {
      position: camera.position,
      target: controls.target,
    };
  }
  function setCamera(x, y, z) {
    camera.position.set(x, y, z);
  }
  function moveCamera(position, target, time) {
    const tweenP = new TWEEN.Tween(camera.position).to(position, time);
    const tweenR = new TWEEN.Tween(controls.target).to(target, time);
    tweenP.start();
    tweenR.start();
  }

  function deleteScene() {
    while (scene.children.length > 0) {
      scene.remove(scene.children[0]);
    }
  }

  function manager() {
    return sceneSubjects[1].manager;
  }

  return {
    update,
    resetModel,
    resetCamera,
    setCamera,
    moveCamera,
    getCamera,
    onWindowResize,
    onMouseMove,
    deleteScene,
    manager,
  };
};
