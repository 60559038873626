const FormOptions = {
  chapters: [
    {
      value: 1,
      label: 'الحركة والقوى والآلات البسيطة',
      from: 1,
      to: 3,
    },
    {
      value: 2,
      label: 'الصخور والمعادن',
      from: 4,
      to: 5,
    },
    {
      value: 3,
      label: 'القوى المشكلة للأرض',
      from: 6,
      to: 7,
    },
    {
      value: 4,
      label: 'الذرات والعناصر والجدول الدوري',
      from: 8,
      to: 10,
    },
  ],

  lessons: [
    {
      value: 1,
      label: 'الحركة',
    },
    {
      value: 2,
      label: 'قوانين نيوتن للحركة',
    },
    {
      value: 3,
      label: 'الشغل والآلات البسيطة',
    },
    {
      value: 4,
      label: 'المعادن جواهر الأرض',
    },
    {
      value: 5,
      label: 'أنواع الصخور',
    },
    {
      value: 6,
      label: 'صفائح الأرض المتحركة',
    },
    {
      value: 7,
      label: 'التجوية والتعرية وأثرهما',
    },
    {
      value: 8,
      label: 'تركيب المادة',
    },
    {
      value: 9,
      label: 'العناصر والجدول الدوري',
    },
    {
      value: 10,
      label: 'المركبات والمخاليط',
    },
  ],

  types: [
    {
      value: 'mcq',
      label: 'اختيار من متعدد',
    },
    {
      value: 'truefalse',
      label: 'صح / خطأ',
    },
    {
      value: 'blanks',
      label: 'املأ الفراغ',
    },
    {
      value: 'qna',
      label: 'أجب على السؤال',
    },
    {
      value: 'match',
      label: 'صِل',
    },
  ],
};

export default FormOptions;
