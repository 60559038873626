import React from 'react';
import { Container, Row, Col } from 'reactstrap';

function Domains() {
  return (
    <Container>
      <Row className="paragraph">
        <div className="title">ميادين العمل</div>
      </Row>
      <Row>
        <Col xs="12" md="6" xl="4">
          <Row className="domain-section">
            <Col xs="3" className="icon">
              <div>
                <i class="fas fa-school"></i>
              </div>
            </Col>
            <Col>
              <div className="title">التعليم الرقمي المدرسي</div>
              <div className="text">إنتاج وتطوير محتوى مدرسي تعليمي رقمي</div>
            </Col>
          </Row>
        </Col>

        <Col xs="12" md="6" xl="4">
          <Row className="domain-section">
            <Col xs="3" className="icon">
              <div>
                <i class="fas fa-tablet-alt"></i>
              </div>
            </Col>
            <Col>
              <div className="title">التعليم العصري</div>
              <div className="text">
                إنتاج كتب تعليمية بأساليب مختلفة عن الكتب التقليدية بشكل مبتكر
                وهادف متضمنة وسائط رقمية.
              </div>
            </Col>
          </Row>
        </Col>

        <Col xs="12" md="6" xl="4">
          <Row className="domain-section">
            <Col xs="3" className="icon">
              <div>
                <i class="fas fa-edit"></i>
              </div>
            </Col>
            <Col>
              <div className="title">التعليم عبر القلم الناطق</div>
              <div className="text">
                إنتاج كتب تعليمية مدمجة بأقلام ناطقة إلكترونية لها العديد من
                الميزات.
              </div>
            </Col>
          </Row>
        </Col>

        <Col xs="12" md="6" xl="4">
          <Row className="domain-section">
            <Col xs="3" className="icon">
              <div>
                <i class="fas fa-mobile"></i>
              </div>
            </Col>
            <Col>
              <div className="title">التطبيقات والبرامج التعليمية</div>
              <div className="text">
                إنتاج برامج وتطبيقات الكترونية تعليمية للعديد من أجهزة الكمبيوتر
                والأجهزة اللوحية واللوحية.
              </div>
            </Col>
          </Row>
        </Col>

        <Col xs="12" md="6" xl="4">
          <Row className="domain-section">
            <Col xs="3" className="icon">
              <div>
                <i class="fas fa-chalkboard-teacher"></i>
              </div>
            </Col>
            <Col>
              <div className="title">أدوات ووسائل تعليمية</div>
              <div className="text">
                إنتاج الأدوات والوسائل التعليمية المبتكرة، والتي تساهم في تطوير
                وتبسيط العملية التعليمية وتهدف أن تكون نواة اختبارية حية للطلاب
                والدارسين.
              </div>
            </Col>
          </Row>
        </Col>

        <Col xs="12" md="6" xl="4">
          <Row className="domain-section">
            <Col xs="3" className="icon">
              <div>
                <i class="fas fa-brain"></i>
              </div>
            </Col>
            <Col>
              <div className="title">
                وسائل تطوير الذكاء والمهارات المختلفة{' '}
              </div>
              <div className="text">
                إبتكار وإنتاج أدوات لتطوير الذكاء وتنمية المهارات المختلفة.
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default Domains;
